<template>
  <!--  first container-->
  <div class="flex flex-col lg:flex-row bg-gray">
    <div class="w-full lg:w-1/2">
      <!-- Slideshow -->
      <div class="w-full carousel bathroom-details-carousel">
        <div :id="'slide' + index" class="relative w-full carousel-item" v-for="(item, index) in slideshow"
             :key="index">
          <img
              :src="item"
              class="w-full h-full"
          />
          <div
              class="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2"
          >
            <a :href="index - 1 >= 0 ? '#slide' + (index - 1) : '#slide' + (slideshow.length - 1)"
               class="btn btn-circle">❮</a>
            <a :href="index + 1 < slideshow.length ?  '#slide' + (index + 1) : '#slide0'" class="btn btn-circle">❯</a>
          </div>
        </div>
      </div>
      <!-- End slideshow -->
    </div>
    <div
        class="flex items-center justify-center px-16 w-full py-10 lg:w-1/2 lg:py-0"
    >
      <div class="flex flex-col items-start justify-start">
        <h1 class="text-4xl mb-4">{{ $t(packagename) }}</h1>
        <h2 class="text-xl text-left mb-8">{{ $t(des) }}</h2>
        <div class="mb-4" v-if="price || avgBuild">
          <span v-if="price">
            <span class="text-sm mr-2">{{
                $t("packagedetail.start_from")
              }}</span>
            <span class="text-2xl">{{ $t(price) }} </span>
          </span>
          <span v-if="avgBuild">
            <span class="text-sm ml-2 mr-2">Avg Build</span>
            <span class="text-2xl">{{ avgBuild }} weeks</span>
          </span>
        </div>
        <ul class="mb-12">
          <li class="text-xs text-left mb-4">
            <div class="flex flex-row items-center">
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
              >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span class="ml-2">{{ $t("packagedetail.des1") }}</span>
            </div>
          </li>
          <li class="text-xs text-left mb-4">
            <div class="flex flex-row items-center">
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
              >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span class="ml-2">{{ $t("packagedetail.des2") }}</span>
            </div>
          </li>
          <li class="text-xs text-left">
            <div class="flex flex-row items-center">
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
              >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span class="ml-2">{{ $t("packagedetail.des3") }}</span>
            </div>
          </li>
        </ul>
        <a
            href="https://designer.nookrenovation.com/"
            target="_blank"
            class="w-full h-12 bg-primary text-black uppercase flex items-center justify-center text-xs hover:bg-custom-hover text-white"
        >
          <span>{{ $t("navbar.pricing") }}</span>
        </a>
        <!-- <span class="service-availability mt-4">
          {{ $t("bathroom.only_available") }}
        </span> -->
      </div>
    </div>
  </div>
  <!--  end of first container-->

  <!--  second container-->
  <div class="lg:flex lg:justify-center">
    <div class="px-6 py-20 home-second-container">
      <div class="lg:flex lg:justify-between">
        <h1 class="text-3xl mb-4 text-left lg:w-1/4 lg:mr-20">
          {{ $t("packagedetail.quality.title") }}
        </h1>
        <div class="text-left lg:w-1/2">
          <span class="font-bold">{{ $t("packagedetail.quality.des") }}</span>
          <!-- <span>{{ $t("packagedetail.design_for_everyday_paragraph") }}</span> -->
        </div>
      </div>
      <!-- <div class="image-showcase flex flex-col">
        <div class="mb-4">
          <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/bathroom/bathroom2.svg" class="w-full">
        </div>
        <div class="lg:flex lg:items-stretch">
          <div class="mb-4 lg:w-2/3 lg:mr-4 lg:mb-0">
            <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/bathroom/bathroom3.svg" class="w-full h-full">
          </div>
          <div class="flex lg:w-1/3 lg:flex-col">
            <div class="w-1/2 mr-4 lg:w-full lg:mb-4">
              <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/bathroom/bathroom4.svg" class="w-full">
            </div>
            <div class="w-1/2 lg:w-full">
              <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/bathroom/bathroom5.svg" class="w-full">
            </div>
          </div>
        </div>

      </div> -->
      <!-- <div class="py-20 lg:px-10">
        <p class="text-2xl lg:text-3xl">
          A pale blue is blended with matte black fixtures and graphic furnishings. An austere pink and gold fixtures
          bring brightness and warmth to our second design.
        </p>
      </div> -->
      <!-- <div class="image-showcase flex flex-col">
        <div class="mb-4">
          <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/bathroom/bathroom2.svg" class="w-full">
        </div>
        <div class="lg:flex lg:items-stretch">
          <div class="mb-4 lg:w-2/3 lg:mr-4 lg:mb-0">
            <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/bathroom/bathroom3.svg" class="w-full h-full">
          </div>
          <div class="flex lg:w-1/3 lg:flex-col">
            <div class="w-1/2 mr-4 lg:w-full lg:mb-4">
              <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/bathroom/bathroom4.svg" class="w-full">
            </div>
            <div class="w-1/2 lg:w-full">
              <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/bathroom/bathroom5.svg" class="w-full">
            </div>
          </div>
        </div>

      </div> -->
    </div>
  </div>
  <!--  end of second container-->

  <!--  third container-->
  <!-- <div class="home-third-container bg-gray w-full py-20 px-5 flex flex-col items-center">
    <h2 class="text-base">{{ $t("bathroom.we_know_renovations") }}</h2>
    <div class="lg:flex">
      <div class="mt-11 flex flex-col items-center">
        <div class="w-20 h-20 bg-white rounded-full">
          <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/bathroom/change_order.svg">
        </div>
        <div class="flex mt-5">
          <h3 class="text-sm font-semibold mr-1">{{ $t("bathroom.change_order_promise") }}</h3>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"/>
          </svg>
        </div>
        <span class="text-xs mt-3">{{ $t("bathroom.change_order_promise_paragraph") }}</span>
      </div>
      <div class="mt-11 flex flex-col items-center">
        <div class="w-20 h-20 bg-white rounded-full">
          <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/bathroom/insurance.svg">
        </div>
        <div class="flex mt-5">
          <h3 class="text-sm font-semibold mr-1">{{ $t("bathroom.insurance_coverage") }}</h3>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"/>
          </svg>
        </div>
        <span class="text-xs mt-3">{{ $t("bathroom.insurance_coverage_paragraph") }}</span>
      </div>
      <div class="mt-11 flex flex-col items-center">
        <div class="w-20 h-20 bg-white rounded-full">
          <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/bathroom/design.svg">
        </div>
        <div class="flex mt-5">
          <h3 class="text-sm font-semibold mr-1">{{ $t("bathroom.design_satisfaction_promise") }}</h3>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"/>
          </svg>
        </div>
        <span class="text-xs mt-3">{{ $t("bathroom.design_satisfaction_promise_paragraph") }}</span>
      </div>
    </div>
  </div> -->
  <!--  end of third container-->

  <!--  fourth container-->
  <!-- <div class="w-full lg:mt-20 lg:mb-20">
    <div class="lg:flex">
      <div class="w-full lg:w-1/2">
        <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/bathroom/bathroom6.svg" class="w-full">
      </div>
      <div class="pt-5 px-7 pb-16 lg:w-1/2 lg:pl-20 lg:pt-0">
        <h1 class="text-2xl text-left">{{ $t("bathroom.design_by_quality") }}</h1>
        <p class="text-sm mt-5 text-left">{{ $t("bathroom.design_by_quality_paragraph") }}</p>
      </div>
    </div>
    <div class="lg:flex flex-row-reverse lg:mt-20">
      <div class="w-full lg:w-1/2">
        <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/bathroom/bathroom7.svg" class="w-full">
      </div>
      <div class="pt-5 px-7 pb-16 lg:w-1/2 lg:pr-20 lg:pt-0">
        <h1 class="text-2xl text-left break-words">{{ $t("bathroom.thousands_of_options") }}</h1>
        <p class="text-sm mt-5 text-left break-words">{{ $t("bathroom.thousands_of_options_paragraph") }}</p>
      </div>
    </div>
  </div> -->
  <!--  end of fourth container-->

  <!--  fifth container-->
  <!-- <div class="bg-gray">
    <h3 class="text-sm font-semibold text-left p-7">
      <span class="lg:hidden">PROJECT HIGHLIGHTS</span>
    </h3>
    <div class="lg:flex lg:flex-row-reverse">
      <div class="pb-7 px-2 lg:w-1/2">
        <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/bathroom/bathroom8.svg">
      </div>
      <div class="p-7 lg:pt-0 lg:w-1/2 lg:mr-5 lg:flex lg:flex-col lg:justify-between">
        <h3 class="hidden text-sm font-semibold text-left pb-7 lg:block">
          <span>PROJECT HIGHLIGHTS</span>
        </h3>
        <div>
          <p class="text-sm text-left lg:text-2xl">
            "This open and airy bathroom maintains a welcoming charm by employing warm accents and fixtures."
          </p>
          <h4 class="quote text-xs text-left py-5 lg:text-sm">
            Block design
          </h4>
        </div>
        <div class="quote-author pt-5 lg:w-1/2">
          <div class="user-avatar flex items-center">
            <img class="h-10 w-10 rounded-full mr-3" src="https://d10ysazx19ptke.cloudfront.net/nookassets/avatar1.svg">
            <div class="author flex flex-col font-semibold">
            <span class="text-sm text-left whitespace-nowrap">
              BUILT BY
            </span>
              <span class="text-sm text-left">
              JOSEPH
            </span>
            </div>
          </div>
        </div>
        <a
            href="https://designer.nookrenovation.com/"
            target="_blank"
            class="w-full h-12 bg-primary text-black uppercase flex
            items-center justify-center text-xs
            hover:bg-black hover:text-white mt-7
            lg:hidden">
          <span>{{ $t("navbar.pricing") }}</span>
        </a>
      </div>
    </div>
    <div class="hidden lg:flex flex-row justify-center pb-14">
      <a
          href="https://designer.nookrenovation.com/"
          target="_blank"
          class="w-60 h-12 bg-primary text-black uppercase flex
          items-center justify-center text-xs
          hover:bg-black hover:text-white mt-7">
        <span>{{ $t("navbar.pricing") }}</span>
      </a>
    </div>

  </div> -->
  <!--  end of fifth container-->

  <!-- <div class="lg:flex lg:justify-center">
    <div class="pt-12 pb-6 px-6 lg:flex bathroom-about-container">
      <div class="bg-gray p-6 text-left bathroom-about lg:pt-20">
        <div>
          <h4 class="text-xs uppercase mb-2 uppercase font-semibold">
            block materials library
          </h4>
          <h2 class="text-3xl mb-4">
            Style at your fingertips
          </h2>
          <p class="mb-4">
            Block's curated materials library offers everything you need to create your ideal bathroom, whether your
            style is classic, contemporary, or somewhere in between. Mix and match the details you love most to design a
            space you can truly call your own.
          </p>
        </div>
      </div>
      <div class="lg:p-6 bg-gray">
        <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/bathroom/bathroom10.webp"/>
      </div>
    </div>
  </div> -->

  <!-- <div class="lg:flex lg:justify-center">
    <div class="pt-12 pb-6 px-6 lg:flex bathroom-about-container">
      <div class="bg-gray p-6 text-left bathroom-about lg:pt-20">
        <div>
          <h4 class="text-xs uppercase mb-2 font-semibold">
            ABOUT
          </h4>
          <h2 class="text-3xl mb-4">
            One Kings Lane + Block
          </h2>
          <p class="mb-4">
            The One Kings Lane Interior Design studio curated products from their thousands of decorative accents, all
            shoppable at One Kings Lane.
          </p>
        </div>
        <div class="hidden lg:block">
          <a class="button-primary w-52 h-12 lg:mt-20 bg-gray border border-solid">
            See more
          </a>
        </div>
      </div>
      <div class="lg:p-6 bg-gray">
        <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/bathroom/bathroom9.webp"/>
      </div>

      <div class="pt-4 bg-gray lg:hidden">
        <a class="button-primary w-full h-12 bg-gray border border-solid">
          See more
        </a>
      </div>
    </div>
  </div> -->

  <!-- <div class="lg:flex lg:justify-center mb-6">
    <div class="lg:flex reviews-and-contractors">
      <div class="p-6">
        <div class="bg-gray p-6 flex flex-col justify-between h-full">
          <h3 class="text-2xl">
            See what our customers are saying
          </h3>
          <div class="pt-10 bg-gray">
            <a class="button-primary w-full h-12 bg-gray border border-solid">
              Read reviews
            </a>
          </div>
        </div>
      </div>

      <div class="p-6">
        <div class="bg-gray p-6 flex flex-col justify-between h-full">
          <h3 class="text-2xl">
            Learn more about our trusted contractors
          </h3>
          <div class="pt-10 bg-gray">
            <a class="button-primary w-full h-12 bg-gray border border-solid">
              Learn more
            </a>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <!-- seventh container -->
  <div class="px-8 pb-16 w-full lg:flex lg:justify-center">
    <div class="package-highlight-showcase grid gap-8 max-width-1200">
      <div :class="'highlight-image-' + (index + 1)" v-for="(i,index) in photos" :key="i">
        <img @click="openViewImageCarousel(index)" class="h-full w-full" :src="i"/>
      </div>
    </div>
  </div>

  <div class="lg:flex lg:justify-center px-8 py-16 bg-gray text-left">
    <div class="max-width-1200">
      <div class="customizable border-b border-dashed pb-8">
        <div>
          <div class="flex flex-col gap-8 lg:flex-row lg:gap-20">
            <div class="customizable-title">
              <h2 class="text-3xl lg:text-4xl">
                {{ $t("packagedetail.customize.title") }}
              </h2>
            </div>
            <div>
              <p class="text-sm mb-4 lg:text-2xl">
                {{ $t("packagedetail.customize.des") }}
              </p>
              <a class="text-sm uppercase underline lg:text-base">
                {{ $t("packagedetail.customize.des2") }}
              </a>
            </div>
          </div>
          <div
              class="mt-8 flex flex-col gap-8 lg:mt-20 lg:grid lg:grid-cols-2 lg:gap-20"
          >
            <div class="flex flex-col gap-8">
              <div v-if="vanity.length > 0">
                <h3 class="text-sm mb-4 uppercase">
                  {{ $t("packagedetail.vanity") }}
                </h3>
                <div
                    class="all-features-preview flex gap-5 overflow-x-scroll pb-4"
                >
                  <div class="feature-preview" v-for="(photo, index) of vanity" :key="index">
                    <img :src="photo" />
                    <!-- <h4 class="text-xs mt-2">
                      Navy
                    </h4> -->
                  </div>
                </div>
              </div>
              <div v-if="tile.length > 0">
                <h3 class="text-sm mb-4 uppercase">
                  {{ $t("packagedetail.tile") }}
                </h3>
                <div
                    class="all-features-preview flex gap-5 overflow-x-scroll pb-4"
                >
                  <div class="feature-preview" v-for="(photo, index) of tile" :key="index">
                    <img :src="photo" />
                    <!-- <h4 class="text-xs mt-2">
                      Nero Marquina
                    </h4> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="shower-feature" v-if="shower.length > 0">
              <h3 class="text-sm mb-4 uppercase">
                {{ $t("packagedetail.shower") }}
              </h3>
              <div
                  class="all-features-preview flex gap-5 overflow-x-scroll pb-4"
              >
                <div class="feature-preview" v-for="(photo, index) of shower" :key="index">
                  <img :src="photo" />
                  <!-- <h4 class="text-xs mt-2">
                    Brushed Gold
                  </h4> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="pt-12 lg:grid lg:grid-cols-2 lg:gap-20">
        <div class="">
          <h2 class="text-base mb-8 lg:text-xl">
            Looking for tile samples? Request a free sample package of the Classic design library to experience the
            materials at home.
          </h2>
          <div class="mt-8 hidden lg:block">
            <button class="w-64 h-12 button-primary uppercase">Request Samples</button>
          </div>
        </div>
        <img src="@/assets/bathroom/bathroom14.webp">
        <div class="mt-8 lg:hidden">
          <button class="w-full h-12 button-primary uppercase">Request Samples</button>
        </div>
      </div> -->
    </div>
  </div>
  <!-- end seventh container -->

  <!--  sixth container-->
  <div class="bg-custom-light-green px-5 py-7">
    <h3 class="common-questions text-sm font-semibold text-left pb-4">
      <span>
        {{ $t("packages.common_question.title") }}
      </span>
    </h3>
    <div class="lg:flex lg:justify-between lg:items-end">
      <div class="lg:w-1/2">
        <div class="">
          <Accordion :title="$t('packages.common_question.faq1.title')">
            <p class="text-left text-sm">
              <span class="mb-3 block">
                {{ $t("packages.common_question.faq1.stt1") }}
              </span>
            </p>
          </Accordion>
          <Accordion :title="$t('packages.common_question.faq2.title')">
            <p class="pb-5 text-left">
              <span class="mb-3 block text-sm">
                {{ $t("packages.common_question.faq2.stt1") }}
              </span>
            </p>
          </Accordion>
          <Accordion :title="$t('packages.common_question.faq3.title')">
            <p class="pb-5 text-left">
              <span class="mb-3 block text-sm">
                {{ $t("packages.common_question.faq3.stt1") }}
              </span>
            </p>
          </Accordion>
          <Accordion :title="$t('packages.common_question.faq4.title')">
            <p class="text-left text-sm">
              <span class="mb-3 block">
                {{ $t("packages.common_question.faq4.stt1") }}
              </span>
            </p>
          </Accordion>
          <Accordion :title="$t('packages.common_question.faq5.title')">
            <p class="text-left text-sm">
              <span class="mb-3 block">
                {{ $t("packages.common_question.faq5.stt1") }}
              </span>
            </p>
          </Accordion>
          <Accordion :title="$t('packages.common_question.faq6.title')">
            <p class="text-left text-sm">
              <span class="mb-3 block">
                {{ $t("packages.common_question.faq6.stt1") }}
              </span>
            </p>
          </Accordion>
          <Accordion :title="$t('packages.common_question.faq7.title')">
            <p class="text-left text-sm">
              <span class="mb-3 block">
                {{ $t("packages.common_question.faq7.stt1") }}
              </span>
            </p>
          </Accordion>
          <Accordion :title="$t('packages.common_question.faq8.title')">
            <p class="text-left text-sm">
              <span class="mb-3 block">
                {{ $t("packages.common_question.faq8.stt1") }}
              </span>
            </p>
          </Accordion>
          <Accordion :title="$t('packages.common_question.faq9.title')">
            <p class="text-left text-sm">
              <span class="mb-3 block">
                {{ $t("packages.common_question.faq9.stt1") }}
              </span>
            </p>
          </Accordion>
          <Accordion :title="$t('packages.common_question.faq10.title')">
            <p class="text-left text-sm">
              <span class="mb-3 block">
                {{ $t("packages.common_question.faq10.stt1") }}
              </span>
            </p>
          </Accordion>
          <Accordion :title="$t('packages.common_question.faq11.title')">
            <p class="text-left text-sm">
              <span class="mb-3 block">
                {{ $t("packages.common_question.faq11.stt1") }}
              </span>
            </p>
          </Accordion>
        </div>
      </div>
      <div class="lg:w-1/2 lg:flex lg:flex-col lg:items-end">
        <div>
          <div class="pb-3 text-left">
            <span class="text-sm">
              {{ $t("packages.still_question") }}
            </span>
          </div>
          <div class="text-left pt-2 pb-5">
            <span class="text-xs block">{{ $t("packages.call_us") }}</span>
            <span class="text-xl block pb-2">{{ $t("packages.phone") }}</span>
            <span class="text-xs block">EMAIL</span>
            <span class="text-xl block">team@nookrenovation.com</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--  end of sixth container-->
  <Modal
      class="feature-modal"
      v-show="isImageShowcaseModalVisible"
      @close="closeModal()"
  >
    <template v-slot:header>
      <div class="pb-1 w-full flex justify-between">
        <div>
          {{viewIndex + 1}} of {{photos.length}}
        </div>
        <div class="flex gap-4 pr-8">
<!--          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"-->
<!--               stroke-width="2">-->
<!--            <path stroke-linecap="round" stroke-linejoin="round"-->
<!--                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0zM10 7v3m0 0v3m0-3h3m-3 0H7"/>-->
<!--          </svg>-->
<!--          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"-->
<!--               stroke-width="2">-->
<!--            <path stroke-linecap="round" stroke-linejoin="round"-->
<!--                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0zM13 10H7"/>-->
<!--          </svg>-->
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="border-none feature-preview-carousel">
        <div class="carousel">
          <div class="carousel-inner">
            <div v-for="(photo, index) of photos" :key="photo">
              <input class="carousel-open" type="radio" :id="`carousel-${index}`"
                     name="carousel" aria-hidden="true" hidden="" :checked="index === viewIndex">
              <div class="carousel-item flex justify-center items-center">
                <img :src="photo">
              </div>
              <label :for="`carousel-${index - 1 >= 0 ? index - 1 : photos.length - 1}`"
                     class="carousel-control prev" :class="`control-${index}`"
                     @click="viewIndex = viewIndex - 1 >= 0 ? viewIndex - 1 : photos.length - 1"
              >
                ‹
              </label>
              <label :for="`carousel-${index + 1 < slideshow.length - 1 ? index + 1 : 0}`"
                     class="carousel-control next" :class="`control-${index}`"
                     @click="viewIndex = viewIndex + 1 < slideshow.length - 1 ? viewIndex + 1 : 0"
              >
                ›
              </label>
            </div>
            <ol class="carousel-indicators">
              <li v-for="(photo, index) of photos" :key="photo">
                <label :for="`carousel-${index}`" class="carousel-bullet">
                  •
                </label>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="p-0"></div>
    </template>
  </Modal>

</template>

<script>
import Accordion from "@/components/Accordion";
import Modal from "@/components/Modal";

export default {
  name: "BathroomDetails",
  components: {Modal, Accordion},
  props: {
    packagename: String,
    des: String,
    price: String,
    mainpt: String,
    photos: Array,
    slideshow: Array,
    vanity: Array,
    shower: Array,
    tile: Array,
    avgBuild: Number,
  },
  data() {
    return {
      isImageShowcaseModalVisible: false,
      viewIndex: 0,
      currentImage: null
    }
  },
  methods: {
    closeModal() {
      this.isImageShowcaseModalVisible = false;
    },
    openViewImageCarousel(index) {
      this.isImageShowcaseModalVisible = true;
      this.viewIndex = index;
      this.currentImage = this.photos[index];
    }
  }
};
</script>

<style>
.service-availability {
  font-size: 0.5rem;
}

.home-second-container {
  max-width: 1200px;
}

.image-showcase img,
.home-third-container img {
  max-width: 100%;
}

.quote::before {
  content: "— ";
}

.quote-author {
  border-top: 1px dashed black;
}

.highlight-image-1 {
  grid-area: 1/1/2/3;
  max-height: 164px;
}

.highlight-image-2 {
  grid-area: 1/3/2/5;
  max-height: 164px;
}

.highlight-image-3 {
  grid-area: 2/1/3/5;
  max-height: 328px;
}

.highlight-image-4 {
  grid-area: 3/1/4/5;
  max-height: 328px;
}

.highlight-image-5 {
  grid-area: 4/1/5/5;
  max-height: 328px;
}

.feature-preview {
  width: 140px;
  flex-shrink: 0;
}

.feature-preview img {
  width: 140px;
  height: 140px;
  object-fit: contain;
}

.all-features-preview::-webkit-scrollbar {
  display: none;
}

.all-features-preview {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.shower-feature .feature-preview img {
  height: 450px;
}

.feature-highlight-carousel {
  max-height: 100%;
  max-width: 600px;
}

.feature-preview-carousel .carousel {
  position: relative;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.64);
  margin-top: 26px;
}

.feature-preview-carousel .carousel-inner {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.feature-preview-carousel .carousel-open:checked + .carousel-item {
  position: static;
  opacity: 100;
}

.feature-preview-carousel .carousel-item {
  position: absolute;
  opacity: 0;
  -webkit-transition: opacity 0.6s ease-out;
  transition: opacity 0.6s ease-out;
}

.feature-preview-carousel .carousel-item img {
  display: block;
  height: auto;
  max-width: 100%;
}

.feature-preview-carousel .carousel-control {
  background: rgba(0, 0, 0, 0.28);
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  display: none;
  font-size: 40px;
  height: 50px;
  line-height: 35px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  text-align: center;
  width: 50px;
  z-index: 10;
}

.feature-preview-carousel .carousel-control.prev {
  left: 2%;
}

.feature-preview-carousel .carousel-control.next {
  right: 2%;
}

.feature-preview-carousel .carousel-control:hover {
  background: rgba(0, 0, 0, 0.8);
  color: #aaa;
}

.feature-preview-carousel #carousel-0:checked ~ .control-0,
.feature-preview-carousel #carousel-1:checked ~ .control-1,
.feature-preview-carousel #carousel-2:checked ~ .control-2,
.feature-preview-carousel #carousel-3:checked ~ .control-3,
.feature-preview-carousel #carousel-4:checked ~ .control-4 {
  display: block;
}

.feature-preview-carousel .carousel-indicators {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 2%;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 10;
}

.feature-preview-carousel .carousel-indicators li {
  display: inline-block;
  margin: 0 5px;
}

.feature-preview-carousel .carousel-bullet {
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 35px;
}

.feature-preview-carousel .carousel-bullet:hover {
  color: #aaa;
}

.feature-preview-carousel #carousel-1:checked ~ .control-1 ~ .carousel-indicators li:nth-child(1) .carousel-bullet, .feature-preview-carousel #carousel-2:checked ~ .control-2 ~ .carousel-indicators li:nth-child(2) .carousel-bullet, .feature-preview-carousel #carousel-3:checked ~ .control-3 ~ .carousel-indicators li:nth-child(3) .carousel-bullet {
  color: #428bca;
}

.feature-preview-carousel #title {
  width: 100%;
  position: absolute;
  padding: 0px;
  margin: 0px auto;
  text-align: center;
  font-size: 27px;
  color: rgba(255, 255, 255, 1);
  font-family: 'Open Sans', sans-serif;
  z-index: 9999;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.33), -1px 0px 2px rgba(255, 255, 255, 0);
}

.feature-modal .modal-footer {
  padding: 0;
}

.feature-preview-carousel .carousel {
  margin-top: 0;
}

.feature-modal .btn-close {
  top: 5px;
}

.feature-preview-carousel .modal-body, .feature-preview-carousel .carousel-item {
  height: calc(100vh - 64px);
  width: 100vw;
}

@media screen and (min-width: 1024px) {
  .bathroom-about {
    width: 500px;
    flex-shrink: 0;
  }

  .bathroom-about-container,
  .reviews-and-contractors {
    width: 1024px;
  }

  .reviews-and-contractors > div {
    width: 512px;
  }

  .package-highlight-showcase {
    grid-template: repeat(2, auto) / repeat(3, auto);
    grid-gap: 40px;
  }

  .highlight-image-1 {
    grid-area: 1/1/2/2;
    max-height: 374px;
  }

  .highlight-image-2 {
    grid-area: 2/1/3/2;
    max-height: 374px;
  }

  .highlight-image-3 {
    grid-area: 1/2/3/-1;
    height: 100%;
    max-height: 788px;
  }

  .highlight-image-4 {
    grid-area: 4/1/-1/4;
    max-width: 50%;
    padding-right: 20px;
    max-height: 580px;
  }

  .highlight-image-5 {
    grid-area: 4/1/-1/4;
    max-width: 50%;
    padding-left: 20px;
    position: relative;
    left: 50%;
    max-height: 580px;
  }

  .customizable-title {
    min-width: 360px;
  }

  .feature-preview {
    width: 164px;
    flex-shrink: 0;
  }

  .feature-preview img {
    width: 164px;
    height: 164px;

    display: grid;
    grid-template: 'image' 164px/1fr;
    object-fit: contain;
  }
}
</style>
