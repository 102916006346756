<template>
  <div>
    <!--  first container-->
    <div class="flex flex-col lg:flex-row bg-gray" id="hiw-header">
      <div class="w-full lg:w-1/2">
        <Carousel></Carousel>
      </div>
      <div
        class="flex items-center justify-center px-16 w-full py-10 lg:w-1/2 lg:py-0"
      >
        <div class="flex flex-col items-start justify-start">
          <h1 class="text-4xl mb-4">{{ $t("howitworks.title") }}</h1>
          <h2 class="text-xl text-left mb-8">{{ $t("howitworks.des") }}</h2>
          <ul class="mb-12">
            <li class="text-xs text-left mb-4">
              <div class="flex flex-row items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <span class="ml-2">{{ $t("howitworks.stt1") }}</span>
              </div>
            </li>
            <li class="text-xs text-left mb-4">
              <div class="flex flex-row items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <span class="ml-2">{{ $t("howitworks.stt2") }}</span>
              </div>
            </li>
            <li class="text-xs text-left mb-4">
              <div class="flex flex-row items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <span class="ml-2">{{ $t("howitworks.stt3") }}</span>
              </div>
            </li>
            <li class="text-xs text-left">
              <div class="flex flex-row items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <span class="ml-2">{{ $t("howitworks.stt4") }}.</span>
                <br />
                <span class="ml-2">{{ $t("howitworks.stt5") }}</span>
              </div>
            </li>
          </ul>
          <a
            href="https://designer.nookrenovation.com/"
            target="_blank"
            class="w-full h-12 bg-primary text-black uppercase flex items-center justify-center text-xs hover:bg-custom-hover text-white"
          >
            <span>{{ $t("navbar.pricing") }}</span>
          </a>
          <!-- <span class="service-availability mt-4">
            {{ $t("bathroom.only_available") }}
          </span> -->
        </div>
      </div>
    </div>
    <!--  end of first container-->

    <div id="hiw-nav-sticky">
      <div class="hiw-nav-sticky flex h-14">
        <a href="#plan" class="hiw-nav-sticky-item" @click="addActiveClass(0)"
          ><span class="hidden lg:flex">{{
            $t("howitworks.step1.title")
          }}</span></a
        >
        <a href="#design" class="hiw-nav-sticky-item" @click="addActiveClass(1)"
          ><span class="hidden lg:flex">{{
            $t("howitworks.step2.title")
          }}</span></a
        >
        <a href="#build" class="hiw-nav-sticky-item" @click="addActiveClass(2)"
          ><span class="hidden lg:flex">{{
            $t("howitworks.step3.title")
          }}</span></a
        >
        <!-- <a href="#manage" class="hiw-nav-sticky-item" @click="addActiveClass(3)"><span
            class="hidden lg:flex">MANAGE</span></a> -->
      </div>
    </div>
    <div class="bg-custom-light-gray py-16 px-4" id="plan">
      <div class="flex flex-col items-center">
        <div class="lg:max-w-md">
          <h2 class="text-3xl uppercase mb-4">
            {{ $t("howitworks.step1.subtitle") }}
          </h2>
          <p class="text-md">
            {{ $t("howitworks.step1.des") }}
          </p>
        </div>
      </div>
      <div class="lg:flex justify-center">
        <div class="lg:flex lg:max-w-5xl">
          <div class="mt-10 lg:w-1/2 lg:mr-10">
            <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/how_it_works/step1.webp" class="w-full" />
          </div>
          <div class="mt-10 lg:w-1/2">
            <PlusAccordion
              :title="$t('howitworks.step1.faq1.title')"
              :is-active="true"
            >
              <div class="text-left">
                <span class="text-md lg:text-sm">
                  {{ $t("howitworks.step1.faq1.des") }}
                </span>
              </div>
            </PlusAccordion>
            <PlusAccordion :title="$t('howitworks.step1.faq2.title')">
              <div class="text-left">
                <span class="text-md lg:text-sm">
                  {{ $t("howitworks.step1.faq2.des") }}
                </span>
              </div>
            </PlusAccordion>
            <PlusAccordion :title="$t('howitworks.step1.faq3.title')">
              <div class="text-left">
                <span class="text-md lg:text-sm">
                  {{ $t("howitworks.step1.faq3.des") }}
                </span>
              </div>
            </PlusAccordion>
            <!-- <PlusAccordion :title='$t("howitworks.step1.faq4.title")' :has-border-bottom="true">
              <div class="text-left">
            <span class="text-md lg:text-sm">
            {{ $t("howitworks.step1.faq4.des") }}
          </span>
              </div>
            </PlusAccordion> -->
          </div>
        </div>
      </div>
    </div>
    <div class="bg-secondary py-16 px-4" id="design">
      <div class="flex flex-col items-center">
        <div class="lg:max-w-md">
          <h2 class="text-3xl uppercase mb-4">
            {{ $t("howitworks.step2.subtitle") }}
          </h2>
          <p class="text-md">
            {{ $t("howitworks.step2.des") }}
          </p>
        </div>
      </div>
      <div class="lg:flex justify-center">
        <div class="lg:flex lg:flex-row-reverse lg:max-w-5xl">
          <div class="mt-10 lg:w-1/2 lg:ml-10">
            <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/how_it_works/step2.webp" class="w-full" />
          </div>
          <div class="mt-10 lg:w-1/2">
            <PlusAccordion
              :title="$t('howitworks.step2.faq1.title')"
              :is-active="true"
            >
              <div class="text-left">
                <span class="text-md lg:text-sm">
                  {{ $t("howitworks.step2.faq1.des") }}
                </span>
              </div>
            </PlusAccordion>
            <PlusAccordion :title="$t('howitworks.step2.faq2.title')">
              <div class="text-left">
                <span class="text-md lg:text-sm">
                  {{ $t("howitworks.step2.faq2.des") }}
                </span>
              </div>
            </PlusAccordion>
            <PlusAccordion :title="$t('howitworks.step2.faq3.title')">
              <div class="text-left">
                <span class="text-md lg:text-sm">
                  {{ $t("howitworks.step2.faq3.des") }}
                </span>
              </div>
            </PlusAccordion>
            <!-- <PlusAccordion :title='$t("howitworks.step2.faq4.title")' :has-border-bottom="true">
              <div class="text-left">
            <span class="text-md lg:text-sm">
            {{ $t("howitworks.step2.faq4.des") }}
          </span>
              </div>
            </PlusAccordion> -->
          </div>
        </div>
      </div>
    </div>
    <div class="bg-custom-light-gray py-16 px-4" id="build">
      <div class="flex flex-col items-center">
        <div class="lg:max-w-md">
          <h2 class="text-3xl uppercase mb-4">
            {{ $t("howitworks.step3.subtitle") }}
          </h2>
          <p class="text-md">
            {{ $t("howitworks.step3.des") }}
          </p>
        </div>
      </div>
      <div class="lg:flex justify-center">
        <div class="lg:flex lg:max-w-5xl">
          <div class="mt-10 lg:w-1/2 lg:mr-10">
            <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/how_it_works/step3.webp" class="w-full" />
          </div>
          <div class="mt-10 lg:w-1/2">
            <PlusAccordion
              :title="$t('howitworks.step3.faq1.title')"
              :is-active="true"
            >
              <div class="text-left">
                <span class="text-md lg:text-sm">
                  {{ $t("howitworks.step3.faq1.des") }}
                </span>
              </div>
            </PlusAccordion>
            <PlusAccordion :title="$t('howitworks.step3.faq2.title')">
              <div class="text-left">
                <span class="text-md lg:text-sm">
                  {{ $t("howitworks.step3.faq2.des") }}
                </span>
              </div>
            </PlusAccordion>
            <PlusAccordion :title="$t('howitworks.step3.faq3.title')">
              <div class="text-left">
                <span class="text-md lg:text-sm">
                  {{ $t("howitworks.step3.faq3.des") }}
                </span>
              </div>
            </PlusAccordion>
            <!-- <PlusAccordion :title='$t("howitworks.step3.faq4.title")' :has-border-bottom="true">
              <div class="text-left">
            <span class="text-md lg:text-sm">
            {{ $t("howitworks.step3.faq4.des") }}
          </span>
              </div>
            </PlusAccordion> -->
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="bg-accent py-16 px-4" id="manage">
      <div class="flex flex-col items-center">
        <div class="lg:max-w-md">
          <h2 class="text-3xl uppercase mb-4">
            4. MANAGE WITH EASE
          </h2>
          <p class="text-md">
            We’ve developed a platform where you can manage your renovation from the comfort of, well... anywhere.
            Owning your project has never been so easy.
          </p>
        </div>
      </div>
      <div class="lg:flex justify-center">
        <div class="lg:flex lg:flex-row-reverse lg:max-w-5xl">
          <div class="mt-10 lg:w-1/2 lg:ml-10">
            <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/how_it_works/hiw4.webp" class="w-full">
          </div>
          <div class="mt-10 lg:w-1/2">
            <PlusAccordion title="Project Dashboard" :is-active="true">
              <div class="text-left">
            <span class="text-md lg:text-sm">
              Your dashboard provides you with a single place to manage your entire project. Easily review your designs, make payments, get build updates, and more.
            </span>
              </div>
            </PlusAccordion>
            <PlusAccordion title="Expansive Materials Library">
              <div class="text-left">
            <span class="text-md lg:text-sm">
            Our project planners are our resident renovation experts. From co-ops to single family houses, rip & replace
            renos to full gut builds, they’ve seen it all. They’ll work with you to set up your project, and teach you
          </span>
              </div>
            </PlusAccordion>
            <PlusAccordion title="Award Winning Design Team">
              <div class="text-left">
            <span class="text-md lg:text-sm">
            Our project planners are our resident renovation experts. From co-ops to single family houses, rip & replace
            renos to full gut builds, they’ve seen it all. They’ll work with you to set up your project, and teach you
          </span>
              </div>
            </PlusAccordion>
            <PlusAccordion title="Design Standards For Builders" :has-border-bottom="true">
              <div class="text-left">
            <span class="text-md lg:text-sm">
            Our project planners are our resident renovation experts. From co-ops to single family houses, rip & replace
            renos to full gut builds, they’ve seen it all. They’ll work with you to set up your project, and teach you
          </span>
              </div>
            </PlusAccordion>

          </div>
        </div>
      </div>
    </div> -->

    <div class="flex flex-col items-center">
      <h2 class="text-3xl py-6">
        {{ $t("howitworks.statements.title") }}
      </h2>
      <div class="p-6 quality-accordions-container w-full">
        <Accordion
          class="quality-accordion"
          :title="$t('howitworks.statements.full_service.title')"
        >
          <p class="text-left text-sm">
            <span class="mb-3 block">
              {{ $t("howitworks.statements.full_service.stt1") }}
            </span>
            <!-- <span class="mb-3 block text-sm">
              {{ $t("howitworks.statements.full_service.stt2") }}
            </span> -->
            <!-- <span class="mb-3 block text-sm">
              {{ $t("howitworks.statements.full_service.stt3") }}
            </span> -->
          </p>
        </Accordion>
        <Accordion
          class="quality-accordion"
          :title="$t('howitworks.statements.beautiful_design.title')"
        >
          <p class="pb-5 text-left">
            <span class="mb-3 block text-sm">
              {{ $t("howitworks.statements.beautiful_design.stt1") }}
            </span>
            <!-- <span class="mb-3 block text-sm">
              {{ $t("howitworks.statements.beautiful_design.stt2") }}
            </span> -->
          </p>
        </Accordion>
        <Accordion
          class="quality-accordion"
          :title="$t('howitworks.statements.quality_materials.title')"
        >
          <p class="pb-5 text-left">
            <span class="mb-3 block text-sm">
              {{ $t("howitworks.statements.quality_materials.stt1") }}
            </span>
            <!-- <span class="mb-3 block text-sm">
              {{ $t("howitworks.statements.quality_materials.stt2") }}
            </span> -->
          </p>
        </Accordion>
        <Accordion
          class="quality-accordion"
          :title="$t('howitworks.statements.professional_team.title')"
        >
          <p class="pb-5 text-left">
            <span class="mb-3 block text-sm">
              {{ $t("howitworks.statements.professional_team.stt1") }}
            </span>
            <!-- <span class="mb-3 block text-sm">
              {{ $t("howitworks.statements.professional_team.stt2") }}
            </span> -->
          </p>
        </Accordion>
        <Accordion
          class="quality-accordion"
          :title="$t('howitworks.statements.price_transparency.title')"
        >
          <p class="pb-5 text-left">
            <span class="mb-3 block text-sm">
              {{ $t("howitworks.statements.price_transparency.stt1") }}
            </span>
            <!-- <span class="mb-3 block text-sm">
              {{ $t("howitworks.statements.price_transparency.stt2") }}
            </span> -->
          </p>
        </Accordion>
      </div>
    </div>
  </div>
</template>
<script>
import Carousel from "@/components/Carousel";
import Accordion from "@/components/Accordion";
import PlusAccordion from "@/components/PlusAccordion";

export default {
  name: "HowItWorks",
  components: { Accordion, Carousel, PlusAccordion },
  data() {
    return {
      navOffsetTop: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const header = document.getElementById("hiw-header");
      const nav = document.getElementById("hiw-nav-sticky");
      this.navOffsetTop = header.offsetHeight;

      if (window.scrollY > this.navOffsetTop) {
        nav.classList.add("fixed-scrollbar");
      } else {
        nav.classList.remove("fixed-scrollbar");
      }
    },
    addActiveClass(index) {
      const elements = Array.from(
        document.getElementsByClassName("hiw-nav-sticky-item")
      );
      elements.forEach((element) => {
        element.classList.remove("selected-section");
      });

      elements[index].classList.add("selected-section");
    },
  },
};
</script>
<style>
.hiw-nav-sticky-item {
  @apply w-full flex items-center justify-center border-r border-black border-collapse;
}

.hiw-nav-sticky-item:last-of-type {
  border-right: none;
}

.hiw-nav-sticky {
  counter-reset: navbar 0;
}

.hiw-nav-sticky-item::before {
  -webkit-box-align: center;
  align-items: center;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 50%;
  counter-increment: navbar 1;
  content: counter(navbar);
  display: flex;
  height: 24px;
  -webkit-box-pack: center;
  justify-content: center;
  width: 24px;
}

.selected-section {
  @apply bg-primary;
}

.fixed-scrollbar {
  @apply fixed top-0 left-0 bg-white w-full;
}

@media screen and (min-width: 1024px) {
  .hiw-nav-sticky-item:before {
    margin-right: 8px;
  }

  .quality-accordions-container {
    max-width: 1200px;
  }

  .quality-accordion:last-of-type a {
    @apply border-b;
  }

  .quality-accordion a {
    @apply border-solid;
  }
}
</style>
