<template>
  <div class="contractor-details-banner lg:flex lg:flex-row lg:justify-center pb-10 bg-gray">
    <div class="p-4 text-left lg:p-8">
      <h4 class="text-3xl mt-4 mb-8 lg:text-md lg:mb-10 font-semibold lg:hidden">
        Art Deco Enterprises
      </h4>
      <div class="lg:flex lg:flex-row-reverse working-together-review lg:py-10">
        <div>
          <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/contractors/details-1.webp"/>
        </div>
        <div class="lg:flex lg:flex-col lg:justify-center lg:h-full lg:mr-10">
          <h4 class="text-3xl mt-4 mb-8 uppercase lg:text-md lg:mb-5 font-semibold hidden lg:block">
            Art Deco Enterprises
          </h4>
          <div>
            <p class="text-md lg:text-xl mt-10 mb-4 lg:mb-4 lg:mt-5">
              Launched by Ion and Sorina in 2012, Art Deco specializes in residential and commercial renovations and has
              successfully completed over 100 projects since its founding.
            </p>
            <div class="mb-4">
              <h4 class="text-md lg:text-xl font-semibold uppercase">
                Owner
              </h4>
              <p class="text-md">
                Ion Dodu
              </p>
            </div>
            <div class="mb-4">
              <h4 class="text-md lg:text-xl font-semibold uppercase">
                Service areas
              </h4>
              <p class="text-md">
                Kings, New York
              </p>
            </div>
          </div>
          <div class="pb-5 mt-10">
            <a href="https://designer.nookrenovation.com/"
               target="_blank"
               class="w-full h-12 bg-primary text-black uppercase flex items-center justify-center text-xs
                hover:bg-custom-hover text-white lg:w-64">
              <span>{{ $t("navbar.pricing") }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-milk w-full py-20 px-5 flex flex-col items-center">
    <div class="grid grid-cols-2 gap-10 lg:grid-cols-4 max-width-1200">
      <div class="mt-11 flex flex-col items-center">
        <div class="w-20 h-20 bg-white rounded-full flex justify-center">
          <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/contractors/contractor-details-1.webp">
        </div>
        <div class="flex mt-5">
          <h3 class="text-md font-semibold mr-1">Craftsmanship verification</h3>
        </div>
      </div>
      <div class="mt-11 flex flex-col items-center">
        <div class="w-20 h-20 bg-white rounded-full flex justify-center">
          <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/contractors/contractor-details-2.webp">
        </div>
        <div class="flex mt-5">
          <h3 class="text-md font-semibold mr-1">Licensed and insured</h3>
        </div>
      </div>
      <div class="mt-11 flex flex-col items-center">
        <div class="w-20 h-20 bg-white rounded-full flex justify-center">
          <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/contractors/contractor-details-3.webp">
        </div>
        <div class="flex mt-5">
          <h3 class="text-md font-semibold mr-1">Workmanship guarantee</h3>
        </div>
      </div>
      <div class="mt-11 flex flex-col items-center">
        <div class="w-20 h-20 bg-white rounded-full flex justify-center">
          <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/contractors/contractor-details-4.webp">
        </div>
        <div class="flex mt-5">
          <h3 class="text-md font-semibold mr-1">Certified Nook design standards</h3>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-white w-full py-20 px-5 flex flex-col items-center">
    <div class="max-width-1200">
      <h2 class="text-3xl mb-2 uppercase">
        Highlights
      </h2>
      <h3 class="text-md uppercase">
        Kings & New York
      </h3>
      <div>
        <div class="mt-5 h-80 flex gap-10 overflow-x-scroll contractor-highlight">
          <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/contractors/contractor-details-5.svg"
               v-for="item in [1,2,3,4,5,6,7,8,9,10,11,12]" :key="item"/>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-white">
    <div class="relative contractor-details-banner">
      <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/contractors/contractor-details-6.webp" class="object-cover w-full h-full"/>
      <h2 class="text-4xl text-white absolute-middle w-full px-10">
        MEET ION AND SORINA
      </h2>
    </div>
    <div class="lg:flex lg:justify-center">
      <div class="my-12 px-5 text-left contractor-details-interview lg:flex lg:flex-col lg:items-center">
        <h2 class="text-xl mb-10">
          Art Deco is run by husband & wife duo Ion and Sorina. They’ve worked all over New York, and have completed
          projects of all scopes and sizes in both the US and Moldova, where they both lived prior to their move
          stateside.
          We chatted with Sorina in one of their ongoing Nook projects.
        </h2>
        <div v-for="i of [1,2]" :key="i">
          <h4 class="contractor-question">
            Can you tell us about how your business came to be where it is today?
          </h4>
          <div class="contractor-answer">
            <p>
              “My father actually has a background in engineering, which Ion has as well. Because of that, I was exposed
              to
              that part of the business and some of the challenges there early on and firsthand. So even though I went
              for a
              Master’s in Business in the US, I got a sort of second education through experience, and I was able to
              think
              through the unique challenges that come with building.
            </p>
            <p>
              On the other hand, Ion’s been in the business forever— since he was 16. From the first day he moved to the
              US
              in 2007, it’s all he’s done. So I would help him here and there, and in the end, we figured ‘this works’
              and
              the partnership was born.”
            </p>
          </div>
        </div>
        <div class="flex justify-between gap-10 mt-5 mb-10">
          <div>
            <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/contractors/contractor-details-7.webp"/>
          </div>
          <div>
            <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/contractors/contractor-details-8.webp"/>
          </div>
        </div>
        <div v-for="i of [1,2,3,4]" :key="i">
          <h4 class="contractor-question">
            Can you tell us about how your business came to be where it is today?
          </h4>
          <div class="contractor-answer">
            <p>
              “My father actually has a background in engineering, which Ion has as well. Because of that, I was exposed
              to
              that part of the business and some of the challenges there early on and firsthand. So even though I went
              for a
              Master’s in Business in the US, I got a sort of second education through experience, and I was able to
              think
              through the unique challenges that come with building.
            </p>
            <p>
              On the other hand, Ion’s been in the business forever— since he was 16. From the first day he moved to the
              US
              in 2007, it’s all he’s done. So I would help him here and there, and in the end, we figured ‘this works’
              and
              the partnership was born.”
            </p>
          </div>
        </div>
        <div class="flex justify-center mt-5 mb-10">
          <div>
            <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/contractors/contractor-details-9.webp"/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="px-5 py-20 bg-gray lg:flex lg:justify-center">
    <div class="max-width-1200">
      <h2 class="text-2xl uppercase mb-10">
        Art Deco Enterprises projects with Nook
      </h2>
      <div class="grid grid-cols-1 gap-y-10 lg:grid-cols-3 lg:gap-10 contractor-projects-gallery">
        <div>
          <img class="mb-4" src="https://d10ysazx19ptke.cloudfront.net/nookassets/contractors/contractor-project-1.svg"/>
          <h4 class="text-xl text-left">
            Project #27690
          </h4>
        </div>
        <div>
          <img class="mb-4" src="https://d10ysazx19ptke.cloudfront.net/nookassets/contractors/contractor-project-2.svg"/>
          <h4 class="text-xl text-left">
            Project #27690
          </h4>
        </div>
        <div>
          <img class="mb-4" src="https://d10ysazx19ptke.cloudfront.net/nookassets/contractors/contractor-project-3.svg"/>
          <h4 class="text-xl text-left">
            Project #27690
          </h4>
        </div>
        <div>
          <img class="mb-4" src="https://d10ysazx19ptke.cloudfront.net/nookassets/contractors/contractor-project-4.webp"/>
          <h4 class="text-xl text-left">
            Project #27690
          </h4>
        </div>
      </div>
    </div>
  </div>
  <div class="px-5 pt-20 pb-10 flex flex-col items-center">
    <h2 class="text-2xl">
      Build with the best
    </h2>
    <div class="mb-10 flex flex-col gap-10 items-center lg:flex-row lg:justify-center lg:gap-20">
      <img class="w-36 h-36" src="https://d10ysazx19ptke.cloudfront.net/nookassets/contractors/contractor-logo.svg"/>
      <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg"
           >
        <path d="M.926 3.075L3.879.122l22.804 22.804-2.953 2.953L.926 3.075z" fill="#000"></path>
        <path d="M3.879 25.879L.926 22.926 23.73.122l2.953 2.953L3.879 25.879z" fill="#000"></path>
      </svg>
      <span class="text-2xl">
        NOOK
      </span>
    </div>
    <a
        href="https://designer.nookrenovation.com/"
        target="_blank"
        class="w-60 h-12 bg-primary text-black uppercase flex items-center justify-center text-xs
            hover:bg-custom-hover text-white my-5">
      <span>{{ $t("navbar.pricing") }}</span>
    </a>
  </div>
</template>
<script>
export default {
  name: 'ContractorDetails',
}
</script>
<style scoped>
.contractor-question {
  @apply text-base mb-3;
}

.contractor-answer {
  @apply text-sm mb-5;
}

.contractor-answer p {
  @apply text-sm mb-3;
}

@media screen and (max-width: 640px) {
  .contractor-highlight {
    max-width: 100vw;
  }
}

@media screen and (min-width: 1024px) {
  .contractor-details-banner {
    height: 680px;
    display: grid;
    grid-template: 'image' 680px/1fr;
    place-items: center;
  }

  .contractor-details-interview {
    max-width: 1200px;
  }

  .contractor-details-interview h2,
  .contractor-details-interview h4,
  .contractor-details-interview p {
    max-width: 600px;
  }

  .contractor-projects-gallery img {
    height: 300px;
    min-width: 400px;
  }

}
</style>
