<template>
  <div class="">
    <ul v-if="pages" class="list-reset flex items-center justify-center">
      <li class="pagination-button mr-2">
        <a href="#"
           class="no-underline text-grey-darker block flex items-center justify-center h-full"
           :class="{'bg-grey-lightest text-grey cursor-not-allowed': currentPage === 1}"
           @click.prevent="getPreviousPage">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
          </svg>
        </a>
      </li>
      <li v-for="(page, index) in range"
          :key="index"
          class="pagination-button mr-2"
          :class="{'border': index === currentPage - 1}"
      >
        <a v-if="page !== '...'"
           href="#"
           class="no-underline text-grey-darker block flex items-center justify-center h-full"
           :class="{'bg-blue-lighter' : page === currentPage}"
           @click.prevent="getPage(page)">
          <span>{{page}}</span>
        </a>
        <a v-else href="#" class="no-underline text-grey-darker block py-3 px-4">
          {{page}}
        </a>
      </li>
      <li class="pagination-button">
        <a href="#"
           class="no-underline text-grey-darker block flex items-center justify-center h-full"
           :class="{'bg-grey-lightest text-grey cursor-not-allowed': currentPage >= pages}"
           @click.prevent="getNextPage">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
          </svg>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    pages: {
      type: Number,
      default: 0
    },
    currentPage: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      range: []
    }
  },
  computed: {
    propsToWatch() {
      return this.pages, this.currentPage, Date.now()
    }
  },
  watch: {
    propsToWatch: {
      handler: 'organisePageLinks',
      immediate: true
    }
  },
  methods: {
    organisePageLinks() {
      this.range = []
      for (let i = 1; i <= this.pages; i++) {
        if (
            i === 1 || // first page
            i === this.pages ||
            i === this.currentPage ||
            i === this.currentPage - 1 ||
            i === this.currentPage + 1 ||
            (i <= 4 && this.currentPage < 4) ||
            (i >= this.pages - 3 && this.currentPage > this.pages - 3)
        ) {
          let index = this.range.length
          if (index > 0 && this.range[index - 1] < i - 1) {

            this.range.push('...')
          }
          this.range.push(i)
        }
      }
    },
    getPage(page) {
      this.$emit('page-changed', page)
    },
    getPreviousPage() {
      this.getPage(this.currentPage - 1)
    },
    getNextPage() {
      this.getPage(this.currentPage + 1)
    }
  }
}
</script>
<style>
.pagination-button {
  @apply w-12 h-12 rounded-md bg-gray inline-block;
}
</style>
