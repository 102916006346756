export default {
  language: {
    vi: "Vietnamese",
    en: "English"
  },
  meta: {
    des: 'Nook is an all-in-one home platform to remodel and redesign homes'
  },
  page_title: {
    home: 'Nook',
    bathroom: 'Packages',
    gallery: 'Gallery',
    faq: 'Faq',
    financing: 'Financing',
    blog: 'Blog',
    about_us: 'About us',
    agents: 'Agents',
    careers: 'Careers',
    estimate: 'Estimate',
    packages: 'Packages',
    reviews: 'Reviews',
    privacy_policy: 'Privacy policy',
    term_of_service: 'Terms of service',
    contractors: 'Contractors',
    how_it_works: 'How it works'
  },
  navbar: {
    home: 'Home',
    packages: 'Bathroom Packages',
    contractors: 'For Contractors',
    gallery: 'Gallery',
    faq: 'Faq',
    financing: 'Financing',
    blog: 'Blog',
    pricing: 'Get Estimate',
    howitworks: 'How it works',
    reviews: 'Reviews',
    agents: 'Agents',
    language: 'Language'
  },
  homepage: {
    slogan: 'BATHROOM RENOVATIONS JUST GOT A LOT EASIER',
    subslogan: 'Nook brings design, materials, and construction all under one roof for a simple renovation package',
    allinclusive: 'Get Your All-Inclusive Renovation Estimate',
    allinclusivetext: {
      construction: 'Construction',
      materials: 'Materials',
      design: 'Design',
      support: 'Support',
      pickingbudget: 'Get started by picking your budget',
      affordable: 'Affordable',
      standard: 'Standard',
      luxury: 'Luxury',
    },
    sponsors: {
      cafebiz: 'Nook takes the role of handling the entire process.',
      cafef: 'The unique and different feature of Nook is the virtual reality tool that presents the 3D bathroom space.',
      afamily: 'With just a few touches, customers can freely choose the design and create the bathroom they want.',
      vdas: "Nook is the construction-tech startup focused on Vietnam's home improvement sector.",
      beststartup: 'All-in-one home platform to remodel and redesign homes in Southeast Asia.'
    },
    statements: {
      title: 'QUALITY AND SIMPLICITY YOU CAN TRUST',
      full_service: {
        title: "Full Service",
        stt1: "Pick your favorite bathroom style and we will renovate from A to Z. Our team will remove the frustration out of the process for you",
        stt2: "Your price estimate includes the full scope of your intended renovation. The estimate is divided into three parts: materials, designs, and construction.",
        stt3: "Materials, consisting of all fixtures, finishes, and shipping, is brought to you at affordable prices because we pass on discounts from bulk volume purchases. Designs are high-end templates that are constructed with materials made to go well together. Finally, construction is provided to you through pre-negotiated rates with vetted contractors so you know prices are fair."
      },
      beautiful_design: {
        title: "Beautiful Design",
        stt1: 'We work with a team of leading interior designers and architects to help you come up with the best solution for your dream bathroom.',
        stt2: 'Our designs are high-end templates that can be customized by yourself or with our in-house design team to ensure you get your dream designs'
      },
      quality_materials: {
        title: "Quality Materials",
        stt1: 'All of the fixtures and materials we supply are high quality products from brands such as TOTO, INAX, American Standard, and Kohler.',
        stt2: 'Since appliances are a critical part of your bathroom in terms of functionality and design, we recommend you select appliances that work with your specific preferences, lifestyle, and budget.'
      },

      professional_team: {
        title: "Professional Team",
        stt1: 'When you renovate through Nook, you are matched with a vetted contractor. We look for experience, quality, and reputation in the field. Our process includes an interview, background checks, and reference calls.',
        stt2: 'By working together on many projects a year, we develop strong relationships with our contractors, which allows us to ensure greater reliability than when working with a contractor on a one-time basis.'
      },

      price_transparency: {
        title: "Price Transparency",
        stt1: 'Worried about extra costs? Unlike most contractors, we guarantee transparent pricing to limit unnecessary price changes.',
        stt2: 'With Nook you’ll be able to see detailed cost breakdowns before you start construction so there are no surprises.'
      }

    },
    complete_platform: {
      title: 'ALL-IN-ONE PLATFORM',
      description: 'Nook provides you with a comprehensive renovation bundle, inclusive of designs, materials, and construction, all in one simple platform.',
      learn: 'See how it works',
      plan: {
        title: 'GET YOUR ESTIMATE',
        stt1: 'Mix and match products using the designs on our website to fit your style, then receive an instant price estimate.'
      },
      design: {
        title: 'CONSULT WITH EXPERTS',
        stt1: 'Work with our expert designers to tailor the design to your specific bathroom layout.'
      },
      build: {
        title: 'BUILD WITH CERTAINTY',
        stt1: "Our Nook-approved builders will complete your renovation with the highest quality and on time."
      }
    },
    renovate: {
      title: '0% Interest for up to 12 months',
      explore: 'EXPLORE FINANCING'
    },
    paranoma: {
      title: "Explore what's possible in 360˚",
      des: "See the high quality materials we work with and how it could look in your space.",
      photo1: {
        title: 'PENTHOUSE'
      },
      photo2: {
        title: 'ENCAUSTIC'
      },
      photo3: {
        title: 'HUNTINGTON'
      },
      photo4: {
        title: 'SCANDINAVIAN'
      },
      more: 'SEE MORE'
    },
    ours: {
      title: 'OUR RENOVATIONS',
      bathroom: {
        title: 'Bathrooms',
        week: '4 WEEKS AVERAGE BUILD',
        des: 'Build your dream bathroom with premium fixtures and finishes.',
        learn: 'LEARN MORE'
      },
      contractors: {
        title: 'Contractors',
        week: '6 WEEKS AVERAGE BUILD',
        des: 'Create your ideal kitchen with quality, lasting materials.',
        learn: 'LEARN MORE'
      }
    },
    why: {
      title: 'Why Nook?',
      typical_ex: {
        stt1: 'Typical',
        stt2: 'Experience'
      },
      with_nook: {
        stt1: 'With',
        stt2: 'Nook'
      },
      reno_ex: {
        title: 'Renovation Experience',
        stt1: 'Time consuming and fragmented',
        stt2: 'All inclusive bundles'
      },
      design: {
        title: 'Design',
        stt1: 'Start from scratch',
        stt2: 'Design with our expert guidance'
      },
      materials: {
        title: 'Materials',
        stt1: 'Order and manage yourself',
        stt2: 'Ordered, inspected and delivered'
      },
      support: {
        title: 'Project Support',
        stt1: 'No support',
        stt2: 'Start-to-finish support'
      },
      construction: {
        title: 'Construction',
        stt1: 'Unvetted',
        stt2: 'Nook-Certified contractor'
      },
    },
    project_gallery: {
      title: 'PROJECT GALLERY',
      des: 'Discover what clients were able to achieve with Nook.',
      view: 'VIEW PROJECT GALLERY'
    },
    review: {
      title: 'Featured Client Reviews',
      des: 'Learn why our customers chose Block for their renovation.',
      view: 'SEE MORE REVIEWS',
      user1: {
        name: 'Project owner, District 7, HCMC',
        date: 'Mar 13, 2021',
        stt: '“Good quality construction and helpful staff“',
        tag: {
          tag1: 'CONDO',
          tag2: 'CONTRACTORS'
        }
      },
      user2: {
        name: 'Project owner, District 5, HCMC',
        date: 'Mar 13, 2021',
        stt: "“I renovated my 2 bathrooms with Nook, feel very satisfied because the price is very clear. I hope Nook has kitchen renovation service so that I can work for my kitchen.“",
        tag: {
          tag1: 'CONDO',
          tag2: 'CONTRACTORS'
        }
      },
      user3: {
        name: 'Project owner, District 2, HCMC',
        date: 'Mar 13, 2021',
        stt: '“Many beautiful designs, I chose the Minimalist, it looks very minimalist and chic.“',
        tag: {
          tag1: 'CONDO',
          tag2: 'CONTRACTORS'
        }
      },
      user4: {
        name: 'Project owner, District 1, HCMC',
        date: 'Mar 13, 2021',
        stt: '“Thanks Nook for your enthusiastic support for my home. My wife and I really love the new bathroom.“',
        tag: {
          tag1: 'CONDO',
          tag2: 'CONTRACTORS'
        }
      },
    },
  },
  howitworks: {
    title: 'HOW IT WORKS',
    des: 'Achieve your dream bathroom with Nook’s expert designers and builders',
    stt1: 'Products, materials, labor included',
    stt2: 'Design and 3D rendering included',
    stt3: 'Quality and timeline guaranteed',
    stt4: '26,000,000 VND',
    stt5: 'Starting Price for a Nook bathroom',
    step1: {
      title: 'GET YOUR ESTIMATE',
      subtitle: '1. GET A PRICE ESTIMATE FOR YOUR BATHROOM',
      des: 'After picking a design that fits your style, you can get an instant estimate for the price of your bathroom.',
      faq1: {
        title: 'Thousands of design possibilities',
        des: 'With over a dozen designs, hundreds of products, and thousands of combinations, you can find the design that fits you.'
      },
      faq2: {
        title: 'Sophisticated Pricing Algorithm',
        des: 'Our project planners are our resident renovation experts. From co-ops to single family houses, rip & replace renos to full gut builds, they’ve seen it all. They’ll work with you to set up your project, and teach you'
      },

      faq3: {
        title: 'Virtual Designer Tool',
        des: 'Using our virtual designer tool, you will be able to visualize your bathroom design in real-time.'
      },
    },
    step2: {
      title: 'DESIGN PROFESSIONALS',
      subtitle: '2. CONSULT WITH OUR DESIGN PROFESSIONALS',
      des: 'Our designers and architects will help customize your chosen style to fit your bathroom layout.',
      faq1: {
        title: 'Expert Team',
        des: 'Our team of designers and architects are experts in their field. We will help make design changes if you want to modify your style with different products and materials.'
      },
      faq2: {
        title: 'Quality Inspection',
        des: 'Before finalizing your contract, our team will thoroughly inspect your current bathroom to identify any potential issues or complications that might come up during construction. That way, we can properly prepare for the renovation.'
      },
      faq3: {
        title: '360 3D View',
        des: 'After finalizing your design, our team will provide you with a full 360 degree rendering so you can visualize what it’s like to be inside your new bathroom.'
      }
    },
    step3: {
      title: 'BUILD WITH CERTAINTY',
      subtitle: '3. BUILD WITH OUR NOOK-APPROVED CONTRACTORS',
      des: 'Nook will build your bathroom according to the agreed price, quality, and timeline. Guaranteed.',
      faq1: {
        title: 'Quality Contractors',
        des: 'Our contractor network has a 3% acceptance rate. This means that we work with the best so that your bathroom is built with the highest quality.'
      },
      faq2: {
        title: 'Price Guarantee',
        des: 'You will never have unnecessary price increases or confusing fees. The price agreed in the final quote is the final price. That way, you will always know exactly what everything costs and why.'
      },
      faq3: {
        title: 'Timeline Certainty',
        des: 'As soon as we start construction, you will be able to enjoy your new bathroom in no more than 7 business days.'
      }
    },
    statements: {
      title: 'Nook Provides',
      full_service: {
        title: "Full Service",
        stt1: "Pick your favorite bathroom style and we will renovate from A to Z. Nook will take care of the design process from materials, labor, to construction.",
        stt2: "For bathroom plumbing fixtures, Block offers national brands such as Kohler and Delta.",
        stt3: "Appliances are a critical component of your bathroom in terms of design and functionality. We recommend that you select your own appliances that work with your specific preferences, lifestyle, and budget."
      },
      beautiful_design: {
        title: "Beautiful Design",
        stt1: 'We work with a team of leading interior designers and architects to help you come up with the best solution for your dream bathroom.',
        stt2: 'Materials consists of all fixtures, finishes, and associated shipping costs. Through our relationship with our vendors, we pass trade discounts onto you. Services includes design services, building approval, 3D renderings, procurement, and project support from beginning to end of your renovation. Finally, Construction consists of home protection, demolition, and labor by a certified and vetted partner contractor.'
      },
      quality_materials: {
        title: "Quality Materials",
        stt1: 'All of the fixtures and materials we supply are high quality products from brands such as TOTO, INAX, American Standard, and Kohler.',
        stt2: 'For bathroom plumbing fixtures, Block offers national brands such as Kohler and Delta. Appliances are a critical component of your bathroom in terms of design and functionality. We recommend that you select your own appliances that work with your specific preferences, lifestyle, and budget.'
      },
      professional_team: {
        title: "Professional Team",
        stt1: 'Your bathroom will be built by a quality contractor carefully selected by Nook for quality and efficiency so that bathroom installation is hassle-free.m description.',
        stt2: 'By working together on multiple projects a year, we develop strong relationships with our partners, ensuring much greater reliability than when working with a contractor on a one-time basis.'
      },
      price_transparency: {
        title: "Price Transparency",
        stt1: 'Worried about extra costs? Unlike most contractors, we guarantee transparent pricing to limit unnecessary price changes.',
        stt2: 'By working together on multiple projects a year, we develop strong relationships with our partners, ensuring much greater reliability than when working with a contractor on a one-time basis.'
      }
    },
  },
  gallery: {
    title: 'PROJECT GALLERY',
    case_study: 'Featured Case Studies',
    project: 'Featured Projects',
    filter: 'Filter'
  },
  packages: {
    title: 'SELECT A BATHROOM PACKAGE',
    des: 'Explore our bathroom packages and get an instant estimate. Each design library features thoughtfully selected fixtures and finishes from leading brands.',
    scandinavian: {
      title: 'SCANDINAVIAN',
      des: 'The cool gray, warm wood, and splash of color will create a comfortable and inviting space for your bathroom',
      more: 'LEARN MORE'
    },
    huntington: {
      title: 'HUNTINGTON',
      des: 'The smooth wall tones contrasted with the patterned floor make for a luxurious, classic feel.',
      more: 'LEARN MORE'
    },
    japandi: {
      title: 'JAPANDI',
      des: 'The combination of Japanese minimalism and Scandinavian functionality creates the perfect blend of function and form, focusing on clean lines, bright spaces, and soothing colors.',
      more: 'LEARN MORE'
    },
    hexagon_flowers: {
      title: 'HEXAGON FLOWERS',
      des: 'The hexagon tiles bring an exciting balance of rigid corners and a circular shape to give a feeling of novelty and luxury to your bathroom.',
      more: 'TÌM HIỂU THÊM'
    },
    we_know_renovations: 'We know renovations',
    change_order_promise: 'Guaranteed Quality',
    change_order_promise_paragraph: 'Change Order Promise Pr',
    insurance_coverage: 'Beautiful Designs',
    insurance_coverage_paragraph: 'Insurance Coverage Pr',
    design_satisfaction_promise: 'Transparent Prices',
    design_satisfaction_promise_paragraph: 'Design Promise Pr',
    finance_avai: 'Simple financing available',
    finance_avai_more: 'LEARN MORE',
    common_question: {
      title: 'COMMON QUESTIONS',
      faq1: {
        title: "Does Nook have any other designs besides the ones on the website?",
        stt1: 'Yes. You can use the online 3D design tool to pick a design that is closest to what you want and then leave your contact information. Our design team will contact you to modify the design to your favorite style.',
      },
      faq2: {
        title: "Does Nook build new bathrooms?",
        stt1: 'Currently, Nook renovates the bathroom in the location where the electricity and water system is available. We do not yet support new construction.',
      },
      faq3: {
        title: "Can I keep some of my existing fixtures, such as the shower or faucet?",
        stt1: 'Yes, renovations are still possible if you want to keep some of your existing fixtures. However, Nook is not responsible for the warranty/maintenance of the customer"s own equipment.',
      },
      faq4: {
        title: "Does Nook sell fixtures or materials separately?",
        stt1: 'Not yet. We only do full construction renovations with the materials included.'
      },
      faq5: {
        title: "I have my construction team and equipment available. Does Nook accept custom designs?",
        stt1: 'Unfortunately, we do not accept designs created by anyone outside of Nook.',
      },
      faq6: {
        title: "Do you work on areas other than the bathroom?",
        stt1: 'While we do focus primarily on bathrooms, we have gotten so many requests for other rooms that we have begun working on other areas of the home. If you have plans to renovate more than just your bathroom, please let us know.',
      },
      faq7: {
        title: "What does the price on the website include?",
        stt1: 'The price on the website includes the bathroom fixtures and construction (labor and materials for dismantling, transporting debris, anti-aging, plastering, tiling, painting ceiling, and installing fixtures).',
      },
      faq8: {
        title: "How can I pay?",
        stt1: 'Nook accepts cash payments and wire transfers.'
      },
      faq9: {
        title: "Does Nook accept construction outside Ho Chi Minh area?",
        stt1: 'Currently, Nook does not accept construction outside Ho Chi Minh area. We will be in your area soon though.',
      },
      faq10: {
        title: "What is Nook's warranty policy?",
        stt1: 'Nook has a 1-year warranty policy for construction quality and Nook-branded fixtures.',
      },
      faq11: {
        title: "How long does construction take?",
        stt1: 'Nook guarantees construction within 07 days, excluding Saturday and Sunday'
      },
    },
    view_all_faqs: 'VIEW ALL QUESTIONS ON OUR FAQ',
    still_question: 'Still have questions?',
    call_us: 'CALL US',
    phone: '0394 629 013'
  },
  packagedetail: {
    quality: {
      title: 'Quality materials + professional design',
      des: 'The high quality materials blended with professionally curated design templates guarantee a premium bathroom for you no matter how much or how little you want to customize it.'
    },
    start_from: 'STARTS FROM',
    des1: 'Products, materials, labor included',
    des2: 'Design and 3D rendering included',
    des3: 'Quality and timeline guaranteed',
    customize: {
      title: 'CUSTOMIZE IT YOUR WAY',
      des: 'There are dozens of customization options to choose from to make your bathroom fit your style. Each option has been carefully chosen by our design experts to ensure it looks good no matter how you want to customize it.',
      des2: 'Take a look at a few of those options here.'
    },
    vanity: 'Vanity + Mirror Shelf Combos',
    shower: 'Showers',
    tile: 'Tiles',
    scandinavian: {
      name: 'Scandinavian Packages',
      des: 'The cool gray, warm wood, and splash of color will create a comfortable and inviting space for your bathroom.',
      price: '48,776,000 VND',
    },
    huntington: {
      name: 'Huntington Packages',
      des: 'Inspired by the classic styles found in Long Island, New York, this design brings a simple yet luxurious feel to your home.',
      price: '30,873,200 VND',
    },
    japandi: {
      name: 'Japandi Packages',
      des: 'The combination of Japanese minimalism and Scandinavian functionality creates the perfect blend of function and form, focusing on clean lines, bright spaces, and soothing colors.',
      price: '50,718,000 VND',
    },
    hexagon_flowers: {
      name: 'Hex Flower Packages',
      des: 'The hexagon tiles bring an exciting balance of rigid corners and a circular shape to give a feeling of novelty and luxury to your bathroom.',
      price: '33,908,000 VND',
    },
  },
  contractors: {
    title: 'BUILD TOGETHER. BUILD MORE',
    des: 'When you become one of Nook’s partners, you will get access to our customers, our processes, and our products so you can focus on what you do best: building.',
    application: 'APPLY NOW',
    meet: {
      title: 'RENOVATION, MEET COLLABORATION',
      profile: {
        title: 'Profile Matching',
        des: 'Our proprietary algorithm matches contractors to projects by finding the perfect fit given location, availability, field of expertise, and ongoing workloads.'
      },
      design: {
        title: 'DESIGN & PROCUREMENT',
        des: 'Our proprietary algorithm matches contractors to projects by finding the perfect fit given location, availability, field of expertise, and ongoing workloads.'
      },
      management: {
        title: 'BUILD MANAGEMENT',
        des: 'Our proprietary algorithm matches contractors to projects by finding the perfect fit given location, availability, field of expertise, and ongoing workloads.'
      },
      guarantee: {
        title: 'SIGN-OFF GUARANTEE',
        des: 'Our proprietary algorithm matches contractors to projects by finding the perfect fit given location, availability, field of expertise, and ongoing workloads.'
      }
    },
    network: {
      title: 'OUR NETWORK',
      slogan: 'NOOK BUILDS WITH THE BEST',
      des: 'Our network accepts only a handful of applicants each year. When you build with Nook, you’re building with the best.'
    },
    certified1: {
      title: 'What does it mean to be a Nook-certified contractor?',
      faq1: {
        title: 'Consistent quality',
        des: 'Our contractors demonstrate the highest quality standards and a commitment to perfection.'
      },
      faq2: {
        title: 'Personality fit',
        des: 'We make sure to work with the best; this means contractors who are excited to learn and grow, and equally obsessed as we are with customer satisfaction.'
      },
      faq3: {
        title: 'Trial period',
        des: 'After working together for a few projects as a trial period, we evaluate long term fit. Only after this period is completed, the contractor becomes fully Nook-certified.'
      },
      faq4: {
        title: 'Virtual Designer Tool',
        des: 'Using our virtual designer tool, you will be able to visualize your bathroom design in real-time.'
      },
    },
    certified2: {
      title: 'How do I become Nook-certified?',
      faq1: {
        title: 'Begin application',
        des: 'Apply using the links on this page and then fill out all the required information'
      },
      faq2: {
        title: 'Meet our team',
        des: 'After reviewing your portfolio we will invite you to an interview so we can get to know each other a little better.'
      },
      faq3: {
        title: 'Complete successful trial period',
        des: 'During your trial period, we will work closely with you and support you along the way to ensure your success.'
      },
      faq4: {
        title: 'Virtual Designer Tool',
        des: 'Using our virtual designer tool, you will be able to visualize your bathroom design in real-time.'
      },
    },
    apply: {
      title: 'Apply to get Block Certified.',
      action: 'Apply Here'
    },
    working: {
      title: 'WORKING TOGETHER',
      des: '"The contractor we were matched with was great. I really enjoyed Luis’ team — they were communicative and I loved seeing the steps come together bit by bit. It felt like we were tackling it step-by-step and getting it right" together.',
      author: 'Erica G, homeowner'
    },
    technology: {
      title: 'OUR TECHNOLOGY',
      subtitle: 'TRANSFORMATIVE TOOLS FOR THE TRADE',
      des: 'Milestone notifications, project task list, cash-flow management, digital build handbook, procurement tracker, and more.'
    },
    ready: {
      title: 'Ready to Renovate?',
      des: 'Get Your Estimate'
    },
    contractor_list: {
      title: 'MEET SOME OF OUR CONTRACTORS',
      ocean_pac: 'A team of highly skilled and dynamic technicians and designers, providing quality assurance products and services to customers.',
      thinh_phat: "Always listen to customers' opinions, work closely with customers to make sure that they receive the right products, and on time."
    }
  },
  aboutus: {
    pr0: 'What is Nook?',
    pr1: 'In English, a “nook” is a small cozy space in a room, such as a corner or alcove with a small bench for sitting and relaxing. It represents comfort and relaxation. It"s closest-sounding analog in Vietnamese is “nước,” meaning water. This represents freshness and cleanliness. Nook’s philosophy combines these two things to make a bathroom: a comfortable and relaxing space for you to feel clean and refreshed.',
    pr2: 'Nook’s goal is to bring cozy, beautiful bathrooms to every homeowner in Vietnam. That’s why we combine professional designs and quality construction together with our Virtual Planner Tool to provide one simplified renovation process.',
    pr3: 'Meet the founders',
    pr4: 'Nook was founded in early 2021 by Robert Huynh and Nathaniel Ricca, two friends passionate about making an impact in Vietnam.',
    pr5: 'Robert, a Vietnamese-American who grew up in the United States, always knew he wanted to move back to Vietnam one day and build something meaningful here. He was the first of his family to go to college and after completing his degree at the University of Texas, he made his way to Silicon Valley. He spent nearly a decade on the west coast at companies like Microsoft and Google, working on everything from strategy and finance to cryptocurrencies and self-driving cars. In 2020, he eventually decided to take a break from tech and pursue an MBA at Harvard Business School. This is where he met his close friend and co-founder, Nathaniel.',
    pr6: 'Nathaniel grew up in rural America to an immigrant mother from the Philippines and father from New York. With a love for art, he pursued graphic design for a brief period before switching to business, eventually completing a college degree at the University of Virginia. He then went on to work at Volkswagen Group, helping navigate the company through the fallout of the 2015 diesel scandal. After some time, he too decided to pursue an MBA, with the intention of improving his skills to one day start a company.',
    pr7: 'After their first year in school, Robert and Nathaniel flew from Boston to Ho Chi Minh city to start Nook. This was in the summer of 2021 when COVID hit the city hard, but 3 weeks in a quarantine hotel didn’t slow them down. They built a small but strong team and moved quickly. By the end of the summer, they saw how much traction Nook was getting so they decided to leave HBS indefinitely to stay in Vietnam and work on Nook full-time.'
  },
  finance: {
    slogan: 'Financing Support - Build Now Pay Later',
    des: "Nook cooperates with Mpos and 29 affiliated banks to implement a 0% interest installment program to reduce customers' financial pressure, bathroom renovation has never been easier and more convenient than with Nook",
    guarantee1: '0% interest up to 12 months',
    guarantee2: '0 VND conversion fee',
    guarantee3: 'Simple procedure, just credit card',
    guarantee4: 'Nook consultation and support is completely free.'
  },
  careers: {
    slogan: 'Redesign the $120B home renovation market with us!',
    des: 'Nook is all-in-one home platform to remodel and redesign homes in Southeast Asia. We offer a hassle-free renovation package to homeowners through tech-enabled visualization, curated design templates, and best in class construction.',
    roles: 'View Open Roles',
    aboutus: {
      title: 'About Us',
      des: 'Nook was founded in 2021 by Robert Huynh and Nathaniel Ricca, two classmates passionate about making an impact in Vietnam.',
    },
    more: 'LEARN MORE',
    our_value: {
      title: 'Our values',
      stt1: 'Make something people want',
      stt2: 'Empowered',
      stt3: 'Get sh!t done',
      stt4: 'Frugality',
      stt5: 'Think big',
      stt6: 'Earn trust',
      stt7: 'Speak up'
    },
    join: {
      title: 'Why Others Joined Nook',
      van: {
        name: 'Võ Bích Vân',
        job: 'Design Consultant',
        statements: "I feel very excited to be working at Nook because of the modern, dynamic and constantly creative team. We're all working towards the same goal: applying technology to simplify and improve the quality of life while solving problems in each family's living space. It is a great combination among the technology, design, and construction team."
      },
      myky: {
        name: 'Nguyễn Lê Mỹ Kỳ',
        job: 'Project Manager',
        statements: 'I like the idea and vision of Nook for getting technology into the bathroom renovation which would be an improvement  for the construction industry in Viet Nam. At the productive working enviroment that Nook has been building, i feel free to create, to speak up and all the contributions are listened respectfully',
      }
    }
  },
  footer: {
    home: 'Home',
    packages: 'Bathroom Packages',
    contractors: 'For Contractors',
    gallery: 'Gallery',
    faq: 'Faq',
    financing: 'Financing',
    blog: 'Blog',
    pricing: 'Get Estimate',
    howitworks: 'How it works',
    reviews: 'Reviews',
    agents: 'Agents',
    language: 'Language',
    callus: 'About Us',
    careers: 'Careers',
    privacy: 'Privacy Policy',
    des: 'Nook is all-in-one home platform to remodel and redesign homes in Southeast Asia. We offer a hassle-free renovation package to homeowners through tech-enabled visualization, curated design templates, and best in class construction.'
  },
  info: {
    contact: 'Contact Us Now',
  },
}