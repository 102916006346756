<template>
  <div class="">
    <div class="tab__header">
      <a href="#"
         class="tab__link py-4 block no-underline flex items-center
                text-black border-t border-black border-dashed"
         :class="{'accordion-border-bottom': hasBorderBottom}"
         @click.prevent="active = !active">
        <span class="plus-arrow" v-show="!active">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4"/>
          </svg>
        </span>
        <span class="minus-arrow" v-show="active">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4" />
          </svg>
        </span>
        <span class="text-2xl lg:text-xl ml-2">{{ title }}</span>
      </a>
    </div>
    <div class="tab__content ml-2 mb-4 text-left" v-show="active">
      <slot/>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PlusAccordion',
  props: {
    title: String,
    isActive: Boolean,
    hasBorderBottom: Boolean
  },
  data() {
    return {
      active: false,
    }
  },
  watch: {
    isActive: {
      immediate: true,
      handler(newVal) {
        this.active = newVal;
      }
    }
  }
}
</script>
<style>
.accordion-border-bottom {
  @apply border-b border-black border-dashed;
}
</style>
