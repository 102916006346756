import { createI18n } from 'vue-i18n'
import en from "./en";
import vi from "./vi";

const messages = { en, vi };

export default createI18n({
    locale: "vi",
    fallbackLocale: 'en',
    messages,
})
