<template>
  <nav class="flex items-center justify-between flex-wrap bg-gray navbar p-0
    pl-0">
    <router-link :to="{name: 'home', params: { lang: this.$i18n.locale }}" class="pl-7 py-2 lg:pl-7"
      @click="closeSidebarOnMobile">
      <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/logo.png" class="w-12 h-12">
    </router-link>
    <div class="block lg:hidden py-2 pr-7">
      <button
          class="flex items-center px-3 py-2 border rounded text-gray-200 border-gray-400"
          @click="isNavbarOpenedOnMobile = !isNavbarOpenedOnMobile">
        <svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <title>Menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
        </svg>
      </button>
    </div>
    <div class="w-full block flex-grow
           lg:flex lg:items-center lg:w-auto lg:justify-between"
         v-if="screenSize >= 960 || isNavbarOpenedOnMobile">
      <div class="text-sm
                  lg:flex-grow lg:flex lg:justify-evenly lg:max-w-4xl">
        <div
            class="navbar-link
                   h-14 lg:h-full whitespace-nowrap"
            v-for="items in navItems"
            :key="items"
        >
          <NavItem
              :itemName="items"
              :options="options"
              @close-navbar="closeSidebarOnMobile()">

          </NavItem>
        </div>
      </div>
      <div class="w-full h-16 flex justify-end
                    lg:max-w-sm lg:ml-10
                    ">
        <a href="https://designer.nookrenovation.com/"
           target="_blank"
           class="w-full h-full">
          <div
              class="w-full h-full bg-primary text-black uppercase flex items-center justify-center text-xs
                     hover:bg-custom-hover text-white">
            <span>{{ $t("navbar.pricing") }}</span>
          </div>
        </a>
      </div>
    </div>

  </nav>

</template>
<script>
import {NavItems} from "@/constants";
import NavItem from "@/components/NavItem";

export default {
  components: {NavItem},
  mounted() {
    this.checkScreenSize();
  },
  data() {
    return {
      navItems: NavItems,
      isNavbarOpenedOnMobile: false,
      screenSize: 0,
      options: [
        {
          flag: 'https://d10ysazx19ptke.cloudfront.net/nookassets/vi.png',
          value: 'vi',
          display: 'Vietnamese'
        },
        {
          flag: 'https://d10ysazx19ptke.cloudfront.net/nookassets/en.png',
          value: 'en',
          display: 'English'
        }
      ]
    }
  },
  methods: {
    checkScreenSize() {
      this.screenSize = window.innerWidth;

      window.addEventListener('resize', () => {
        this.screenSize = window.innerWidth;
      });
    },
    closeSidebarOnMobile() {
      if (this.screenSize < 960 && this.isNavbarOpenedOnMobile) {
        this.isNavbarOpenedOnMobile = false;
      }
    }
  }
}
</script>
<style scoped>
.navbar {
  border-bottom: 1px solid black;
}

.navbar-link {
  @apply
  block text-gray uppercase text-black text-xs font-medium
  hover:underline
  pl-7 m-0 flex items-center
  lg:mt-0
  ;
}

@media screen and (max-width: 1023px) {
  .navbar-link {
    border-top: 1px solid black;
    width: 100%;
  }
}

</style>
