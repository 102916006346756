export const GalleryProjectsTestData = [
    {
        id: 1,
        name: 'Project #12345',
        room: 'Bathroom',
        type: 'Condo'
    },
    {
        id: 2,
        name: 'Project #12345',
        room: 'Kitchen',
        type: 'Co-op'
    },
    {
        id: 3,
        name: 'Project #12345',
        room: 'Bathroom',
        type: 'Single or multi-family home'
    },
    {
        id: 4,
        name: 'Project #12345',
        room: 'Kitchen',
        type: 'Condo'
    },
    {
        id: 5,
        name: 'Project #12345',
        room: 'Bathroom',
        type: 'Co-op'
    },
    {
        id: 6,
        name: 'Project #12345',
        room: 'Kitchen',
        type: 'Single or multi-family home'
    },
    {
        id: 7,
        name: 'Project #12345',
        room: 'Bathroom',
        type: 'Single or multi-family home'
    },
    {
        id: 8,
        name: 'Project #12345',
        room: 'Bathroom',
        type: 'Single or multi-family home'
    },
    {
        id: 9,
        name: 'Project #12345',
        room: 'Kitchen',
        type: 'Co-op'
    },
    {
        id: 10,
        name: 'Project #12345',
        room: 'Bathroom',
        type: 'Condo'
    },
    {
        id: 11,
        name: 'Project #12345',
        room: 'Bathroom',
        type: 'Single or multi-family home'
    },
    {
        id: 12,
        name: 'Project #12345',
        room: 'Bathroom',
        type: 'Condo'
    },
    {
        id: 13,
        name: 'Project #12345',
        room: 'Kitchen',
        type: 'Co-op'
    },
    {
        id: 14,
        name: 'Project #12345',
        room: 'Bathroom',
        type: 'Single or multi-family home'
    },
    {
        id: 15,
        name: 'Project #12345',
        room: 'Kitchen',
        type: 'Condo'
    },
    {
        id: 16,
        name: 'Project #12345',
        room: 'Bathroom',
        type: 'Co-op'
    },
    {
        id: 17,
        name: 'Project #12345',
        room: 'Kitchen',
        type: 'Single or multi-family home'
    },
    {
        id: 18,
        name: 'Project #12345',
        room: 'Bathroom',
        type: 'Single or multi-family home'
    },
    {
        id: 19,
        name: 'Project #12345',
        room: 'Bathroom',
        type: 'Single or multi-family home'
    },
    {
        id: 20,
        name: 'Project #12345',
        room: 'Kitchen',
        type: 'Co-op'
    },
    {
        id: 21,
        name: 'Project #12345',
        room: 'Bathroom',
        type: 'Condo'
    },
    {
        id: 22,
        name: 'Project #12345',
        room: 'Bathroom',
        type: 'Single or multi-family home'
    },
];
