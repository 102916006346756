<template>
  <div class="py-16">
    <h1 class="text-3xl uppercase">
      {{ $t('gallery.title') }}
    </h1>
  </div>
  <div class="pb-4 px-4 mb-2">
    <div class="border-b border-black pb-2">
      <h2 class="text-xl text-left">
        {{ $t('gallery.case_study') }}
      </h2>
    </div>
  </div>
  <div class="case-studies overflow-x-scroll">
    <div class="flex px-2 pb-10">
      <CaseStudy
          v-for="study in allStudyData"
          :key="study.id"
          :study="study"
          @open-dialog="this.isCaseStudyModalVisible = true"
      >
      </CaseStudy>
    </div>
  </div>
  <div class="pb-4 px-4 mt-4 project-section flex justify-center">
    <div class="project-container w-full">
      <div class="border-b border-black pb-2 flex justify-between items-center">
        <h2 class="text-xl text-left">
          {{ $t('gallery.project') }}
        </h2>
        <button class="text-black text-xl py-2 px-4 rounded border border-black border-solid lg:hidden"
                @click="showModal">
          {{ $t('gallery.filter') }}
        </button>
      </div>
      <div class="project-showcase mt-6">
        <div class="projects flex">
          <div class="filter hidden lg:block mr-10">
            <div class="bg-gray rounded-sm w-64">
              <div class="filter-block p-4 border-t border-l border-r border-black">
                <h4 class="text-sm uppercase text-left">
                  {{ $t('gallery.project') }}
                </h4>
              </div>
              <div class="filter-block p-4 border border-black">
                <a href="#"
                   class="tab__link block no-underline flex justify-between
                  text-black w-full"
                   @click.prevent="roomFilterActive = !roomFilterActive">
                  <h4 class="text-md">
                    Room
                  </h4>
                  <span class="plus-arrow" v-show="!roomFilterActive">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4"/>
                    </svg>
                  </span>
                  <span class="minus-arrow" v-show="roomFilterActive">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4"/>
                    </svg>
                  </span>
                </a>
                <div class="mt-4" v-show="roomFilterActive">
                  <div class="mb-4">
                    <label class="flex items-center cursor-pointer">
                      <input type="checkbox"
                             :checked="roomFilters.includes('Bathroom')"
                             @change="addFilterToRoom('Bathroom')">
                      <h4 class="text-md ml-2">Bathroom</h4>
                    </label>

                  </div>
                  <div class="">
                    <label class="flex items-center cursor-pointer">
                      <input type="checkbox"
                             :checked="roomFilters.includes('Kitchen')"
                             @change="addFilterToRoom('Kitchen')">
                      <h4 class="text-md ml-2">Kitchen</h4>
                    </label>
                  </div>
                </div>
              </div>
              <div class="filter-block p-4 border-b border-l border-r border-black">
                <a href="#"
                   class="tab__link block no-underline flex justify-between
                  text-black w-full"
                   @click.prevent="buildingTypeFilterActive = !buildingTypeFilterActive">
                  <h4 class="text-md">
                    Building Type
                  </h4>
                  <span class="plus-arrow" v-show="!buildingTypeFilterActive">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4"/>
                    </svg>
                  </span>
                  <span class="minus-arrow" v-show="buildingTypeFilterActive">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4"/>
                    </svg>
                  </span>
                </a>
                <div class="mt-4" v-show="buildingTypeFilterActive">
                  <div class="mb-4">
                    <label class="flex items-center cursor-pointer">
                      <input type="checkbox"
                             :checked="typeFilters.includes('Condo')"
                             @change="addFilterToType('Condo')">
                      <h4 class="text-md ml-2">Condo</h4>
                    </label>
                  </div>
                  <div class="mb-4">
                    <label class="flex items-center cursor-pointer">
                      <input type="checkbox"
                             :checked="typeFilters.includes('Co-op')"
                             @change="addFilterToType('Co-op')">
                      <h4 class="text-md ml-2">Co-op</h4>
                    </label>
                  </div>
                  <div class="">
                    <label class="flex items-center cursor-pointer">
                      <input type="checkbox"
                             :checked="typeFilters.includes('Single or multi-family home')"
                             @change="addFilterToType('Single or multi-family home')">
                      <h4 class="text-md ml-2">Single or multi-family home</h4>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-col md:flex-row md:flex-wrap project-list"
               id="project-list"
               v-if="displayData && displayData.length > 0">
            <div class="project w-full mb-5" v-for="item in displayData" :key="item.id">
              <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/gallery/gallery1.webp" class="w-full">
              <h4 class="text-md text-left my-2">{{ item.name }}</h4>
            </div>

          </div>
          <div class="project-list flex justify-center items-center w-full" v-else>
            There is no data to display
          </div>
        </div>
      </div>
      <div class="project-pagination" v-if="displayData && displayData.length > 0">
        <Pagination :current-page="currentPage" :pages="totalPages" @page-changed="changePage"></Pagination>
      </div>
    </div>
  </div>
  <Modal
      v-show="isFilterModalVisible"
      @close="closeModal(); cancelFilterOnMobile()"
  >
    <template v-slot:header>
      <div class="uppercase text-md">
        FILTER
      </div>
    </template>
    <template v-slot:body>
      <div class="p-4 border-b border-black">
        <h4 class="text-xl text-left mb-4">
          Room
        </h4>
        <div class="mb-4">
          <label class="flex items-center cursor-pointer">
            <input type="checkbox"
                   :checked="roomFilters.includes('Bathroom')"
                   @change="addFilterToRoomOnMobile('Bathroom')">
            <h4 class="text-md ml-2">Bathroom</h4>
          </label>

        </div>
        <div class="">
          <label class="flex items-center cursor-pointer">
            <input type="checkbox"
                   :checked="roomFilters.includes('Kitchen')"
                   @change="addFilterToRoomOnMobile('Kitchen')">
            <h4 class="text-md ml-2">Kitchen</h4>
          </label>
        </div>
      </div>
      <div class="p-4">
        <h4 class="text-xl text-left mb-4">
          Building Type
        </h4>
        <div class="mb-4">
          <label class="flex items-center cursor-pointer">
            <input type="checkbox"
                   :checked="typeFilters.includes('Condo')"
                   @change="addFilterToTypeOnMobile('Condo')">
            <h4 class="text-md ml-2">Condo</h4>
          </label>
        </div>
        <div class="mb-4">
          <label class="flex items-center cursor-pointer">
            <input type="checkbox"
                   :checked="typeFilters.includes('Co-op')"
                   @change="addFilterToTypeOnMobile('Co-op')">
            <h4 class="text-md ml-2">Co-op</h4>
          </label>
        </div>
        <div class="">
          <label class="flex items-center cursor-pointer">
            <input type="checkbox"
                   :checked="typeFilters.includes('Single or multi-family home')"
                   @change="addFilterToTypeOnMobile('Single or multi-family home')">
            <h4 class="text-md ml-2">Single or multi-family home</h4>
          </label>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="p-4 flex items-center justify-between">
        <button class="w-1/2 h-12 border border-black uppercase bg-white mr-4"
                @click="closeModal(); resetAllFilters()">
          CLEAR ALL
        </button>
        <button class="w-1/2 h-12 bg-primary uppercase mr-4" @click="closeModal(); applyFilterOnMobile()">
          APPLY
        </button>
      </div>
    </template>
  </Modal>
  <CaseStudyModal
      v-show="isCaseStudyModalVisible"
      @close="closeCaseStudyModal()">

  </CaseStudyModal>
</template>
<script>
import Modal from "@/components/Modal";
import {GalleryProjectsTestData} from "@/constants/gallery-projects-test-data";
import Pagination from "@/components/Pagination";
import {GalleryStudiesTestData} from "@/constants/gallery-studies-test-data";
import CaseStudy from "@/components/CaseStudy";
import CaseStudyModal from "@/components/CaseStudyModal";

export default {
  name: 'Gallery',
  components: {CaseStudyModal, CaseStudy, Pagination, Modal},
  mounted() {
    this.filteredData = this.allProjectData;
    this.displayData = this.allProjectData;
    this.recalculatePagination();
  },
  data() {
    return {
      isFilterModalVisible: false,
      isCaseStudyModalVisible: false,
      roomFilterActive: false,
      buildingTypeFilterActive: false,
      allProjectData: GalleryProjectsTestData,
      allStudyData: GalleryStudiesTestData,
      roomFilters: [],
      typeFilters: [],
      tempRoomFilters: [],
      tempTypeFilters: [],
      filteredData: [],
      displayData: [],
      currentPage: 0,
      totalPages: 0
    };
  },
  methods: {
    showModal() {
      this.isFilterModalVisible = true;
    },
    closeModal() {
      this.isFilterModalVisible = false;
    },

    showCaseStudyModal() {
      this.isCaseStudyModalVisible = true;
    },
    closeCaseStudyModal() {
      this.isCaseStudyModalVisible = false;
    },

    addFilterToRoomOnMobile(room) {
      if (this.tempRoomFilters.includes(room)) {
        this.tempRoomFilters = this.tempRoomFilters.filter(filter => filter !== room);
      } else {
        this.tempRoomFilters.push(room);
      }
    },

    addFilterToTypeOnMobile(type) {
      if (this.tempTypeFilters.includes(type)) {
        this.tempTypeFilters = this.tempTypeFilters.filter(filter => filter !== type);
      } else {
        this.tempTypeFilters.push(type);
      }
    },

    applyFilterOnMobile() {
      this.roomFilters = this.tempRoomFilters;
      this.typeFilters = this.tempTypeFilters;

      this.applyFilters();
    },

    cancelFilterOnMobile() {
      this.tempRoomFilters = this.roomFilters;
      this.tempTypeFilters = this.typeFilters;
    },

    resetAllFilters() {
      this.roomFilters = [];
      this.typeFilters = [];
      this.tempRoomFilters = [];
      this.tempTypeFilters = [];

      this.applyFilters();
    },

    addFilterToRoom(room) {
      if (this.roomFilters.includes(room)) {
        this.roomFilters = this.roomFilters.filter(filter => filter !== room);
      } else {
        this.roomFilters.push(room);
      }

      this.tempRoomFilters = this.roomFilters;
      this.applyFilters();
    },
    addFilterToType(type) {
      if (this.typeFilters.includes(type)) {
        this.typeFilters = this.typeFilters.filter(filter => filter !== type);
      } else {
        this.typeFilters.push(type);
      }

      this.tempTypeFilters = this.typeFilters;
      this.applyFilters();
    },
    applyFilters() {

      let tempData = this.allProjectData;
      if (this.roomFilters.length > 0) {
        tempData = tempData.filter((data) => this.roomFilters.includes(data.room));
      }

      if (this.typeFilters.length > 0) {
        tempData = tempData.filter((data) => this.typeFilters.includes(data.type));
      }

      this.filteredData = tempData;

      this.recalculatePagination();
    },

    recalculatePagination() {
      this.totalPages = Math.floor(this.filteredData.length / 10) + 1;
      this.changePage(1);
    },

    changePage(page) {
      this.displayData = this.filteredData.slice((page - 1) * 10, page * 10);
      this.currentPage = page;
      const projectListElement = document.getElementById('project-list');

      if (projectListElement) {
        window.scrollTo(0, projectListElement.offsetTop);
      }
    }
  }
}
</script>
<style scoped>

.filter-popover {
  z-index: 9999;
}

.project-container {
  max-width: 1440px;
}

input[type=checkbox] {
  -ms-transform: scale(1.5); /* IE */
  -moz-transform: scale(1.5); /* FF */
  -webkit-transform: scale(1.5); /* Safari and Chrome */
  -o-transform: scale(1.5); /* Opera */
  transform: scale(1.5);
  padding: 10px;
}

@media screen and (min-width: 768px) {
  div.project:nth-of-type(even) {
    margin-left: 40px;
  }

  .project {
    width: calc(50% - 20px);
  }
}

@media screen and (min-width: 1024px) {
  .project-pagination {
    margin-left: 296px;
  }
}
</style>
