<template>
  <div class="modal-backdrop">
    <div class="case-study-modal bg-brown flex flex-col lg:flex-row">
      <header class="modal-header p-4">
        <div class="flex items-center justify-between w-full lg:flex-col lg:h-full">
          <button class="h-10 w-10 flex justify-center items-center rounded-md"
                  @click="close">
            <svg height="18" viewBox="0 0 20 18" width="20" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M9.42663 18L11.4286 16.0979L5.43246 10.3825H20V7.61753H5.43246L11.4286 1.89278L9.42663 0L0 9L9.42663 18Z"></path>
            </svg>
          </button>
          <div class="text-xl uppercase text-white lg:h-full lg:flex lg:items-end">
            <span style="transform: rotate(-90deg); margin-bottom: 16px">
              NOOK
            </span>
          </div>
          <button class="h-10 w-10 flex justify-center items-center rounded-md lg:mt-4">
            <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M8.46588 11.2931C9.59888 10.1601 11.5749 10.1601 12.7079 11.2931L13.4149 12.0001L14.8289 10.5861L14.1219 9.87906C13.1789 8.93506 11.9229 8.41406 10.5869 8.41406C9.25088 8.41406 7.99488 8.93506 7.05188 9.87906L4.92988 12.0001C3.99417 12.9388 3.46875 14.2101 3.46875 15.5356C3.46875 16.861 3.99417 18.1323 4.92988 19.0711C5.39375 19.5356 5.94484 19.9039 6.55149 20.1548C7.15814 20.4057 7.80839 20.5342 8.46488 20.5331C9.12154 20.5344 9.77199 20.406 10.3788 20.1551C10.9857 19.9042 11.5369 19.5358 12.0009 19.0711L12.7079 18.3641L11.2939 16.9501L10.5869 17.6571C10.0233 18.2181 9.26055 18.533 8.46538 18.533C7.67021 18.533 6.90741 18.2181 6.34388 17.6571C5.78238 17.0938 5.46708 16.3309 5.46708 15.5356C5.46708 14.7402 5.78238 13.9773 6.34388 13.4141L8.46588 11.2931Z"></path>
              <path
                  d="M11.9999 4.92924L11.2929 5.63624L12.7069 7.05024L13.4139 6.34324C13.9774 5.78223 14.7402 5.46727 15.5354 5.46727C16.3305 5.46727 17.0933 5.78223 17.6569 6.34324C18.2184 6.90651 18.5337 7.66941 18.5337 8.46474C18.5337 9.26007 18.2184 10.023 17.6569 10.5862L15.5349 12.7072C14.4019 13.8402 12.4259 13.8402 11.2929 12.7072L10.5859 12.0002L9.17188 13.4142L9.87887 14.1212C10.8219 15.0652 12.0779 15.5862 13.4139 15.5862C14.7499 15.5862 16.0059 15.0652 16.9489 14.1212L19.0709 12.0002C20.0066 11.0615 20.532 9.79016 20.532 8.46474C20.532 7.13932 20.0066 5.86795 19.0709 4.92924C18.1324 3.99304 16.861 3.46729 15.5354 3.46729C14.2098 3.46729 12.9383 3.99304 11.9999 4.92924Z"></path>
            </svg>
          </button>
        </div>
      </header>

      <section id="case-study-modal" class="w-full flex flex-col lg:flex-row"
               @wheel="triggerHorizontalScroll($event)">
        <div class="w-full bg-white p-6 rounded-2xl text-left margin-bottom-2 case-study-card">
          <div class="mb-10">
            <h3 class="text-md mb-2">PROJECT #12345</h3>
            <h2 class="text-4xl mb-2">Whimsical Tiles in Classic Bath</h2>
            <p class="text-md">
              This transformation is a breath of fresh air with colorful niche details and classic silhouettes. Tiles
              from
              Morocco are seamlessly incorporated into a timeless District 1 bath in this Ho Chi Minh City Co-op.
            </p>
          </div>
          <div class="p-6 bg-gray">
            <h3 class="text-sm mb-6">Ho Chi Minh City</h3>
            <div class="w-full map-iframe">
              <iframe
                  class="w-full"
                  src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d31355.26283683628!2d106.7034627!3d10.7800417!3m2!1i1024!2i768!4f13.1!5e0!3m2!1svi!2s!4v1645253274700!5m2!1svi!2s"
                  style="border:0;" allowfullscreen="" loading="lazy">
              </iframe>
            </div>
          </div>
        </div>
        <div class="margin-bottom-2 image-only case-study-card">
          <img class="rounded-2xl" src="https://d10ysazx19ptke.cloudfront.net/nookassets/gallery/gallery-modal-1.webp"/>
        </div>
        <div class="w-full bg-milk p-6 rounded-2xl text-left margin-bottom-2 case-study-card">
          <div>
            <h4 class="text-sm uppercase mb-2 font-semibold">
              PLAN
            </h4>
            <h3 class="text-xl uppercase mb-2">
              STAGE 01
            </h3>
            <p class="text-md">
              Every renovation starts somewhere. Based on the project’s details, we create a renovation roadmap to get
              our
              clients into their dream space as fast as possible.
            </p>
          </div>
          <div>
            <div class="stage-inner-card-padding">
              <h4 class="text-sm uppercase">Building Age</h4>
              <h3 class="text-xl">Built before 1945</h3>
            </div>
            <div class="stage-inner-card-padding-second">
              <h4 class="text-sm uppercase">Building Type</h4>
              <h3 class="text-xl">Co-op</h3>
            </div>
          </div>
        </div>
        <div class="w-full bg-milk p-6 rounded-2xl text-left margin-bottom-2 case-study-card">
          <div>
            <h4 class="text-sm uppercase mb-2 font-semibold">
              DESIGN
            </h4>
            <h3 class="text-xl uppercase mb-2">
              STAGE 02
            </h3>
            <p class="text-md">
              Form meets function thanks to a comprehensive design system that combines the best of both worlds. Curated
              materials, architect grade renderings, and an award-winning design team to tie it all together.
            </p>
          </div>
          <div class="stage-inner-card-no-padding">
            <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/gallery/gallery-modal-1.webp">
          </div>
        </div>
        <div class="w-full bg-milk p-6 rounded-2xl text-left margin-bottom-2 case-study-card">
          <div>
            <h4 class="text-sm uppercase mb-2 font-semibold">
              CONSTRUCTION PHOTOS
            </h4>
            <h3 class="text-xl uppercase mb-2">
              STAGE 03
            </h3>
            <p class="text-md">
              Each step is diligently recorded and reported so everyone can stay up to date.
            </p>
          </div>
          <div class="stage-images">
            <div class="stage-inner-card-no-padding">
              <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/gallery/gallery-modal-2.webp">
            </div>
<!--            <div class="stage-inner-card-no-padding">-->
<!--              <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/gallery/gallery-modal-3.webp">-->
<!--            </div>-->
<!--            <div class="stage-inner-card-no-padding">-->
<!--              <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/gallery/gallery-modal-4.webp">-->
<!--            </div>-->
          </div>
        </div>
        <div class="w-full bg-milk p-6 rounded-2xl text-left margin-bottom-2 case-study-card">
          <div>
            <h4 class="text-sm uppercase mb-2 font-semibold">
              COMPLETION
            </h4>
            <h3 class="text-xl uppercase mb-2">
              STAGE 04
            </h3>
            <p class="text-md">
              Because every space deserves a happily ever after.
            </p>
          </div>
          <div class="stage-inner-card-padding">
            <svg height="16" viewBox="0 0 120 17" width="110"><title>rating: 5 / 5</title>
              <path
                  d="M8.84 0.5L11.42 5.72L17.18 6.56L13.01 10.63L14 16.37L8.84 13.66L3.69 16.37L4.67 10.63L0.5 6.56L6.26 5.72L8.84 0.5Z"
                  fill="#000000" stroke="#000000" stroke-linejoin="round" stroke-miterlimit="10"></path>
              <g transform="translate(24,0)">
                <path
                    d="M8.84 0.5L11.42 5.72L17.18 6.56L13.01 10.63L14 16.37L8.84 13.66L3.69 16.37L4.67 10.63L0.5 6.56L6.26 5.72L8.84 0.5Z"
                    fill="#000000" stroke="#000000" stroke-linejoin="round" stroke-miterlimit="10"></path>
              </g>
              <g transform="translate(48,0)">
                <path
                    d="M8.84 0.5L11.42 5.72L17.18 6.56L13.01 10.63L14 16.37L8.84 13.66L3.69 16.37L4.67 10.63L0.5 6.56L6.26 5.72L8.84 0.5Z"
                    fill="#000000" stroke="#000000" stroke-linejoin="round" stroke-miterlimit="10"></path>
              </g>
              <g transform="translate(72,0)">
                <path
                    d="M8.84 0.5L11.42 5.72L17.18 6.56L13.01 10.63L14 16.37L8.84 13.66L3.69 16.37L4.67 10.63L0.5 6.56L6.26 5.72L8.84 0.5Z"
                    fill="#000000" stroke="#000000" stroke-linejoin="round" stroke-miterlimit="10"></path>
              </g>
              <g transform="translate(96,0)">
                <path
                    d="M8.84 0.5L11.42 5.72L17.18 6.56L13.01 10.63L14 16.37L8.84 13.66L3.69 16.37L4.67 10.63L0.5 6.56L6.26 5.72L8.84 0.5Z"
                    fill="#000000" stroke="#000000" stroke-linejoin="round" stroke-miterlimit="10"></path>
              </g>
            </svg>
            <h2 class="text-4xl mt-4">
              I truly cannot be happier.
            </h2>
            <p class="text-md mt-2">
              I truly cannot be happier. It feels like I'm going to the spa every time I go to the bathroom, so I'm
              thrilled... I had never witnessed a renovation ever, so I was very nervous. The second I met with John, my
              designer, I felt totally comfortable, I totally trusted him. He totally understood and respected what I
              wanted, and it was super smooth."
            </p>
          </div>
        </div>
        <div class="margin-bottom-2 image-only case-study-card">
          <img class="rounded-2xl" src="https://d10ysazx19ptke.cloudfront.net/nookassets/gallery/gallery-modal-6.webp"/>
        </div>
        <div class="margin-bottom-2 image-only case-study-card">
          <img class="rounded-2xl margin-bottom-2" src="https://d10ysazx19ptke.cloudfront.net/nookassets/gallery/gallery-modal-7.webp"/>
        </div>
        <div class="flex flex-col case-study-card">
          <div class="w-full bg-milk p-6 rounded-2xl text-left margin-bottom-2
                      lg:h-1/2 lg:flex lg:flex-col lg:justify-between">
            <div>
              <h2 class="text-2xl uppercase mb-2 font-semibold">
                READY TO RENOVATE?
              </h2>
              <p class="text-md mb-2">
                Block simplifies the chaos and costs of construction by bringing design, sourcing, and vetted
                contractors
                all under one roof.
              </p>
            </div>
            <a href="https://designer.nookrenovation.com/"
               target="_blank"
               class="w-full h-12 bg-primary text-black uppercase flex items-center justify-center text-xs
                       hover:bg-custom-hover text-white mt-10">
              <span>{{ $t("navbar.pricing") }}</span>
            </a>
          </div>
          <div class="w-full bg-milk p-6 rounded-2xl text-left
                      lg:h-1/2 lg:h-1/2 lg:flex lg:flex-col lg:justify-between">
            <div>
              <h2 class="text-2xl uppercase mb-2 font-semibold">
                View More Case Studies
              </h2>
              <p class="text-md mb-2">
                Block simplifies the chaos and costs of construction by bringing design, sourcing, and vetted
                contractors
                all under one roof.
              </p>
            </div>
            <a href="https://designer.nookrenovation.com/"
               target="_blank"
               class="w-full h-12 bg-primary text-black uppercase flex items-center justify-center text-xs
                       hover:bg-custom-hover text-white mt-10">
              <span class="uppercase">View Next Case Study</span>
            </a>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CaseStudyModal',
  methods: {
    close() {
      this.$emit('close');
    },
    triggerHorizontalScroll(event) {
      document.getElementById('case-study-modal').scrollLeft += event.deltaY;
    }
  },
};
</script>
<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.case-study-modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
}

.case-study-modal .modal-header button {
  background: rgb(107, 107, 107);
  fill: rgb(216, 216, 216);
}

section {
  padding: 2px;
}

.margin-bottom-2 {
  margin-bottom: 2px;
}

.stage-inner-card-padding {
  background: rgb(240, 238, 224);
  @apply p-6 mt-10 text-left;
}

.stage-inner-card-padding-second {
  background: rgb(240, 238, 224);
  @apply p-6 mt-1 text-left;
}

.stage-inner-card-no-padding {
  background: rgb(240, 238, 224);
  @apply mt-10 text-left;
}

.case-study-modal {
  width: 100%;
  height: 100%;
}

section {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

section::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 1023px) {
  .map-iframe {
    height: 600px;
  }
}

@media screen and (min-width: 1024px) {
  section {
    height: 100vh;
    overflow-y: hidden;
  }

  section .case-study-card {
    min-width: 500px;
    height: 100vh;
    margin-right: 2px;
    @apply lg:flex lg:flex-col lg:justify-between;
  }

  .image-only > img {
    height: 100%;
  }

  .stage-images {
    @apply flex flex-row flex-nowrap h-full w-full;
  }

  .stage-images > div {
    margin-right: 20px;
  }

  .stage-inner-card-no-padding, .stage-inner-card-padding {
    margin-top: 20px;
    flex-shrink: 0;
  }

  .stage-images img {
    max-width: 452px;
    height: 100%;
  }
}
</style>
