<template>
  <div class="text-left py-16 px-56">
    <p class="font-bold text-4xl">TERMS OF SERVICE</p>

    <p class="mt-8">
      This agreement <b>(“Agreement”</b> or <b>“Terms of Service”)</b> is a
      binding agreement between Untitled Labs, Inc. dba Nook Renovation and you,
      the customer. These Terms of Service shall govern any Accepted Proposal or
      other document entered into between you and Made (each, an
      <b>“Accepted Proposal”</b>). As used in these Terms of Service, “Made” or
      “we” or “us” refers to Untitled Labs, Inc. dba Made Renovation, and “you”
      or “your” or “Customer” refers to you, the customer. References to this
      “Agreement” include any Accepted Proposals issued hereunder.
    </p>

    <p class="my-12">Date of Last Revision: January 26, 2022</p>

    <p class="font-bold text-xl mt-8">1. SERVICES</p>

    <p class="font-bold mt-6 mb-2">1.1 Overview</p>

    <p>
      Made provides an online platform where homeowners can control and manage
      their bathroom renovation projects from design through completion. Made’s
      online platform allows customers to design their bathroom, select their
      materials, choose a contractor, and pay for everything all in one place
      (the <b>“Made Services”</b>).
    </p>

    <p class="font-bold mt-6 mb-2">1.2 Services</p>

    <p>
      The specific services included in your project will be set out in the
      Accepted Proposal agreed to between you and Made (<b>“Services”</b>).
    </p>

    <p class="font-bold mt-6 mb-2">1.3 Construction Services</p>

    <p>
      Made customers can select a contractor from Made’s extensive network at
      pre-negotiated prices. Made does not provide construction services, but
      Made will provide project support services as set out in the Accepted
      Proposal.
    </p>

    <p class="font-bold text-xl mt-8">2. PAYMENT TERMS</p>

    <p class="font-bold mt-6 mb-2">2.1 Payment</p>

    <p>
      Customer agrees to pay the fees set out in the Accepted Proposal for the
      Services and on the terms set out therein. The prices set out in the
      Accepted Proposal are valid for 90 days from the date of the Accepted
      Proposal. If the Construction Services do not commence within 90 days of
      the Accepted Proposal due to delays caused by Customer or for other
      reasons outside Made’s control, the fees may be adjusted to account for
      any increased costs (associated with supply chain issues or otherwise).
      Any such increase in fees will not exceed 10% of the initially quoted
      price.
    </p>

    <p class="font-bold mt-6 mb-2">2.2 Late Payments</p>

    <p>
      Customer agrees agree that all late payments will bear interest at the
      lesser of the rate of 1.5% per month or the highest rate permissible under
      California law, calculated daily and compounded monthly. Customer also
      agrees to reimburse Made for all costs incurred in collecting any late
      payments, including, without limitation, attorneys' fees.
    </p>

    <p class="font-bold text-xl mt-8">3. TERM, TERMINATION, &#38; SUSPENSION OF SERVICES</p>

    <p class="font-bold mt-6 mb-2">3.1 Term</p>

    <p>
      The term of the Agreement shall commence on the effective date specified
      in the Accepted Proposal and shall continue until the Services under all
      Accepted Proposals have been completed or terminated in accordance with
      the terms set out herein.
    </p>

    <p class="font-bold mt-6 mb-2">3.2 Termination by Customer</p>

    <p>
      Customer may terminate the Agreement or any Accepted Proposal by providing
      5 days’ written notice to Made. In such an event, Customer shall
      immediately pay to Made all outstanding balances for Services performed
      prior to the date of termination, as well as any costs incurred by Made in
      connection with the termination, including demobilization and any
      termination charges by vendors and subcontractors, plus 20%.
    </p>

    <p class="font-bold mt-6 mb-2">3.3 Termination by Made</p>

    <p>
      Made may terminate this Agreement at any time, upon written notice to
      Customer, provided that Made will refund to Customer any prepaid and
      unearned Fees for Services that have not been performed prior to the date
      of termination, less any amounts owed by Customer to Made upon
      termination, including fees for Services performed prior to the date of
      termination.
    </p>

    <p class="font-bold mt-6 mb-2">3.4 Termination for Breach</p>

    <p>
      Either Party may terminate this Agreement or any Accepted Proposal
      hereunder effective upon written notice to the other Party, if the
      defaulting Party materially breaches this Agreement, and such breach is
      incapable of cure, or with respect to a material breach capable of cure,
      the defaulting Party does not cure such breach within 5 days after receipt
      of written notice of such breach. Notwithstanding the foregoing, Made may
      terminate this Agreement or any Accepted Proposal hereunder immediately
      upon notice to Customer in the event of a Customer Default. In such an
      event, you shall immediately pay to Made all outstanding balances for
      Services performed prior to the date of termination, as well as any costs
      incurred by Made in connection with the termination, including
      demobilization and any termination charges by vendors and subcontractors,
      plus 20%.
    </p>

    <p class="font-bold mt-6 mb-2">3.5 Suspension of Services</p>

    <p>
      Customer agrees that Made may suspend the performance of Services due to a
      Customer Default upon written notice of the Customer Default to Customer.
      Made may continue to suspend the Services until the Customer Default has
      been cured. Customer further understands, acknowledges, and agrees that
      any suspension of Services in accordance with the terms of this Agreement
      will automatically extend any estimated timelines by a period of time
      equal to the Suspension Period.
    </p>

    <p class="font-bold text-xl mt-8">4. CHANGES TO SERVICES</p>

    <p>
      Any changes to the scope of the Made Services shall be agreed between
      Customer and Made in a Change Order signed by both parties. Any changes to
      the Services prior to commencement of the Construction Services shall be
      agreed between Made and Customer through a written Change Order. Unless
      otherwise agreed between the parties, any changes to the Construction
      Services following commencement of the Construction Services shall be
      agreed between Customer and the Contractor directly.
    </p>

    <p class="font-bold text-xl mt-8">5. RETURN POLICY</p>

    <p>
      No material returns will be accepted by Made unless an item arrives
      damaged or an incorrect item is delivered. If an item arrives damaged or
      is incorrect, Made will replace the item free of charge. Made will also
      allow changes to be made to materials ordered from Made's standard catalog
      within a two-week window starting from the date of the materials invoice.
      This two-week window does not apply to custom materials ordered outside of
      Made’s standard catalog. Customer understands that once Made orders
      materials, the materials order may not be cancelled and Made will be
      obligated to pay for the materials notwithstanding that Customer has
      decided to cancel the order. Made shall make reasonable efforts to cancel
      the materials order and minimize the cancellation costs. However, Made
      shall be paid all monies for all material costs that a vendor refuses to
      cancel, and Made shall deliver those materials to Customer or dispose of
      them as directed by Customer. Excess material left over after
      construction, including tile overages, are the property of the Customer
      and are not eligible for refunds or returns.
    </p>

    <p class="font-bold text-xl mt-8">6. CUSTOMER OBLIGATIONS</p>

    <p class="font-bold mt-6 mb-2">6.1 General Obligations</p>

    <p>
      Customer agrees to: (i) respond promptly to any reasonable requests from
      Made for information or documents required by Made to provide the
      Services; (ii) cooperate with Made in its performance of the Services and
      provide access to Customer's premises as required to enable Made to scope,
      price, or otherwise provide the Services; and (iii) comply with all
      Customer obligations under the applicable Accepted Proposal. Customer
      shall be responsible for all Materials once delivered by Made to Customer,
      and Made assumes no liability for Materials after they have been delivered
      to Customer.
    </p>

    <p class="font-bold mt-6 mb-2">6.2 Customer Delays</p>

    <p>
      Customer understands that Made’s performance is dependent in part on
      Customer's actions and timely provision of feedback, approvals, and other
      information requested by Made. Further, Customer understands that Made is
      relying on the accuracy and completeness of all information provided by
      Customer in performing the Services, and that the Deliverable(s) are
      intended for the specified interior and/or exterior areas of the
      Customer's property as represented by Customer (as specified in the
      relevant Accepted Proposal ). Accordingly, Customer will provide Made with
      the necessary items, information and assistance specified in the relevant
      Accepted Proposal (or otherwise reasonably requested by Made) in a timely
      manner. Customer shall be solely responsible for any delays, damages,
      losses, liabilities, delays, or expenses arising from or relating to
      Customer's failure to provide Made all necessary information and
      assistance in a timely manner, Customer’s breach of this Contract, or
      Customer’s provision of inaccurate information or other delay.
    </p>

    <p class="font-bold mt-6 mb-2">6.3 Review &#38; Acceptance</p>

    <p>
      Customer will review each Deliverable immediately upon delivery by Made.
      If the Deliverable does not conform with the specifications set out in the
      Accepted Proposal, Customer must provide Made with written notice of such
      nonconformity within three (3) days of delivery (the
      <b>“Acceptance Period”</b>). Upon receipt of such notice, Made will review
      the Deliverables and will use reasonable efforts to correct any material
      nonconformities specified in the notice. The provisions above shall be
      reapplied until the Deliverable is accepted, provided that, after the
      second (or any subsequent rejection notice) either party may terminate
      this Contract upon ten (10) days’ notice. A Deliverable may not be
      rejected for a previously uncited failure that was reasonably discoverable
      in a previously submitted version of the Deliverable.
    </p>

    <p class="font-bold text-xl mt-8">7. DISCLAIMER</p>

    <p>
      Customer understands and agrees that Made shall not be providing any
      Construction Services in connection with this Agreement or the Project.
      Customer may choose to select a Contractor through the Made Platform at
      Made’s pre-negotiated rates; however, Made will not be involved in the
      management or performance of the Construction Services and any agreement
      relating to the Construction Services will be between the Customer and the
      Contractor directly.
      <b
        >CUSTOMER HEREBY RELEASES MADE FROM ANY CLAIMS, DEMANDS, OR LIABILITIES
        ARISING FROM OR RELATING TO THE CONSTRUCTION AGREEMENT BETWEEN CUSTOMER
        AND THE CONTRACTOR OR OTHERWISE RELATING TO THE CONSTRUCTION
        SERVICES.</b
      >
      Customer acknowledges and agrees that any timelines provided in the
      Accepted Proposal are estimates only and are subject to the Construction
      Agreement entered into between Customer and the Contractor. Customer
      further acknowledges and agrees that the scope of work set out in the
      Accepted Proposal is based on the information known at the time of
      entering into this Agreement. Customer acknowledges, understands, and
      agrees that conditions or circumstances may exist that are unknown to Made
      at the time of entering into this Agreement (including, but not limited
      to, the existence of mold, asbestos, latent site conditions, concealed
      plumbing, dry rot, knob and tube wiring replacement, changes to or
      relocation of pipes due to conflict with recessed fixtures, plumbing
      upgrades to meet code, large obstructions (such as a concrete block or
      otherwise) that need to be removed to achieve a new layout, walls, floors,
      or ceilings out of level, rerouting plumbing pipes/vents for layout
      changes, or furring out of walls to accommodate plumbing lines or recessed
      accessories). Customer acknowledges and agrees that, to the extent any
      unforeseen conditions are discovered at the Project Site that impact the
      Project cost, expected timeline, or any other aspect of the Project,
      Customer will work together with the Contractor to revise the scope of
      work in the Accepted Proposal as appropriate through a Change Order.
    </p>

    <p class="font-bold text-xl mt-8">8. LIMITATION OF LIABILITY</p>

    <p class="font-bold mt-6 mb-2">8.1 Waiver of Consequential Damages</p>

    <p>
      IN NO EVENT SHALL EITHER PARTY BE LIABLE TO THE OTHER PARTY OR TO ANY
      THIRD PARTY FOR ANY CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY,
      SPECIAL, OR PUNITIVE DAMAGES WHETHER ARISING OUT OF BREACH OF CONTRACT,
      TORT (INCLUDING NEGLIGENCE), OR OTHERWISE, REGARDLESS OF WHETHER SUCH
      DAMAGE WAS FORESEEABLE AND WHETHER OR NOT SUCH PARTY HAS BEEN ADVISED OF
      THE POSSIBILITY OF SUCH DAMAGES, AND NOTWITHSTANDING THE FAILURE OF ANY
      AGREED OR OTHER REMEDY OF ITS ESSENTIAL PURPOSE.
    </p>

    <p class="font-bold mt-6 mb-2">8.2 Liability Cap</p>

    <p>
      IN NO EVENT SHALL EITHER PARTY’S AGGREGATE LIABILITY ARISING OUT OF OR
      RELATED TO THIS AGREEMENT, WHETHER ARISING OUT OF OR RELATED TO BREACH OF
      CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE, EXCEED THE AGGREGATE
      AMOUNTS PAID OR PAYABLE BY CUSTOMER TO MADE PURSUANT TO THE APPLICABLE
      ACCEPTED PROPOSAL IN THE 6 MONTH PERIOD PRECEDING THE EVENT GIVING RISE TO
      THE CLAIM.
    </p>

    <p class="font-bold mt-6 mb-2">8.3 No Liability for Third Party Expenses</p>

    <p>
      CUSTOMER ACKNOWLEDGES AND AGREES THAT MADE SHALL NOT BE LIABLE FOR ANY
      THIRD-PARTY FEES OR EXPENSES THAT CUSTOMER MAY INCUR AS A RESULT OF THE
      SERVICES, INCLUDING, BUT NOT LIMITED TO, LODGING, HOUSING, OR
      ACCOMMODATION COSTS, PERMITTING FEES, OR ANY OTHER THIRD-PARTY FEES AND
      EXPENSES, EXCEPT AS OTHERWISE EXPRESSLY PROVIDED IN AN ACCEPTED PROPOSAL.
      CUSTOMER AGREES THAT ANY TIMELINES PROVIDED BY MADE ARE ESTIMATES ONLY AND
      MAY BE ADJUSTED BASED ON PROJECT REQUIREMENTS, AS DETERMINED BY MADE IN
      ITS REASONABLE DISCRETION. CUSTOMER ACKNOWLEDGES AND AGREES THAT MADE
      SHALL NOT LIABLE FOR ANY LOSS OR DAMAGE RESULTING FROM A DELAY IN DELIVERY
      OF THE SERVICES OR ANY DELIVERABLE UNDER AN ACCEPTED PROPOSAL.
    </p>

    <p class="font-bold text-xl mt-8">9. INTELLECTUAL PROPERTY</p>

    <p>
      All intellectual property rights created in connection with the Services
      shall be owned by Made. The Made Renovation name and logos are trademarks
      and service marks of Made (collectively the <b>“Made Trademarks”</b>).
      Other company, product, and service names and logos used and displayed via
      the Service may be trademarks or service marks of their respective owners
      who may or may not endorse or be affiliated with or connected to Made.
      Nothing in this Agreement should be construed as granting, by implication,
      estoppel, or otherwise, any license or right to use any of Made Trademarks
      displayed on the Service, without our prior written permission in each
      instance. All goodwill generated from the use of Made Trademarks will
      inure to Made’s exclusive benefit.
    </p>

    <p class="font-bold text-xl mt-8">10. CONFIDENTIALITY</p>

    <p>
      From time to time during the Term of this Agreement, either Party may
      disclose or make available to the other Party Confidential Information of
      the Disclosing Party. The Receiving Party shall: (x) protect and safeguard
      the confidentiality of the Disclosing Party's Confidential Information
      with at least the same degree of care as the Receiving Party would use to
      protect its own Confidential Information, but in no event with less than a
      commercially reasonable degree of care; (y) not use the Disclosing Party's
      Confidential Information, or permit it to be accessed or used, for any
      purpose other than to exercise its rights or perform its obligations under
      this Agreement; and (z) not disclose any such Confidential Information to
      any person or entity, except to the Receiving Party's Group who need to
      know the Confidential Information to assist the Receiving Party, or act on
      its behalf, to exercise its rights or perform its obligations under this
      Agreement. If the Receiving Party is required by applicable law or legal
      process to disclose any Confidential Information, it shall, prior to
      making such disclosure, use commercially reasonable efforts to notify
      Disclosing Party of such requirements to afford Disclosing Party the
      opportunity to seek, at Disclosing Party's sole cost and expense, a
      protective order or other remedy. For purposes of this Section 14 only,
      Receiving Party's Group shall mean the Receiving Party's affiliates and
      its or their employees, officers, directors, shareholders, partners,
      members, managers, agents, independent contractors, service providers,
      sublicensees, subcontractors, attorneys, accountants, and financial
      advisors.
    </p>

    <p class="font-bold text-xl mt-8">11. USE OF IMAGES</p>

    <p>
      Customer hereby grants Made the right to photograph the Project, including
      before, during, and after completion of the Services, for Made’s portfolio
      and/or marketing purposes, including online use. Upon request from Made,
      Customer agrees to accommodate Made’s reasonable requests for photography.
      Made will not publish, without Customer’s express permission, Customer’s
      full name, address, or any other sensitive information relating to
      Customer. The photography session costs will be borne and paid by Made. In
      line with standard industry practice, Made may include Customer’s first
      name or initials as part of a list of current or past clients for
      marketing purposes.
    </p>

    <p class="font-bold text-xl mt-8">12. BINDING ARBITRATION AGREEMENT</p>

    <p>
      Any dispute, claim or controversy arising out of or relating to this
      Agreement or the breach, termination, enforcement, interpretation or
      validity thereof, including the determination of the scope or
      applicability of this agreement to arbitrate, shall be determined by
      arbitration in the city in which the Project is located, before one
      arbitrator. The arbitration shall be administered by JAMS pursuant to its
      Engineering and Construction Arbitration Rules &#38; Procedures for
      Expedited Arbitration. Judgment on the Award may be entered in any court
      having jurisdiction. This clause shall not preclude parties from seeking
      provisional remedies in aid of arbitration from a court of appropriate
      jurisdiction.
    </p>

    <p class="font-bold text-xl mt-8">13. FORCE MAJEURE</p>

    <p>
      If Made is rendered wholly or partly unable to carry out its obligations
      under this Agreement due to a Force Majeure Event, Made’s obligations will
      be suspended during the period of the Force Majeure Event, and Made shall
      use its commercially reasonable efforts to remedy the cause of such Force
      Majeure Event in a reasonably timely manner. If a Force Majeure Event
      extends for a period of thirty (30) days or more, then either Party shall
      have the right to terminate this Agreement upon written notice to the
      other Party.
    </p>

    <p class="font-bold text-xl mt-8">14. INSURANCE; WAIVER OF SUBROGATION</p>

    <p>
      Customer and Made waive all rights against each other and against all
      other subcontractors for loss or damage to the extent reimbursed by any
      property or equipment insurance applicable to the work, except such rights
      as they may have to the proceeds of such insurance. If any applicable
      policies of insurance require an endorsement or consent of the insurance
      company to provide for continued coverage where there is a waiver of
      subrogation, the Customers of such policies will cause them to be so
      endorsed or obtain such consent.
    </p>

    <p class="font-bold text-xl mt-8">15. GENERAL</p>

    <p class="font-bold mt-6 mb-2">15.1 Independent Contractors</p>

    <p>
      The parties are independent contractors, and nothing contained in this
      Agreement shall be construed as creating any agency, partnership, joint
      venture or other form of joint enterprise, employment, or fiduciary
      relationship between them. Neither party shall be authorized to contract
      for or bind the other party in any manner whatsoever.
    </p>

    <p class="font-bold mt-6 mb-2">15.2 Notice</p>

    <p>
      Except as otherwise provided herein, all notices, requests, consents,
      claims, demands, waivers, and other communications hereunder (each, a
      <b>“Notice”</b>) shall be in writing and addressed to the parties at the
      addresses set forth on the first page of the Signed Contract (or to such
      other address that may be designated by the receiving party from time to
      time in accordance with this Section). All Notices shall be delivered by
      personal delivery, nationally recognized overnight courier (with all fees
      pre-paid), facsimile or email (with confirmation of transmission) or
      certified or registered mail (in each case, return receipt requested,
      postage pre-paid). Except as otherwise provided in this Agreement, a
      Notice is effective only (a) upon receipt by the receiving party; and (b)
      if the party giving the Notice has complied with the requirements of this
      Section.
    </p>

    <p class="font-bold mt-6 mb-2">15.3 Entire Agreement; All Other Agreements Superseded</p>

    <p>
      The Parties agree and acknowledge that, during the proposal process, Made
      and Customer may have discussed different plans, pricing options,
      services, deliverables, timelines, and other details relating to the
      proposed project and Services. Notwithstanding the foregoing, Customer and
      Made hereby expressly acknowledge and agree that any prior proposals,
      discussions, agreements, or understandings are superseded by this
      Agreement and the Accepted Proposal, and that this Agreement, together
      with any Accepted Proposals issued hereunder, is the entire agreement and
      understanding between the Parties. The Parties further acknowledge and
      agree that, during the course of providing the Services, Made and Customer
      may discuss different plans, pricing options, services, deliverables,
      timelines, and other details relating to the proposed project and
      Services. Notwithstanding the foregoing, Customer and Made hereby
      expressly acknowledge and agree that any discussions, agreements, or
      alleged understandings between the Parties are not valid unless agreed in
      a Change Order signed by both Parties. This Agreement, together with any
      Accepted Proposals or Change Orders validly issued hereunder (or any other
      documents expressly incorporated herein by reference), constitutes the
      sole and entire agreement of the parties to this Agreement with respect to
      the subject matter contained herein, and supersedes all prior and
      contemporaneous understandings and agreements, both written and oral, with
      respect to such subject matter.
    </p>

    <p class="font-bold mt-6 mb-2">15.4 Order of Precedence</p>

    <p>
      In the event of any conflict between the terms and provisions of these
      Terms of Services and those of any Accepted Proposal, Change Order, or
      other document issued hereunder, then these Terms of Service shall control
      unless expressly set forth otherwise in a document signed by both parties.
    </p>

    <p class="font-bold mt-6 mb-2">15.5 Assignment</p>

    <p>
      Customer may not assign, transfer, or delegate any or all of its rights or
      obligations under this Agreement, without Made’s prior written consent. No
      assignment shall relieve the assigning party of any of its obligations
      hereunder. Any attempted assignment, transfer or other conveyance by
      Customer in violation of the foregoing shall be null and void. This
      Agreement shall be binding upon and shall inure to the benefit of the
      parties hereto and their respective successors and permitted assigns.
    </p>

    <p class="font-bold mt-6 mb-2">15.6 No Third-Party Beneficiaries</p>

    <p>
      This Agreement is for the sole benefit of the parties hereto and their
      respective successors and permitted assigns and nothing herein, express or
      implied, is intended to or shall confer upon any other Person any legal or
      equitable right, benefit, or remedy of any nature whatsoever, under or by
      reason of this Agreement.
    </p>

    <p class="font-bold mt-6 mb-2">15.7 Amendment and Modification; Waiver</p>

    <p>
      This Agreement may be amended, modified, or supplemented only by an
      agreement in writing signed by each party hereto. No failure to exercise
      any rights, remedy, power or privilege (“Right(s)”) arising from this
      Agreement shall operate or be construed as a waiver thereof. No single or
      partial exercise of any Right hereunder precludes any other or further
      exercise thereof or the exercise of any other Right.
    </p>

    <p class="font-bold mt-6 mb-2">15.8 Severability</p>

    <p>
      No invalidity, illegality, or unenforceability of any provision herein in
      any jurisdiction, shall affect any other term or provision of this
      Agreement or invalidate or render such provision unenforceable in any
      other jurisdiction. If any provision is determined to be invalid, illegal,
      or unenforceable, the parties hereto shall negotiate in good faith to
      modify this Agreement so as to effect the original intent of the parties
      as closely as possible.
    </p>

    <p class="font-bold mt-6 mb-2">15.9 Governing Law</p>

    <p>
      This Agreement shall be governed by and construed in accordance with the
      internal laws of the state in which the Project is located without giving
      effect to any choice or conflict of law provision or rule that would cause
      the application of laws of any other jurisdiction. Each party irrevocably
      waives any right to trial by jury. Service of process, summons, notice or
      other document by mail to such party’s address set forth herein shall be
      effective service of process for any suit, action, or other proceeding
      brought in any such court.
    </p>

    <p class="font-bold mt-6 mb-2">15.10 Equitable Relief; Cumulative Remedies</p>

    <p>
      Each party acknowledges that a breach of Section 9 (Intellectual Property
      Rights; Ownership) or Section 10 (Confidentiality) may cause the
      non-breaching party irreparable damages, for which an award of damages
      would not be adequate compensation. In the event of such breach or
      threatened breach, the non-breaching party will be entitled to seek
      equitable relief. Except as expressly set forth in this Agreement, the
      right and remedies under this Agreement are cumulative and in addition to
      any other rights or remedies available at law or in equity or otherwise.
    </p>

    <p class="font-bold mt-6 mb-2">15.11 Counterparts</p>

    <p>
      This Agreement may be executed in counterparts, each of which shall be
      deemed an original, but all of which together shall be deemed to be one
      and the same agreement. A signed copy of this Agreement delivered by
      facsimile, e-mail or other means of electronic transmission shall be
      deemed to have the same legal effect as delivery of an original signed
      copy of this Agreement.
    </p>

    <p class="font-bold mt-6 mb-2">15.12 Survival</p>

    <p>
      Any terms under this Agreement that, by their nature, would be expected to
      survive termination or expiration of this Agreement, shall survive such
      termination or expiration.
    </p>

    <p class="font-bold text-xl mt-8">16. DEFINITIONS</p>

    <p>
      The following terms, when capitalized in this Agreement (including in a
      Signed Contract), shall have the meanings set out below:
    </p>

    <p class="font-bold mt-6 mb-2">16.1 Accepted Proposal</p>

    <p>
      A written proposal from Made to Customer setting out the Services to be
      provided to Customer, which is accepted by Customer. Customer hereby
      agrees that all Accepted Proposals constitute a legally binding contract
      between Made and Customer, and all Accepted Proposals shall be governed by
      these Terms of Service.
    </p>

    <p class="font-bold mt-6 mb-2">16.2 Change Order</p>

    <p>
      An amendment to an Accepted Proposal that is signed by both Parties and
      expressly references the relevant Accepted Proposal.
    </p>

    <p class="font-bold mt-6 mb-2">16.3 Confidential Information</p>

    <p>
      Confidential Information means (i) the terms of the Accepted Proposal;
      (ii) any non-public, proprietary, and confidential information of the
      Disclosing Party, whether disclosed in writing or orally, that a
      reasonable person would expect to be treated as confidential; (iii) any
      information, documents or communications relating to a dispute between the
      Parties. Notwithstanding the foregoing, Confidential Information does not
      include any information that: (a) is or becomes generally available to the
      public other than as a result of Receiving Party's breach of Section 9 of
      these Terms of Service.; (b) is or becomes available to the Receiving
      Party on a non-confidential basis from a third-party source, provided that
      such third party is not and was not prohibited from disclosing such
      Confidential Information; (c) was in Receiving Party's possession prior to
      Disclosing Party's disclosure hereunder; or (d) was or is independently
      developed by Receiving Party without using any Confidential Information.
    </p>

    <p class="font-bold mt-6 mb-2">16.4 Construction Agreement</p>

    <p>
      An agreement for Construction Services between Customer and the
      Contractor.
    </p>

    <p class="font-bold mt-6 mb-2">16.5 Construction Services</p>

    <p>
      The construction services provided by the Contractor under a separate
      agreement between the Customer and the Contractor.
    </p>

    <p class="font-bold mt-6 mb-2">16.6 Contractor</p>

    <p>
      The contractor selected by Customer to perform the Construction Services.
    </p>

    <p class="font-bold mt-6 mb-2">16.7 Customer Default</p>

    <p>
      Customer’s failure to comply with any of its obligations under this
      Agreement or the applicable Accepted Proposal , including, but not limited
      to, Customer’s obligations under Sections 1.1 (Fees), Section 4 (Changes
      to Services), Section 4 (Unforeseen Conditions), Section 5 (Customer
      Obligations), any failure by Customer to make design decisions in a
      reasonable time period (or as otherwise specified in the Contract), or if
      Customer shall be adjudged bankrupt; become insolvent; file or be subject
      to any arrangement, reorganization or other bankruptcy proceedings; be
      subject to any receivership proceedings; make an assignment for the
      benefit of creditors; become deceased or is determined to be mentally
      incompetent (if an individual); or dissolve or terminate its existence (if
      a business entity).
    </p>

    <p class="font-bold mt-6 mb-2">16.8 Deliverable</p>
    <p>Any deliverable identified in an Accepted Proposal, including any design
    deliverables, drawings, renderings, mock-ups or materials ordered by Customer.</p>

    <p class="font-bold mt-6 mb-2">16.9 Design Services</p>
    <p>The design services described in the Agreement.</p>

    <p class="font-bold mt-6 mb-2">16.10 Designated Contacts</p>
    <p>
      The Customer Contact or Made Contact, as applicable, as identified in the
      applicable Accepted Proposal.
    </p>

    <p class="font-bold mt-6 mb-2">16.11 Disclosing Party</p>
    <p>
      A Party disclosing Confidential Information to the other Party under
      Section 9 of these Terms of Service.
    </p>

    <p class="font-bold mt-6 mb-2">16.12 Force Majeure Event</p>

    <p>
      Any event or circumstance that is outside the reasonable control of a
      Party, such acts of God, epidemics, pandemics, landslides, mudslides,
      explosions, fires, storms, hurricanes, tornados, high-water washouts,
      lightning, earthquakes, severe or unusual weather conditions, floods or
      similar cataclysmic event, infectious diseases of a contagious nature,
      acts (or failure to act) of governmental authorities, acts of public
      enemy, wars, armed conflict, blockades, civil disturbance or
      insurrections, riots, acts or threats of terrorism, sabotage, lockouts,
      strikes or other labor issues/difficulties or industrial disturbances
      (suffered by a Party), governmental actions such as the enactment of
      statutes, laws or regulations frustrating the purpose of this Agreement or
      the Project, failure of Made’s Suppliers to comply with their obligations
      to Made, changes in law, failure to obtain or changes in authorizations or
      permits that are not the direct fault of Made, interruptions in supply
      chains, emergencies at the Project, and any other cause or causes, whether
      of the kind herein enumerated or otherwise, not within the reasonable
      control of such Party.
    </p>

    <p class="font-bold mt-6 mb-2">16.13 Intellectual Property Rights</p>

    <p>
      All intellectual property rights, including copyrights, patents, patent
      disclosures, and inventions (whether patentable or not), trademarks,
      service marks, trade secrets, know-how, and other confidential
      information, trade dress, trade names, logos, corporate names, and domain
      names, together with all of the goodwill associated therewith, derivative
      works, and all other rights in and to all documents, work product, and
      other materials that are delivered to Customer under this Agreement or
      prepared by or on behalf of Made in the course of performing the Services.
    </p>

    <p class="font-bold mt-6 mb-2">16.14 Made Services</p>
    <p>
      The services provided through the Made Platform, including project design
      services, materials selection services, materials procurement, contractor
      selection services, and payment processing services.
    </p>

    <p class="font-bold mt-6 mb-2">16.15 Parties</p>
    <p>Made and/or Customer, as the context requires</p>

    <p class="font-bold mt-6 mb-2">16.16 Project</p>
    <p>The bathroom remodeling project described in an Accepted Proposal.</p>

    <p class="font-bold mt-6 mb-2">16.17 Project Site</p>
    <p>The location of the Project</p>

    <p class="font-bold mt-6 mb-2">16.18 Receiving Party</p>
    <p>
      The Party receiving Confidential Information from the other Party under
      Section 9 of these Terms of Service.
    </p>

    <p class="font-bold mt-6 mb-2">16.19 Services</p>
    <p>The services identified in the applicable Order From.</p>

    <p class="font-bold mt-6 mb-2">16.20 Suspension Period</p>
    <p>
      Any period of time during which the Services are suspended in accordance
      with the terms of this Agreement.
    </p>

    <p class="font-bold mt-6 mb-2">16.21 Terms of Services</p>
    <p>These Terms of Service.</p>
  </div>
</template>
<script>
export default {
  name: "TermOfService",
};
</script>
