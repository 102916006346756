<template>
  <div v-if="itemName === 'language'" class="dropdown relative">
    <span class="flex justify-between items-center cursor-pointer">
      <svg
          class="w-4 h-4 inline mr-2"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
      >
        <path
            fill-rule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clip-rule="evenodd"
        ></path>
      </svg>

      <img class="w-6 h-4 mr-2" :src="'https://d10ysazx19ptke.cloudfront.net/nookassets/' + this.currentLocale + '.png'">
      <span class="uppercase">{{this.currentLocale}}</span>
    </span>

    <ul class="dropdown-menu w-max hidden text-left absolute bg-gray">
      <li class="p-2 cursor-pointer hover:bg-primary" @click="$emit('close-navbar'); setLocale('vi')">
        <img class="mr-2 w-6 h-4 inline" src="https://d10ysazx19ptke.cloudfront.net/nookassets/vi.png"/>
        <span>Vietnamese</span>
      </li>

      <li class="p-2 cursor-pointer hover:bg-primary" @click="$emit('close-navbar'); setLocale('en')">
        <img class="mr-2 w-6 h-4 inline" src="https://d10ysazx19ptke.cloudfront.net/nookassets/en.png"/>
        <span>English</span>
      </li>
    </ul>
  </div>
  <div v-else>
    <router-link :to='{name: itemName, params: { lang: this.$i18n.locale }}' @click="$emit('close-navbar')">
      {{ $t("navbar." + itemName) }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: "NavItem",
  props: {
    itemName: String,
  },
  data() {
    return {
      currentLocale: 'en'
    }
  },
  methods: {
    setLocale(locale) {
      if (this.currentLocale !== locale) {
        this.currentLocale = locale;
        this.$i18n.locale = locale;

        document.title = this.$t(this.$route.meta.title);
      }
    }
  },
  mounted() {
    this.currentLocale = this.$i18n.locale;
  },
  watch: {
    '$i18n.locale': function(newVal) {
      if (newVal) {
        this.currentLocale = newVal;
      }
    }
  }
};
</script>

<style scoped>
.dropdown-menu {
  z-index: 1000;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

img {
  max-width: 100%;
}
</style>
