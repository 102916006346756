export default {
  language: {
    vi: "Tiếng Việt",
    en: "Tiếng Anh"
  },
  meta: {
    des: 'Nook is an all-in-one home platform to remodel and redesign homes'
  },
  page_title: {
    home: 'Trang chủ',
    bathroom: 'Mẫu thiết kế',
    gallery: 'Bộ sưu tập',
    faq: 'Faq',
    financing: 'Tài chính',
    blog: 'Blog',
    abou_tus: 'Về chúng tôi',
    agents: 'Đại lý',
    careers: 'Nghề nghiệp',
    estimate: 'Định giá',
    packages: 'Mẫu thiết kế',
    reviews: 'Đánh giá',
    privacy_policy: 'Chính sách bảo mật',
    term_of_service: 'Điều khoản dịch vụ',
    contractors: 'Nhà thầu',
    how_it_works: 'Quy trình cải tạo'
  },
  navbar: {
    home: 'Trang chủ',
    packages: 'CÁC MẪU THIẾT KẾ',
    contractors: 'NHÀ THẦU CỦA NOOK',
    gallery: 'Bộ sưu tập',
    faq: 'faq',
    financing: 'Tài chính',
    blog: 'blog',
    pricing: 'Bảng giá',
    howitworks: 'QUY TRÌNH CẢI TẠO',
    reviews: 'Nhận xét',
    agents: 'Agents',
    language: 'Language',
  },
  homepage: {
    slogan: 'CẢI TẠO NHÀ TẮM DỄ DÀNG HƠN',
    subslogan: 'Nook cung cấp gói cải tạo bao gồm thiết kế, vật liệu và thi công, tất cả tinh gọn trên một nền tảng',
    allinclusive: 'Ước tính cải tạo phòng tắm ngay lập tức dành riêng cho bạn',
    allinclusivetext: {
      construction: 'Thi công',
      materials: 'Vật Liệu',
      design: 'Thiết kế',
      support: 'Hỗ trợ',
      pickingbudget: 'Bắt đầu chọn mức ngân sách',
      affordable: 'Phải chăng',
      standard: 'Tiêu chuẩn',
      luxury: 'Cao cấp',
    },
    sponsors: {
      cafebiz: 'Nook đảm nhận vai trò xử lý toàn bộ quy trình.',
      cafef: 'Điểm độc đáo và khác biệt của Nook là công cụ thực tế ảo thể hiện không gian phòng tắm 3D.',
      afamily: 'Chỉ với một vài cái chạm, khách hàng có thể thoải mái lựa chọn và tạo ra phòng tắm như ý muốn.',
      vdas: 'Nook là công ty khởi nghiệp về công nghệ và thi công, tập trung vào lĩnh vực cải tạo nhà ở tại Việt Nam.',
      beststartup: 'Nền tảng gói gọn tất cả các giai đoạn thiết kế và cải tạo nhà cửa ở khu Đông Nam Á.'
    },
    statements: {
      title: 'Nook cung cấp',
      full_service: {
        title: "Dịch vụ trọn gói",
        stt1: "Chọn phòng tắm yêu thích của bạn và chúng tôi sẽ cải tạo từ A đến Z. Đội ngũ chúng tôi sẽ giúp bạn loại trừ mọi rủi ro trong quá trình cải tạo.",
        stt2: "Mức giá dự toán bạn chọn sẽ bao gồm toàn bộ chi phí cải tạo: thiết kế, nhân công và vật tư, thiết bị và nội thất.",
        stt3: "Các mẫu thiết kế được xây dựng bằng vật liệu cao cấp. Bao gồm tất cả đồ đạc, vật liệu được hoàn thiện và vận chuyển đến bạn với giá cả phải chăng vì Nook được giảm giá khi mua số lượng lớn."
      },
      beautiful_design: {
        title: "Thiết kế tinh tế",
        stt1: 'Đội ngũ thiết kế nội thất và kiến trúc sư hàng đầu của chúng tôi sẽ giúp đưa ra giải pháp tốt nhất cho phòng tắm của bạn.',
        stt2: 'Bạn có thể tùy chọn hoặc chỉnh sửa các mẫu thiết kế, hoặc đội ngũ chuyên viên thiết kế của Nook sẽ giúp bạn tạo nên những thiết kế mơ ước của riêng mình.'
      },
      quality_materials: {
        title: "Vật liệu chất lượng",
        stt1: 'Tất cả vật liệu mà chúng tôi cung cấp là sản phẩm chất lượng cao đến từ các thương hiệu như TOTO, INAX, American Standard and Kohler.',
        stt2: 'Vì thiết bị là một phần quan trọng trong phòng tắm, nên tính năng và thẩm mỹ là hai yếu tố bạn cần chú trọng và xem xét kỹ lưỡng. Chúng tôi khuyên bạn nên chọn những thiết bị phù hợp với sở thích, phong cách sống và đặc biệt là phù hợp với ngân sách của bạn.'
      },
      professional_team: {
        title: "Đội ngũ chuyên nghiệp",
        stt1: 'Khi cải tạo phòng tắm cùng Nook, bạn sẽ được hợp tác với nhà thầu chất lượng. Chúng tôi luôn làm việc với những đơn vị có nhiều năm kinh nghiệm, đảm bảo chất lượng cao và uy tín trong lĩnh vực này.',
        stt2: 'Chúng tôi xây dựng mối quan hệ bền chặt với nhà thầu của mình bằng cách cùng nhau làm nhiều dự án mỗi năm, vì thế độ tin cậy cao hơn rất nhiều so với việc chỉ hợp tác với một nhà thầu một lần duy nhất.'
      },
      price_transparency: {
        title: "Giá cả minh bạch",
        stt1: 'Bạn đang lo lắng về chi phí phát sinh? Chúng tôi đảm bảo giá cả luôn minh bạch để hạn chế các khoản chi phí phát sinh.',
        stt2: 'Đến với Nook, bạn có thể xem được báo giá chi tiết trước khi bắt đầu cải tạo để luôn chắc chắn rằng sẽ không phát sinh thêm gì cả.'
      }
    },
    complete_platform: {
      title: 'TẤT CẢ TRONG MỘT',
      description: 'Nook cung cấp cho bạn gói cải tạo toàn diện, bao gồm thiết kế, vật tư và thi công, tất cả trong một nền tảng đơn giản.',
      learn: 'Tìm hiểu thêm',
      plan: {
        title: 'NHẬN BÁO GIÁ CHI PHÍ',
        stt1: 'Tùy chỉnh, mix & match các sản phẩm, thiết bị bằng công cụ thiết kế ảo của chúng tôi để nhận báo giá tức thì.'
      },
      design: {
        title: 'TƯ VẤN MẪU THIẾT KẾ',
        stt1: 'Đội ngũ chuyên viên thiết kế của chúng tôi sẽ hỗ trợ và tư vấn để điều chỉnh mẫu thiết kế sao cho phù hợp với bố cục phòng tắm của bạn.'
      },
      build: {
        title: 'THI CÔNG ĐÚNG TIẾN ĐỘ',
        stt1: 'Các nhà thầu của Nook sẽ hoàn thành phòng tắm trong mơ của bạn với chất lượng cao và đúng thời hạn. Chúng tôi liên tục cập nhật cho bạn nắm rõ những giai đoạn quan trọng trong quá trình cải tạo.'
      }
    },
    renovate: {
      title: '0% lãi suất lên đến 12 tháng',
      explore: 'KHÁM PHÁ HỖ TRỢ TÀI CHÍNH'
    },
    paranoma: {
      title: "Khám phá thiết kế phòng tắm trong không gian 360˚",
      des: "See the high quality materials we work with and how it could look in your space.",
      photo1: {
        title: 'PENTHOUSE'
      },
      photo2: {
        title: 'ENCAUSTIC'
      },
      photo3: {
        title: 'HUNTINGTON'
      },
      photo4: {
        title: 'SCANDINAVIAN'
      },
      more: 'XEM THÊM'
    },
    ours: {
      title: 'SỰ ĐỔI MỚI CỦA CHÚNG TÔI',
      bathroom: {
        title: 'Phòng tắm',
        week: 'CẢI TẠO TRONG 4 TUẦN',
        des: 'Cải tạo phòng tắm của bạn với vật dụng và vật liệu cao cấp.',
        learn: 'TÌM HIỂU THÊM'
      },
      contractors: {
        title: 'Nhà thầu',
        week: 'CẢI TẠO TRONG 6 TUẦN',
        des: 'Cải tạo nhà bếp của bạn với vật liệu chất lượng, bền lâu.',
        learn: 'TÌM HIỂU THÊM'
      }
    },
    why: {
      title: 'Tại sao là Nook?',
      typical_ex: {
        stt1: 'Đặc trưng',
        stt2: 'Kinh nghiệm'
      },
      with_nook: {
        stt1: 'Với',
        stt2: 'Nook'
      },
      reno_ex: {
        title: 'Kinh nghiệm đổi mới',
        stt1: 'Tốn nhiều thời gian',
        stt2: 'Dịch vụ trọn gói'
      },
      design: {
        title: 'Thiết kế',
        stt1: 'Góp nhặt từ nhiều ý tưởng, đôi khi rời rạc, không đồng bộ',
        stt2: 'Mẫu thiết kế gợi ý từ nhà thiết kế nội thất chuyên nghiệp'
      },
      materials: {
        title: 'Vật liệu',
        stt1: 'Cần tự tìm nhà cung cấp, tự quản lý dự án',
        stt2: 'Chỉ cần đặt hàng, kiểm tra và nhận kết quả hoàn thiện'
      },
      support: {
        title: 'Hỗ trợ dự án',
        stt1: 'Không hỗ trợ',
        stt2: 'Hỗ trợ từ đầu đến cuối'
      },
      construction: {
        title: 'Xây dựng',
        stt1: 'Khó kiểm duyệt chất lượng',
        stt2: 'Nhà thầu được Nook chứng nhận'
      },
    },
    project_gallery: {
      title: 'DỰ ÁN TIÊU BIỂU',
      des: 'Khám phá các dự án khách hàng cải tạo cùng Nook.',
      view: 'XEM DỰ ÁN TIÊU BIỂU'
    },
    review: {
      title: 'Đánh Giá Từ Khách Hàng',
      des: 'Tìm hiểu tại sao khách hàng chọn Nook để cải tạo.',
      view: 'XEM ĐÁNH GIÁ KHÁC',
      user1: {
        name: 'Chủ đầu tư dự án - Quận 7, TP.HCM',
        date: '13/03/2021',
        stt: '“Chất lượng công trình rất tốt, nhân viên nhiệt tình hỗ trợ.“',
        tag: {
          tag1: 'CONDO',
          tag2: 'CONTRACTORS'
        }
      },
      user2: {
        name: 'Chủ đầu tư dự án - Quận 5, TP.HCM',
        date: 'Mar 13, 2021',
        stt: "“Tôi cải tạo 2 nhà tắm của mình với Nook, cảm thấy rất ưng ý vì giá cả rất rõ ràng. Mong Nook có thêm dịch vụ cải tạo nhà bếp để tôi triển luôn cho bếp nhà mình.“",
        tag: {
          tag1: 'CONDO',
          tag2: 'CONTRACTORS'
        }
      },
      user3: {
        name: 'Chủ đầu tư dự án - Quận 2, TP.HCM',
        date: 'Mar 13, 2021',
        stt: '“Nhiều thiết kế đẹp, tôi chọn thiết kế Minimalist, trông rất tối giản và sang trọng.“',
        tag: {
          tag1: 'CONDO',
          tag2: 'CONTRACTORS'
        }
      },
      user4: {
        name: 'Chủ dự án dự án - Quận 1, TP.HCM',
        date: 'Mar 13, 2021',
        stt: '“Cám ơn Nook vì sự hỗ trợ nhiệt tình cho tổ ấm của tôi. Hai vợ chồng tôi rất thích nhà tắm mới.“',
        tag: {
          tag1: 'CONDO',
          tag2: 'CONTRACTORS'
        }
      },
    },
  },
  howitworks: {
    title: 'CHÚNG HOẠT ĐỘNG NHƯ THẾ NÀO?',
    des: 'Tạo ra phòng tắm mơ ước của bạn với các nhà thiết kế và sự xây sựng chuyên nghiệp của Nook',
    stt1: 'Đã gồm sản phẩm, vật tư, nhân công',
    stt2: 'Đã gồm tư vấn thiết kế và 3D',
    stt3: 'Đảm bảo chất lượng và tiến độ',
    stt4: '26,000,000 VND',
    stt5: 'Giá khởi điểm cho phòng tắm của Nook',
    step1: {
      title: 'NHẬN BÁO GIÁ CHI PHÍ',
      subtitle: '1. NHẬN BÁO GIÁ CHO CẢI TẠO PHÒNG TẮM NHÀ BẠN',
      des: 'Sau khi lựa chọn mẫu thiết kế phù hợp phong cách, bạn sẽ nhận được báo giá cải tạo phòng tắm ngay tức thì.',
      faq1: {
        title: 'Thư Viện Mẫu Thiết Kế Tinh Tế',
        des: 'Bạn có thể tìm được mẫu thiết kế ưng ý trong thư viện mẫu thiết kế tinh tế của Nook với vô vàn tùy chọn, sản phẩm và thiết bị.'
      },
      faq2: {
        title: 'Phạm vi công việc phù hợp',
        des: 'Các chuyên gia lập kế hoạch dự án của Nook sẽ làm việc với bạn để thiết lập dự án một cách tốt nhất'
      },
      faq3: {
        title: 'Tiến trình dự án thực tế',
        des: 'Các chuyên gia lập kế hoạch dự án của Nook sẽ làm việc với bạn để thiết lập dự án một cách tốt nhất'
      },
      faq4: {
        title: 'Hướng dẫn phê duyệt xây dựng',
        des: 'Các chuyên gia lập kế hoạch dự án của Nook sẽ làm việc với bạn để thiết lập dự án một cách tốt nhất'
      },
    },
    step2: {
      title: 'CHUYÊN GIA THIẾT KẾ',
      subtitle: '2. THIẾT KẾ VỚI SỰ TỰ TIN',
      des: 'Đội ngũ thiết kế và kiến trúc sư sẽ giúp bạn tùy chỉnh phong cách thiết kế đã chọn cho phù hợp với bố cục phòng tắm của bạn.',
      faq1: {
        title: 'Đội ngũ chuyên gia',
        des: 'Đội ngũ thiết kế và kiến trúc sư của chúng tôi là những chuyên gia trong ngành. Chúng tôi sẽ giúp bạn chỉnh sửa thiết kế nếu bạn muốn thay đổi phong cách của mình với các sản phẩm và vật liệu khác nhau.'
      },
      faq2: {
        title: 'Khảo sát hiện trạng',
        des: 'Trước khi ký kết hợp đồng, đội ngũ của chúng tôi sẽ khảo sát kỹ lưỡng phòng tắm hiện tại của bạn để xác định những vấn đề hoặc rủi ro tiềm ẩn nào có thể xảy ra trong quá trình thi công. Bằng cách đó, các bên có thể chuẩn bị đầy đủ để tiến hành cải tạo.'
      },
      faq3: {
        title: 'Chế độ xem 360 độ 3D',
        des: 'Sau khi hoàn thiện thiết kế của bạn, đội ngũ của chúng tôi sẽ cung cấp cho bạn phối cảnh 360 độ toàn diện để bạn có thể hình dung không gian bên trong phòng tắm mới của mình.'
      },
      faq4: {
        title: 'Hướng dẫn Phê duyệt Xây dựng',
        des: 'Các nhà lập kế hoạch dự án của Nook đều là các chuyên gia cải tạo chuyên nghiệp. Họ đưa cho bạn những lời khuyên hợp lý để bạn thực hiện dự án cải tạo hoàn hảo nhất'
      },
    },
    step3: {
      title: 'X Y DỰNG',
      subtitle: '3. THI CÔNG CHẤT LƯỢNG',
      des: 'Người lập kế hoạch dự án chuyên dụng của bạn sẽ tư vấn cho bạn tất cả những điều cần biết về việc cải tạo và thiết lập dự án của bạn.',
      faq1: {
        title: 'Tiến trình dự án thực tế',
        des: 'Các nhà lập kế hoạch dự án của Nook đều là các chuyên gia cải tạo chuyên nghiệp. Họ đưa cho bạn những lời khuyên hợp lý để bạn thực hiện dự án cải tạo hoàn hảo nhất'
      },
      faq2: {
        title: 'Phạm vi công việc phù hợp',
        des: 'Các nhà lập kế hoạch dự án của Nook đều là các chuyên gia cải tạo chuyên nghiệp. Họ đưa cho bạn những lời khuyên hợp lý để bạn thực hiện dự án cải tạo hoàn hảo nhất'
      },
      faq3: {
        title: 'Tiến trình dự án thực tế',
        des: 'Các nhà lập kế hoạch dự án của Nook đều là các chuyên gia cải tạo chuyên nghiệp. Họ đưa cho bạn những lời khuyên hợp lý để bạn thực hiện dự án cải tạo hoàn hảo nhất'
      },
      faq4: {
        title: 'Tư vấn cải tạo',
        des: 'Các nhà lập kế hoạch dự án của Nook đều là các chuyên gia cải tạo chuyên nghiệp. Họ đưa cho bạn những lời khuyên hợp lý để bạn thực hiện dự án cải tạo hoàn hảo nhất'
      },

    },
    statements: {
      title: 'Nook cung cấp',
      full_service: {
        title: "Dịch vụ trọn gói",
        stt1: "Hãy chọn phong cách phòng tắm yêu thích của bạn và chúng tôi sẽ cải tạo từ A đến Z. Nook sẽ đảm nhận quá trình thiết kế từ vật liệu, nhân công, đến thi công.",
        stt2: "Đối với hệ thống ống nước trong phòng tắm, Nook cung cấp các thương hiệu quốc tế như Kohler và Delta.",
        stt3: "Thiết bị là một phần quan trọng trong phòng tắm về mặt thiết kế và chức năng. Nook khuyến khích bạn nên tự chọn các thiết bị để phù hợp với sở thích, phong cách sống và ngân sách cụ thể của bạn."
      },
      beautiful_design: {
        title: "Thiết kế tinh tế",
        stt1: 'Chúng tôi làm việc với đội ngũ thiết kế nội thất và kiến trúc sư hàng đầu để giúp bạn đưa ra giải pháp tốt nhất cho căn phòng tắm mơ ước.',
        stt2: 'Vật liệu bao gồm tất cả đồ đạc, vật liệu hoàn thiện và chi phí vận chuyển. Nook có sự hợp tác với các nhà cung cấp để mang đến mức giá tốt nhất cho bạn. Các dịch vụ bao gồm dịch vụ thiết kế, phê duyệt tòa nhà, kết xuất 3D, mua sắm và hỗ trợ dự án từ đầu đến cuối quá trình cải tạo của bạn. Cuối cùng, Xây dựng bao gồm bảo vệ nhà, phá dỡ và lao động bởi một nhà thầu đối tác được chứng nhận và kiểm tra.'
      },
      quality_materials: {
        title: "Vật liệu chất lượng",
        stt1: 'Tất cả các thiết bị và vật liệu chúng tôi cung cấp là sản phẩm chất lượng cao của các thương hiệu như TOTO, INAX, American Standard và Kohler.',
        stt2: 'Đối với hệ thống ống nước trong phòng tắm, Nook cung cấp các thương hiệu quốc tế như Kohler và Delta. Thiết bị là một phần quan trọng trong phòng tắm về mặt thiết kế và chức năng. Nook khuyến khích bạn nên tự chọn các thiết bị để phù hợp với sở thích, phong cách sống và ngân sách cụ thể của bạn.'
      },
      professional_team: {
        title: "Đội ngũ chuyên nghiệp",
        stt1: 'Phòng tắm của bạn sẽ được xây dựng bởi  nhà thầu chất lượng được Nook kiểm định nghiêm ngặt. Đảm bảo quá trình cải tạo trọn gói chất lượng và hiệu quả. Mang đến cho bạn trải nghiệm cải tạo mới mẻ: Nhanh gọn và không phiền hà.',
        stt2: 'Chúng tôi đề cao mối quan hệ bền vững với đối tác bằng cách xây dựng lòng tin thông qua những dự án đã thực hiện. Nook muốn phát triển mối quan hệ bền chặt với các đối tác của mình, đảm bảo độ tin cậy cao hơn nhiều so với khi làm việc với một nhà thầu trên cơ sở một lần.'
      },
      price_transparency: {
        title: "Giá cả minh bạch",
        stt1: 'Lo lắng về chi phí phụ? Bạn hãy yên tâm, Nook đảm bảo giá cả minh bạch để hạn chế những thay đổi giá không cần thiết.',
        stt2: 'Chúng tôi đề cao mối quan hệ bền vững với đối tác bằng cách xây dựng lòng tin thông qua những dự án đã thực hiện. Nook muốn phát triển mối quan hệ bền chặt với các đối tác của mình, đảm bảo độ tin cậy cao hơn nhiều so với khi bạn làm việc với một nhà thầu mới lần đầu.'
      }
    },
  },
  gallery: {
    title: 'PROJECT GALLERY',
    case_study: 'Featured Case Studies',
    project: 'Featured Projects',
    filter: 'Filter'
  },
  packages: {
    title: 'CHỌN GÓI THIẾT KẾ PHÒNG TẮM BẠN YÊU THÍCH',
    des: 'Khám phá những gói thiết kế của chúng tôi và nhận báo giá ngay lập tức. Mỗi thiết kế đều bao gồm thiết bị và vật liệu được lựa chọn cẩn thận từ các thương hiệu hàng đầu',
    scandinavian: {
      title: 'SCANDINAVIAN',
      des: 'Với sự tương phản độc đáo giữa vách tường xanh mang tông màu lạnh kết hợp với tủ gỗ mang tông màu ấm tạo ra không gian mộc mạc và tinh tế.',
      more: 'TÌM HIỂU THÊM'
    },
    huntington: {
      title: 'HUNTINGTON',
      des: 'Sự kết hợp giữa tường trơn với sàn gạch bông họa tiết tạo cảm giác sang trọng và cổ điển.',
      more: 'TÌM HIỂU THÊM'
    },
    japandi: {
      title: 'JAPANDI',
      des: 'Sự kết hợp giữa chủ nghĩa tối giản Nhật Bản và chức năng của vùng Scandinavia tạo ra sự pha trộn hoàn hảo giữa chức năng và hình thức, tập trung vào các đường nét sạch sẽ, không gian sáng sủa và màu sắc nhẹ nhàng.',
      more: 'TÌM HIỂU THÊM'
    },
    hexagon_flowers: {
      title: 'HEXAGON FLOWERS',
      des: 'Hình lục giác không chỉ mang tới tính thẩm mỹ cao cho không gian nhà cửa, mà còn tạo sự sang trọng, mới lạ bởi bản thân hình lục giác là sự dung hòa giữa khung hình tròn và hình vuông.',
      more: 'TÌM HIỂU THÊM'
    },
    we_know_renovations: 'Chúng tôi cải tạo',
    change_order_promise: 'Thay đổi yêu cầu đặt hàng',
    change_order_promise_paragraph: 'Thay đổi yêu cầu đặt hàng Pr',
    insurance_coverage: 'Bảo hành',
    insurance_coverage_paragraph: 'Phạm vi bảo hành',
    design_satisfaction_promise: 'Cam kết thiết kế',
    design_satisfaction_promise_paragraph: 'Cam kết thiết kế Pr',
    finance_avai: 'Có sẵn nguồn tài chính đơn giản',
    finance_avai_more: 'TÌM HIỂU THÊM',
    common_question: {
      title: 'CÂU HỎI THƯỜNG GẶP',
      faq1: {
        title: "Nook có thêm mẫu nào khác ngoài các mẫu trên website không?",
        stt1: 'Có. Bạn vui lòng tham khảo giá một mẫu bất kỳ ở công cụ thiết kế trực tuyến 3D và để lại thông tin liên lạc. Kiến trúc sư của chúng tôi sẽ liên lạc để tư vấn thiết kế theo phong cách yêu thích của bạn nhé.',
      },
      faq2: {
        title: "Nook có xây phòng tắm mới không?",
        stt1: 'Hiện tại Nook cải tạo phòng tắm ở vị trí có sẵn hệ thống điện, nước. Chúng tôi chưa hỗ trợ xây mới.',
      },
      faq3: {
        title: "Mình có hết thiết bị vệ sinh rồi thì Nook có nhận thi công không?",
        stt1: 'Có. Nook sẽ thi công và áp dụng chính sách bảo hành cho phần thô và lắp đặt thiết bị. Chúng tôi sẽ không chịu trách nhiệm về bảo hành/bảo dưỡng thiết bị của khách hàng tự mua.',
      },
      faq4: {
        title: "Nook có bán thiết bị riêng không?",
        stt1: 'Không. Nook không bán thiết bị riêng.'
      },
      faq5: {
        title: "Tôi có sẵn đội thi công và thiết bị của mình. Nook có nhận thiết kế riêng không?",
        stt1: 'Không. Nook không nhận thiết kế riêng.',
      },
      faq6: {
        title: "Bạn có thi công những khu vực khác ngoài phòng tắm không?",
        stt1: 'Trước đó, khi chỉ tập trung vào dịch vụ cải tạo phòng tắm, chúng tôi đã nhận được rất nhiều yêu cầu về cải tạo các không gian khác. Thế nên, chúng tôi quyết định bắt tay vào các khu vực khác trong nhà. Nếu bạn có kế hoạch cải tạo không chỉ là phòng tắm của mình, vui lòng cho chúng tôi biết nhé.',
      },
      faq7: {
        title: "Giá trên hệ thống bao gồm những gì?",
        stt1: 'Giá trên hệ thống đã bao gồm phần thi công (nhân công và vật liệu cho các hạng mục tháo dỡ, vận chuyển xà bần, chống thâm, trét vữa, lát gạch, sơn trần, và lắp đặt thiết bị) và thiết bị phòng tắm.',
      },
      faq8: {
        title: "Tôi có thể thanh toán bằng phương thức nào?",
        stt1: 'Nook nhận thanh toán tiền mặt và chuyển khoản.'
      },
      faq9: {
        title: "Nook có nhận thi công ngoài khu vực Hồ Chí Minh không?",
        stt1: 'Hiện tại Nook chưa nhận thi công ngoài khu vực Hồ Chí Minh. Chờ một chút. Chúng tôi sẽ sớm đến với khu vực của bạn nhé.',
      },
      faq10: {
        title: "Chính sách bảo hành của Nook như thế nào?",
        stt1: 'Nook có chính sách bảo hành 01 năm đối với các hạng mục Nook thi công.',
      },
      faq11: {
        title: "Nook thi công mất bao nhiêu ngày?",
        stt1: 'Nook đảm bảo thi công trong vòng 07 ngày và không bao gồm thứ 7 và Chủ nhật.'
      },
    },
    view_all_faqs: 'XEM TẤT CẢ CÂU HỎI TRÊN CÂU HỎI THƯỜNG GẶP CỦA NOOK',
    still_question: 'Bạn vẫn còn câu hỏi khác?',
    call_us: 'GỌI CHO NOOK NGAY',
    phone: '0394 629 013'
  },
  packagedetail: {
    quality: {
      title: 'Vật liệu chất lượng + thiết kế chuyên nghiệp',
      des: 'Các vật liệu chất lượng cao kết hợp với các mẫu thiết kế được tuyển chọn chuyên nghiệp từ Nook sẽ đảm bảo một phòng tắm cao cấp cho bạn, bạn có thể thay đổi chi tiết bạn thích nhưng vẫn đảm bảo độ thẩm mĩ và phù hợp.'
  },
    start_from: 'STARTS FROM',
    des1: 'Products, materials, labor included',
    des2: 'Design and 3D rendering included',
    des3: 'Quality and timeline guaranteed',
    customize: {
        title: 'THIẾT KẾ THEO CÁCH RIÊNG CỦA BẠN',
        des: 'Có hàng chục lựa chọn về kiểu dáng để bạn thỏa thích trang trí phòng tắm theo phong cách riêng của mình. Mỗi phương án thiết kế đều đã được lựa chọn cẩn thận bởi các chuyên gia thiết kế của Nook để bạn yên tâm rằng, dù có thay đổi thiết kế thì nhà tắm vẫn đảm bảo được về tính thẩm mĩ',
        des2: 'Hãy xem ngay những mẫu nhà tắm đẹp của Nook.' 
    },
    vanity: 'Combo kệ gương + Bồn rửa mặt',
    shower: 'Vòi hoa sen',
    tile: 'Tiles',
    scandinavian: {
        name: 'Mẫu thiết kế Scandinavian',
        des: 'Màu xám lạnh, gỗ ấm áp và màu sắc nổi bật sẽ tạo ra một không gian thoải mái và hấp dẫn cho phòng tắm của bạn.’',
        price: '48,776,000 VND',
    },
    huntington: {
        name: 'Mẫu thiết kế Huntington',
        des: 'Lấy cảm hứng từ phong cách cổ điển ở Long Island - New York, thiết kế Huntington này tạo nên cảm giác đơn giản nhưng sang trọng cho ngôi nhà của bạn.',
        price: '30,873,200 VND',
    },
    japandi: {
      name: 'Mẫu thiết kế Japandi',
      des: 'Sự kết hợp giữa chủ nghĩa tối giản Nhật Bản và chức năng của vùng Scandinavia tạo ra sự pha trộn hoàn hảo giữa chức năng và hình thức, tập trung vào các đường nét sạch sẽ, không gian sáng sủa và màu sắc nhẹ nhàng.',
      price: '50,718,000 VND',
    },
    hexagon_flowers: {
      name: 'Mẫu thiết kế Hex Flower',
      des: 'Hình lục giác không chỉ mang tới tính thẩm mỹ cao cho không gian nhà cửa, mà còn tạo sự sang trọng, mới lạ bởi bản thân hình lục giác là sự dung hòa giữa khung hình tròn và hình vuông.',
      price: '33,908,000 VND',
    },
},
  contractors: {
    title: 'THI CÔNG CÙNG NHAU. THI CÔNG NHIỀU HƠN NỮA.',
    des: 'Khi trở thành đối tác thi công của Nook, bạn sẽ có đặc quyền tiếp cận với khách hàng, quy trình và sản phẩm của chúng tôi để có thể tập trung vào những thế mạnh của mình: chính là thi công.',
    application: 'APPLY NOW',
    meet: {
      title: 'RENOVATION, MEET COLLABORATION',
      profile: {
        title: 'Profile Matching',
        des: 'Our proprietary algorithm matches contractors to projects by finding the perfect fit given location, availability, field of expertise, and ongoing workloads.'
      },
      design: {
        title: 'DESIGN & PROCUREMENT',
        des: 'Our proprietary algorithm matches contractors to projects by finding the perfect fit given location, availability, field of expertise, and ongoing workloads.'
      },
      management: {
        title: 'BUILD MANAGEMENT',
        des: 'Our proprietary algorithm matches contractors to projects by finding the perfect fit given location, availability, field of expertise, and ongoing workloads.'
      },
      guarantee: {
        title: 'SIGN-OFF GUARANTEE',
        des: 'Our proprietary algorithm matches contractors to projects by finding the perfect fit given location, availability, field of expertise, and ongoing workloads.'
      }
    },
    network: {
      title: 'OUR NETWORK',
      slogan: 'NOOK BUILDS WITH THE BEST',
      des: 'Our network accepts only a handful of applicants each year. When you build with Nook, you’re building with the best.'
    },
    certified1: {
      title: 'Nhà thầu đạt chuẩn của Nook nghĩa là gì?',
      faq1: {
        title: 'Chất lượng nhất quán',
        des: 'Các nhà thầu của chúng tôi chứng minh đáp ứng được các tiêu chuẩn chất lượng cao nhất và cam kết về sự hoàn chỉnh.'
      },
      faq2: {
        title: 'Phẩm cách phù hợp',
        des: 'Chúng tôi chắc chắn làm việc với những nhà thầu tốt nhất; điều này có nghĩa là nhà thầu luôn thể hiện thiện chí không ngừng học hỏi và phát triển, đồng thời cùng chúng tôi hướng đến sự hài lòng của khách hàng.'
      },
      faq3: {
        title: 'Giai đoạn thử thách',
        des: 'Sau thời gian “thử nghiệm” làm việc cùng nhau trong một vài dự án, chúng tôi sẽ đánh giá tính phù hợp lâu dài. Chỉ sau khi hoàn thành giai đoạn này, nhà thầu mới thực sự trở thành nhà thầu đạt chuẩn của Nook.'
      },
      faq4: {
        title: 'Virtual Designer Tool',
        des: 'Using our virtual designer tool, you will be able to visualize your bathroom design in real-time.'
      },
    },
    certified2: {
      title: 'Làm sao để trở thành nhà thầu đạt chuẩn của Nook?',
      faq1: {
        title: 'Bắt đầu ứng tuyển',
        des: 'Đăng ký ứng tuyển bằng cách truy cập vào link trên trang này, sau đó điền vào tất cả các thông tin cần thiết.'
      },
      faq2: {
        title: 'Gặp gỡ đội ngũ của Nook',
        des: 'Sau khi xem xét hồ sơ năng lực của nhà thầu, chúng tôi sẽ mời nhà thầu đến phỏng vấn để hai bên có thể trao đổi và hiểu nhau hơn.'
      },
      faq3: {
        title: 'Hoàn thành giai đoạn thử thách',
        des: 'Trong thời gian thử thách, chúng tôi sẽ phối hợp chặt chẽ với nhà thầu và hỗ trợ nhà thầu trong suốt quá trình này để đảm bảo thành công cho nhà thầu.'
      },
      faq4: {
        title: 'Virtual Designer Tool',
        des: 'Using our virtual designer tool, you will be able to visualize your bathroom design in real-time.'
      },
    },
    apply: {
      title: 'Apply to get Block Certified.',
      action: 'Apply Here'
    },
    working: {
      title: 'WORKING TOGETHER',
      des: '"The contractor we were matched with was great. I really enjoyed Luis’ team — they were communicative and I loved seeing the steps come together bit by bit. It felt like we were tackling it step-by-step and getting it right" together.',
      author: 'Erica G, homeowner'
    },
    technology: {
      title: 'OUR TECHNOLOGY',
      subtitle: 'TRANSFORMATIVE TOOLS FOR THE TRADE',
      des: 'Milestone notifications, project task list, cash-flow management, digital build handbook, procurement tracker, and more.'
    },
    ready: {
      title: 'Ready to Renovate?',
      des: 'Get Your Estimate'
    },
    contractor_list: {
      title: 'DANH SÁCH ĐỐI TÁC THI CÔNG',
      ocean_pac: 'Đội ngũ chuyên viên kỹ thuật, thiết kế có tay nghề và năng động, cung cấp sản phẩm và dịch vụ đảm bảo chất lượng cho khách hàng.',
      thinh_phat: 'Luôn lắng nghe ý kiến của khách hàng, hợp tác chặt chẽ với khách hàng để đảm bảo khách hàng nhận đúng sản phẩm, đúng thời gian.'
    }
  },
  aboutus: {
    pr0: 'What is Nook?',
    pr1: 'Nook trong tiếng anh mang nghĩa là một không gian nhỏ ấm cúng. Bạn có thể hình dung đến một góc tường nhỏ kê chiếc ghế dài để ngồi thư giãn và trầm tư. Một không gian thư giãn tuyệt vời đúng không? Nook chính là cảm giác đó.',
    pr2: '“Nook” cũng muốn được xem một tay chơi…chữ chính hiệu. Nook và Nước, sự đồng âm thú vị này khiến hình ảnh của Nook hiện ra trong tâm trí của người nghe thật tươi mới và mát lành. Đó cũng chính là triết lý của Nook: mang đến phòng tắm của bạn sự thoải mái và thư giãn tuyệt đối. Sứ mệnh của Nook luôn hướng tới là tạo ra không gian thoải mái và tinh tế cho phòng tắm của mỗi gia đình ở Việt Nam. Đó chính là lý do chúng tôi luôn đề cao chất lượng xây dựng, kết hợp thiết kế chuyên nghiệp cùng với công cụ thiết kế ảo để mang đến một quy trình cải tạo tinh gọn. ',
    pr3: 'GẶP GỠ NHÀ SÁNG LẬP NOOK. ',
    pr4: 'Nook được thành lập vào đầu năm 2021 bởi Robert Huỳnh và Nathaniel Ricca, hai người bạn có niềm đam mê và khát khao tạo ra sự thay đổi ở Việt Nam.',
    pr5: 'Robert, một người Mỹ gốc Việt sinh ra và lớn lên tại Hoa Kỳ. Anh là người đầu tiên trong gia đình học đại học. Sau khi hoàn thành chương trình học tại Đại học Texas, Robert đã dành gần một thập kỷ làm việc tại Thung lũng Silicon ở các công ty như Microsoft và Google, phụ trách nhiều vị trí từ chiến lược và tài chính đến tiền điện tử và xe hơi tự lái. Vào năm 2020, Robert quyết định nghỉ việc để theo đuổi chương trình học  MBA tại Trường Kinh doanh Harvard. Đây là nơi anh gặp người bạn thân và đồng sáng lập của mình, Nathaniel.',
    pr6: 'Nathaniel lớn lên ở vùng nông thôn Mỹ với mẹ là người nhập cư từ Philippines và bố từ New York. Với tình yêu nghệ thuật, anh theo đuổi thiết kế đồ họa trong một thời gian ngắn trước khi chuyển sang theo học kinh doanh tại Đại học Virginia. Sau khi tốt nghiệp, Nathaniel làm việc tại Tập đoàn Volkswagen, giúp điều hướng công ty vượt qua hậu quả của vụ bê bối động cơ diesel năm 2015. Sau một thời gian, anh ấy quyết định theo học MBA, với ý định nâng cao kỹ năng của mình để một ngày nào đó thành lập công ty.',
    pr7: 'Sau năm học đầu tiên, Robert và Nathaniel bay từ Boston đến thành phố Hồ Chí Minh để bắt đầu Nook. Đó là vào mùa hè năm 2021 khi COVID tấn công thành phố nặng nề, nhưng 3 tuần trong khách sạn cách ly không làm họ chậm lại. Họ đã xây dựng một đội ngũ nhỏ nhưng mạnh và tiến lên nhanh chóng. Vào cuối mùa hè, thấy được sức hút không ngừng lớn lên của Nook,  họ đã quyết định rời Harvard Business School vô thời hạn để ở lại Việt Nam và làm việc toàn thời gian cho Nook.'
  },
  finance: {
    slogan: 'Hỗ trợ tài chính - Xây trước trả sau',
    des: "Nook hợp tác với Mpos và 29 ngân hàng liên kết để thực hiện chương trình trả góp 0% lãi suất được giảm bớt áp lực tài chính cho khách hàng, cải tạo phòng tắm chưa bao giờ dễ dàng và tiện lợi hơn với Nook",
    guarantee1: '0% lãi suất lên đến 12 tháng',
    guarantee2: '0 đồng phí chuyển đổi',
    guarantee3: 'Thủ tục đơn giản, chỉ cần thẻ tín dụng',
    guarantee4: 'Nook tư vấn, hỗ trợ hoàn toàn miễn phí'
  },
  careers: {
    slogan: 'Redesign the $120B home renovation market with us!',
    des: 'Nook là nền tảng gia đình tất cả trong một để tu sửa và thiết kế lại nhà ở Đông Nam Á. Chúng tôi cung cấp gói cải tạo đơn giản cho chủ nhà thông qua hình ảnh hóa được hỗ trợ công nghệ, các mẫu thiết kế được lựa chọn và chất lượng công trình được đảm bảo. ',
    roles: 'Vai trò của Nook’',
    aboutus: {
        title: 'Về chúng tôi',
        des: 'Nook được thành lập vào năm 2021 bởi Robert Huỳnh và Nathaniel Ricca, hai người bạn cùng lớp đến từ Mỹ có đam mê tạo ảnh hưởng tại Việt Nam bằng cách xây dựng một doanh nghiệp có thể giúp ích cho chủ nhà và nhà thầu đơn giản hóa quá trình cải tạo nhà vốn phức tạp.',
    },
    more: 'TÌM HIỂU THÊM',
    our_value: {
        title: 'Giá trị của chúng tôi',
        stt1: 'Làm những điều có ích cho xã hội’',
        stt2: 'Được trao quyền',
        stt3: 'Hoàn thành thôi',
        stt4: 'Tính tiết kiệm',
        stt5: 'Nghĩ lớn',
        stt6: 'Xây dựng niềm tin',
        stt7: 'Tự tin nêu quan điểm'
    },
    join: {
        title: 'Tại sao đồng hành cùng Nook',
        van: {
            name: 'Võ Bích Vân',
            job: 'Tư vấn thiết kế',
            statements: "Tôi cảm thấy rất hào hứng khi được làm việc tại Nook bởi đội ngũ hiện đại, năng động và không ngừng sáng tạo. Tất cả chúng tôi đều hướng tới cùng một mục tiêu: ứng dụng công nghệ để đơn giản hóa, nâng cao chất lượng cuộc sống đồng thời giải quyết các vấn đề trong không gian sống của mỗi gia đình. Đó là sự kết hợp tuyệt vời giữa đội ngũ công nghệ, thiết kế và xây dựng."
        },
        myky: {
            name: 'Nguyễn Lê Mỹ Kỳ',
            job: 'Project Manager',
            statements: 'Tôi thích ý tưởng và tầm nhìn của Nook về việc đưa công nghệ vào cải tạo phòng tắm, đây sẽ là một bước cải tiến cho ngành xây dựng ở Việt Nam. Tại môi trường làm việc năng động mà Nook xây dựng, tôi cảm thấy được thoải mái sáng tạo và mọi đóng góp đều được lắng nghe một cách trân trọng.',
        }
    }
},
  footer: {
    home: 'Trang chủ',
    packages: 'Các gói',
    contractors: 'Nhà thầu',
    gallery: 'Bộ sưu tập',
    faq: 'faq',
    financing: 'Tài chính',
    blog: 'blog',
    pricing: 'Bảng giá',
    howitworks: 'Cách làm',
    reviews: 'Nhận xét',
    agents: 'Agents',
    language: 'Language',
    callus: 'Về chúng tôi',
    careers: 'Công việc',
    privacy: 'Chính sách',
    des: 'Nook là nền tảng hiện đại giúp gói gọn tất cả giai đoạn cải tạo nhà cửa thành một để mang lại sự tiện lợi tuyệt đối cho chủ nhà ở khu Đông Nam Á. Chúng tôi cung cấp gói cải tạo đơn giản cho chủ nhà thông qua trực quan hóa công nghệ bằng các mẫu thiết kế đẹp và mới nhất.'
  },
  info: {
      contact: 'Liên hệ ngay với chúng tôi',
  }
}
