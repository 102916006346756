<template>
  <div class="w-full">
    <!-- <div class="flex flex-col md:flex-row w-full bg-primary-50 py-32">
      <div class="w-full self-center mx-auto">
        <div class="text-center sm:text-left mx-auto sm:ml-40">
          <p class="text-primary font-bold text-4xl mb-6">Welcome to Nook!</p>

          <p class="font-serif text-5xl leading-normal font-semibold">I’m Stella Wu, <br /> Founder and CEO.</p>

          <button class="bg-primary w-48 h-16 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-8">
            <span class="text-xl">Let's Talk</span>
          </button>
        </div>
      </div>

      <div class="self-center px-auto w-full mt-16 sm:mt-0">
        <img class="mx-auto rounded-full w-60 h-60" src="https://d10ysazx19ptke.cloudfront.net/nookassets/logo.svg" />
      </div>
    </div> -->

    <div class="my-32">
      <p class="text-5xl font-bold">
        {{ $t('footer.callus') }}
      </p>

      <!-- <p class="mt-4 text-xl font-semibold opacity-50">Một chút về Nook ...</p> -->
    </div>
    
    <div class="flex flex-col md:flex-row mb-20 w-full">
      <div class="md:ml-20 w-full mr-10">
        <div class="w-full md:w-4/6 mx-auto">
          <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/aboutus/aboutus2.webp" alt="About us">
          <!-- <p class="mt-2 mb-12 md:mb-0">As a young girl whose parents worked in construction, I grew up around builders.</p> -->
        </div>
      </div>

      <div class="mx-auto md:mr-20 w-11/12">
        <p class="text-xl font-medium">
          {{ $t('aboutus.pr1') }}
        </p>

        <p class="mt-12 text-xl font-medium">
          {{ $t('aboutus.pr2') }} 
        </p>
      </div>
    </div>

    <div class="flex flex-col md:flex-row mb-20 w-full">
      <div class="mx-auto md:ml-20 w-11/12">
        <p class="text-xl font-medium">{{ $t('aboutus.pr3') }}</p>

        <p class="mt-12 text-xl font-medium">
          {{ $t('aboutus.pr4') }}
        </p>

        <p class="mt-12 text-xl font-medium">
          {{ $t('aboutus.pr5') }}
        </p>
      </div>

      <div class="mr-20 w-full md:ml-10">
        <div class="md:w-4/6 mx-auto">
          <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/aboutus/aboutus1.webp" alt="About us">
          <!-- <p class="mt-2">As a young girl whose parents worked in construction, I grew up around builders.</p> -->
        </div>
      </div>
    </div>

    <div class="flex flex-col md:flex-row mb-20 w-full">
      <!-- <div class="md:ml-20 w-full mr-10">
        <div class="w-full md:w-4/6 mx-auto">
          <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/aboutus/aboutus1.jpeg" alt="About us">
          <p class="mt-2 mb-12 md:mb-0">As a young girl whose parents worked in construction, I grew up around builders.</p>
        </div>
      </div> -->

      <div class="mx-auto md:mr-20 w-11/12">
        <p class="text-xl font-medium">
          {{ $t('aboutus.pr6') }}
        </p>

        <p class="mt-12 text-xl font-medium">
          {{ $t('aboutus.pr7') }}
        </p>
      </div>
    </div>

    <!-- <div class="flex flex-col md:flex-row mb-20 w-full">
      <div class="mx-auto md:ml-20 w-11/12">
        <p class="text-xl font-medium">Nathaniel lớn lên ở vùng nông thôn Mỹ với mẹ là người nhập cư từ Philippines và bố từ New York. Với tình yêu nghệ thuật, anh theo đuổi thiết kế đồ họa trong một thời gian ngắn trước khi chuyển sang kinh doanh, cuối cùng hoàn thành bằng đại học tại Đại học Virginia. Sau đó, ông tiếp tục làm việc tại Tập đoàn Volkswagen, giúp điều hướng công ty vượt qua hậu quả của vụ bê bối động cơ diesel năm 2015. Sau một thời gian, anh ấy cũng quyết định theo học MBA, với ý định nâng cao kỹ năng của mình để một ngày nào đó thành lập công ty.</p>

        <p class="mt-12 text-xl font-medium">
          Sau năm học đầu tiên, Robert và Nathaniel từ Boston đến thành phố Hồ Chí Minh để bắt đầu thành lập Nook. Đó là vào mùa hè năm 2021 khi COVID tấn công thành phố nặng nề, nhưng 3 tuần trong khách sạn cách ly không làm họ chậm lại. Họ đã xây dựng một đội nhỏ nhưng mạnh và tiến lên nhanh chóng. Vào cuối mùa hè, họ thấy Nook đang có sức hút lớn như thế nào nên họ quyết định rời HBS vô thời hạn để ở lại Việt Nam và làm việc toàn thời gian cho Nook.
        </p>
      </div>

      <div class="mr-20 w-full md:ml-10">
        <div class="md:w-4/6 mx-auto">
          <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/aboutus/aboutus1.jpeg" alt="About us">
          <p class="mt-2">As a young girl whose parents worked in construction, I grew up around builders.</p>
        </div>
      </div>
    </div> -->

    <!-- <div class="w-full bg-aboutus-slogan my-16 py-20">
      <svg class="w-5 h-5 mb-4 inline-block" fill="white"><defs></defs><path d="M8.6 16.8H0v-6.5C0 7.9.6 6 1.6 4.6c1-1.4 2.7-2.7 5.1-3.7l1.9 3.5a9 9 0 00-3.1 2c-.6.8-1 1.6-1 2.5h4v8zm9.9 0H10v-6.5c0-2.4.5-4.3 1.5-5.7 1-1.4 2.7-2.7 5.1-3.7l1.9 3.5a9 9 0 00-3.1 2c-.6.8-1 1.6-1 2.5h4v8z" fill="white"></path></svg>
      <span class="text-white text-bold text-2xl md:text-4xl">Uy tín - Chất lượng - Không phiền hà</span>
      <svg class="transform rotate-180 w-5 h-5 mb-4 inline-block" fill="none"><defs></defs><path d="M8.6 16.8H0v-6.5C0 7.9.6 6 1.6 4.6c1-1.4 2.7-2.7 5.1-3.7l1.9 3.5a9 9 0 00-3.1 2c-.6.8-1 1.6-1 2.5h4v8zm9.9 0H10v-6.5c0-2.4.5-4.3 1.5-5.7 1-1.4 2.7-2.7 5.1-3.7l1.9 3.5a9 9 0 00-3.1 2c-.6.8-1 1.6-1 2.5h4v8z" fill="white"></path></svg>
      <p class="text-white text-sm md:text-2xl mt-8">Triết lý kinh doanh của Nook</p>
    </div> -->

    <!-- <div class="mt-32 mb-20">
      <p class="text-5xl font-bold">We Hope You Choose Us</p>

      <p class="mt-4 text-xl font-semibold opacity-50">For your Bay Area Flooring, Bathroom, Kitchen, or ADU projects.</p>
    </div> -->

    <!-- <div class="flex flex-col md:flex-row w-full justify-center mb-32">
      <div class="self-right">
        <img class="mx-auto w-full md:w-64" src="https://d10ysazx19ptke.cloudfront.net/nookassets/aboutus/aboutus2.svg" />
      </div>

      <div class="self-center px-12 md:px-0 md:self-left">
        <div class="text-left md:ml-32">
          <p class="font-semibold text-sm mb-4">Not just because</p>

          <p class="text-3xl">We Meet Your Budget</p>

          <p class="mt-2 font-semibold text-sm mb-4">but because</p>

          <p class="text-3xl">You Deserve To Have</p>

          <p class="font-bold text-3xl">The Best</p>

          <p class="text-3xl">Renovation Experience</p>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
export default {
  name: 'AboutUs',
}
</script>
