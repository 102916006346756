<template>
  <div class="case-study relative mr-8">
    <div class="study-hoverable">

      <div class="study-images">
        <img :src="'https://d10ysazx19ptke.cloudfront.net/nookassets/gallery/' + study.images[0]"
             class="project-image smaller-study-image" id="project-image-1"
              @click="$emit('open-dialog')">
        <img :src="'https://d10ysazx19ptke.cloudfront.net/nookassets/gallery/' + study.images[1]"
             class="project-image-hidden bigger-study-image" id="project-image-2"
             @click="$emit('open-dialog')">
      </div>
      <div class="study-images">
        <img :src="'https://d10ysazx19ptke.cloudfront.net/nookassets/gallery/' + study.images[2]"
             class="project-image-hidden bigger-study-image" id="project-image-3"
             @click="$emit('open-dialog')">
        <img :src="'https://d10ysazx19ptke.cloudfront.net/nookassets/gallery/' + study.images[3]"
             class="project-image-hidden smaller-study-image" id="project-image-4"
             @click="$emit('open-dialog')">
      </div>
    </div>
    <h4 class="text-md text-left my-1 lg:text-sm">{{ study.project }}</h4>
    <h3 class="text-md text-left">{{ study.title }}</h3>
    <div class="gallery-badge absolute top-4 left-4 rounded-xl px-2">
      <h3 class="text-md font-semibold">{{ study.room }}</h3>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CaseStudy',
  props: {
    study: Object
  },
}
</script>
<style>
.gallery-badge {
  background: rgba(255, 255, 255, 0.4);
}

.case-study {
  min-width: 256px;
}

.project-image-hidden {
  /*display: none;*/
  opacity: 0;
  height: 0;
  transition: opacity 0.4s ease-out;
}

@media screen and (min-width: 1024px) {
  .case-study {
    min-width: 600px;
  }

  .case-study img#project-image-1 {
    width: 600px;
    height: 350px;
  }

  .study-hoverable:hover {
    display: flex;
    flex-direction: column;
  }

  .study-hoverable:hover .study-images {
    display: flex;
    max-width: 600px;
    max-height: 350px;
  }

  .case-study .study-hoverable:hover img#project-image-1,
  .case-study .study-hoverable:hover img#project-image-4 {
    width: 389px;
    height: 167px;
  }

  .case-study .study-hoverable:hover img#project-image-2,
  .case-study .study-hoverable:hover img#project-image-3 {
    width: 195px;
    height: 167px;
  }

  .case-study .study-hoverable:hover img#project-image-1,
  .case-study .study-hoverable:hover img#project-image-2 {
    margin-bottom: 16px;
  }

  .case-study .study-hoverable:hover img#project-image-1,
  .case-study .study-hoverable:hover img#project-image-3 {
    margin-right: 16px;
  }

  .study-hoverable:hover .project-image-hidden {
    opacity: 1;
    height: auto;
  }

  .study-hoverable img {
    cursor: pointer;
  }
}
</style>
