<template>
  <div class="lg:flex lg:justify-center">
    <div class="text-black pt-7 px-7 pb-16 lg:grid lg:grid-cols-4 lg:gap-10 max-width-1200 lg:px-20">
<!--          <div class="mb-6 lg:mr-7">-->
<!--             <span class="text-2xl">NOOK</span> -->
<!--          </div>-->
      <!-- border-t border-primary border-dashed -->
      <div class="py-5 text-left text-sm lg:mr-7 lg:justify-self-center">
        <div class="mb-3">
          {{ $t("footer.des") }}
        </div>
        <div class="flex">
          <span class="mr-2">&copy;</span>
          <span>Nook 2022</span>
        </div>
        <div>
          <p class="text-xl mt-12">BACKED BY</p>
          <img class="max-h-16" src="https://d10ysazx19ptke.cloudfront.net/nookassets/sponsor.webp" alt="">
        </div>
      </div>
      <div class="navigation-footer flex flex-col pt-5 border-top-dashed-primary
                lg:mr-7 justify-self-center">
        <router-link v-for="item in navItems" :to="{name: item, params: { lang: this.$i18n.locale }}" :key="item">
          <div class="text-sm uppercase mb-5 text-left" @click="scrollToTop">
            {{ $t("footer." + item) }}
          </div>
        </router-link>
      </div>
      <div class="text-left py-5 border-top-dashed-primary
                lg:mr-7 justify-self-center">
        <span class="text-xs block">CALL US</span>
        <span class="text-xl block pb-5 lg:text-sm">0394 629 013</span>
        <span class="text-xs block">EMAIL</span>
        <span class="text-xl block pb-5 lg:text-sm break-words">team@nookrenovation.com</span>
        <span class="text-xs block">FOLLOW US</span>
        <div class="flex mt-1">
          <a class="w-5 h-5 mr-5" href="https://www.facebook.com/nook.renovation" target="_blank">
            <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/icons/facebook.svg" />
          </a>
          <a class="w-5 h-5 mr-5" href="https://www.instagram.com/nook.renovation/" target="_blank">
            <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/icons/instagram.svg" />
          </a>
          <a class="w-5 h-5 mr-5" href="https://www.linkedin.com/company/nookrenovation" target="_blank">
            <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/icons/linkedin.svg" />
          </a>
          <!-- <button class="w-5 h-5">
            <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/icons/pinterest.svg" />
          </button> -->
        </div>
      </div>
      <div class="text-left py-5 border-top-dashed-primary lg:mr-7 justify-self-center">
        <div class="mb-5">
          <router-link :to="{name: 'aboutus', params: { lang: this.$i18n.locale }}">
            <a class="text-left text-sm">{{ $t('footer.callus') }}</a>
          </router-link>
        </div>
        <div class="mb-5">
          <a class="text-left text-sm" href="https://blog.nookrenovation.com/" target="_blank">
            Blog
          </a>
        </div>
        <div class="mb-5">
          <!-- <a href="https://www.workatastartup.com/companies/nook" class="text-left text-sm" target="_blank">
            {{ $t('footer.careers') }}
          </a> -->
          <router-link :to="{name: 'careers', params: { lang: this.$i18n.locale }}">
            <a class="text-left text-sm">{{ $t('footer.careers') }}</a>
          </router-link>
        </div>
        <!-- <div class="mb-5">
          <router-link to="/terms">
            <a class="text-left text-sm">Terms of use</a>
          </router-link>
        </div> -->
        <div>
          <router-link :to="{name: 'privacy', params: { lang: this.$i18n.locale }}">
            <a class="text-left text-sm">{{ $t('footer.privacy') }}</a>
          </router-link>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import {NavFooterItems} from "@/constants";

export default {
  components: {},
  data() {
    return {
      navItems: NavFooterItems,
    }
  },
  methods: {
    scrollToTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  }
}
</script>
<style>
.border-top-dashed-primary {
  /* @apply border-t border-primary border-dashed; */
}
</style>
