<template>
  <div>
    <div v-if="!$route.params.type">
      <div class="flex justify-center">
        <div class="p-7 lg:p-14 packages-preview">
          <div class="text-left lg:text-center">
            <h2 class="text-3xl mb-4 lg:text-4xl lg:mb-8">
              {{ $t("packages.title") }}
            </h2>
            <p class="text-2xl mb-8">
              {{ $t("packages.des") }}
            </p>
          </div>
          <div class="lg:flex lg:flex-row lg:flex-wrap lg:items-stretch">
            <div class="w-full bg-gray package-preview" v-for="(item, index) in listPackages" :key="index">
              <div>
                <div class="py-4 font-semibold uppercase">
                  {{ $t(item.title) }}
                </div>
                <router-link :to="{name: 'packagesdetails', params: { lang: this.$i18n.locale, type: item.type }}">
                  <img :src="item.src"/>
                </router-link>
                <p class="text-md p-4 text-left">
                  {{ $t(item.des) }}
                </p>
              </div>
              <!-- <a
                  href="https://designer.nookrenovation.com/"
                  class="button-primary w-full h-12"
                  target="_blank"
              >
                {{ $t("packages.scandinavian.more") }}
              </a> -->
              <router-link :to="{name: 'packagesdetails', params: { lang: this.$i18n.locale, type: item.type }}" class="button-primary w-full h-12">
                {{ $t(item.more) }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-gray w-full py-20 px-5 flex flex-col items-center">
        <!-- <h2 class="text-base">{{ $t("packages.we_know_renovations") }}</h2> -->
        <div class="lg:flex">
          <div class="mt-11 flex flex-col items-center">
            <div class="w-20 h-20 rounded-full">
              <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/packages/worker.webp"/>
            </div>
            <div class="flex mt-5">
              <h3 class="text-sm font-semibold mr-1">
                {{ $t("packages.change_order_promise") }}
              </h3>
              <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"/>
              </svg> -->
            </div>
            <!-- <span class="text-xs mt-3">{{ $t("packages.change_order_promise_paragraph") }}</span> -->
          </div>
          <div class="mt-11 flex flex-col items-center mx-36">
            <div class="w-20 h-20 rounded-full">
              <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/packages/design.webp"/>
            </div>
            <div class="flex mt-5">
              <h3 class="text-sm font-semibold mr-1">
                {{ $t("packages.insurance_coverage") }}
              </h3>
              <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"/>
              </svg> -->
            </div>
            <!-- <span class="text-xs mt-3">{{ $t("packages.insurance_coverage_paragraph") }}</span> -->
          </div>
          <div class="mt-11 flex flex-col items-center">
            <div class="w-20 h-20 rounded-full">
              <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/packages/dong.webp"/>
            </div>
            <div class="flex mt-5">
              <h3 class="text-sm font-semibold mr-1">
                {{ $t("packages.design_satisfaction_promise") }}
              </h3>
              <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"/>
              </svg> -->
            </div>
            <!-- <span class="text-xs mt-3">{{ $t("packages.design_satisfaction_promise_paragraph") }}</span> -->
          </div>
        </div>
      </div>
      <div class="financing relative h-72">
        <img class="h-72 w-full object-cover" src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/financing_ver1.webp"/>
        <div class="financing-text absolute flex flex-col items-center">
          <h1 class="text-3xl text-black lg:text-4xl">
            {{ $t("packages.finance_avai") }}
          </h1>
          <router-link
              :to="{name: 'financing', params: { lang: this.$i18n.locale }}"
              class="w-60 h-12 bg-primary text-black uppercase flex items-center justify-center text-xs hover:bg-custom-hover text-white mt-5"
          >
            <span>{{ $t("packages.finance_avai_more") }}</span>
          </router-link>
        </div>
      </div>

      <div class="bg-custom-light-green px-5 py-7">
        <h3 class="common-questions text-sm font-semibold text-left pb-4">
          <span>
            {{ $t("packages.common_question.title") }}
          </span>
        </h3>
        <div class="lg:flex lg:justify-between lg:items-end">
          <div class="lg:w-1/2">
            <div class="">
              <Accordion :title="$t('packages.common_question.faq1.title')">
                <p class="text-left text-sm">
                  <span class="mb-3 block">
                    {{ $t("packages.common_question.faq1.stt1") }}
                  </span>
                  <!-- <span class="mb-3 block text-sm">
            {{ $t("packages.common_question.faq1.stt2") }}
          </span> -->
                  <!-- <span class="mb-3 block text-sm">
            {{ $t("packages.common_question.faq1.stt3") }}
          </span> -->
                </p>
              </Accordion>
              <Accordion :title="$t('packages.common_question.faq2.title')">
                <p class="pb-5 text-left">
                  <span class="mb-3 block text-sm">
                    {{ $t("packages.common_question.faq2.stt1") }}
                  </span>
                  <!-- <span class="mb-3 block text-sm">
            {{ $t("packages.common_question.faq2.stt2") }}
          </span> -->
                </p>
              </Accordion>
              <Accordion :title="$t('packages.common_question.faq3.title')">
                <p class="pb-5 text-left">
                  <span class="mb-3 block text-sm">
                    {{ $t("packages.common_question.faq3.stt1") }}
                  </span>
                  <!-- <span class="mb-3 block text-sm">
            {{ $t("packages.common_question.faq3.stt2") }}
          </span> -->
                </p>
              </Accordion>
              <Accordion :title="$t('packages.common_question.faq4.title')">
                <p class="text-left text-sm">
                  <span class="mb-3 block">
                    {{ $t("packages.common_question.faq4.stt1") }}
                  </span>
                  <!-- <span class="mb-3 block text-sm">
            {{ $t("packages.common_question.faq4.stt2") }}
          </span> -->
                  <!-- <span class="mb-3 block text-sm">
            {{ $t("packages.common_question.faq4.stt3") }}
          </span> -->
                </p>
              </Accordion>
              <Accordion :title="$t('packages.common_question.faq5.title')">
                <p class="text-left text-sm">
                  <span class="mb-3 block">
                    {{ $t("packages.common_question.faq5.stt1") }}
                  </span>
                </p>
              </Accordion>
              <Accordion :title="$t('packages.common_question.faq6.title')">
                <p class="text-left text-sm">
                  <span class="mb-3 block">
                    {{ $t("packages.common_question.faq6.stt1") }}
                  </span>
                </p>
              </Accordion>
              <Accordion :title="$t('packages.common_question.faq7.title')">
                <p class="text-left text-sm">
                  <span class="mb-3 block">
                    {{ $t("packages.common_question.faq7.stt1") }}
                  </span>
                </p>
              </Accordion>
              <Accordion :title="$t('packages.common_question.faq8.title')">
                <p class="text-left text-sm">
                  <span class="mb-3 block">
                    {{ $t("packages.common_question.faq8.stt1") }}
                  </span>
                </p>
              </Accordion>
              <Accordion :title="$t('packages.common_question.faq9.title')">
                <p class="text-left text-sm">
                  <span class="mb-3 block">
                    {{ $t("packages.common_question.faq9.stt1") }}
                  </span>
                </p>
              </Accordion>
              <Accordion :title="$t('packages.common_question.faq10.title')">
                <p class="text-left text-sm">
                  <span class="mb-3 block">
                    {{ $t("packages.common_question.faq10.stt1") }}
                  </span>
                </p>
              </Accordion>
              <Accordion :title="$t('packages.common_question.faq11.title')">
                <p class="text-left text-sm">
                  <span class="mb-3 block">
                    {{ $t("packages.common_question.faq11.stt1") }}
                  </span>
                </p>
              </Accordion>
            </div>
          </div>
          <div class="lg:w-1/2 lg:flex lg:flex-col lg:items-end">
            <div>
              <div class="pb-3 text-left">
                <span class="text-sm">
                  {{ $t("packages.still_question") }}
                </span>
              </div>
              <div class="text-left pt-2 pb-5">
                <span class="text-xs block">{{ $t("packages.call_us") }}</span>
                <span class="text-xl block pb-2">{{ $t("packages.phone") }}</span>
                <span class="text-xs block">EMAIL</span>
                <span class="text-xl block">team@nookrenovation.com</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <BathroomDetails
        :packagename="packagename"
        :des="des"
        :price="price"
        :avg-build="avgBuild"
        :mainpt="mainpt"
        :photos="photos"
        :slideshow="slideshow"
        :vanity="vanity"
        :shower="shower"
        :tile="tile">
      </BathroomDetails>
    </div>
  </div>
</template>
<script>
import Accordion from "@/components/Accordion";
import BathroomDetails from "@/components/BathroomDetails";

export default {
  name: "Packages",
  components: {BathroomDetails, Accordion},
  data() {
    return {
      listPackages: [
        {
          title: "packages.scandinavian.title",
          type: "scandinavian",
          src: "https://d10ysazx19ptke.cloudfront.net/nookassets/packages/scandinavian.webp",
          des: "packages.scandinavian.des",
          more: "packages.scandinavian.more",
        },
        {
          title: "packages.huntington.title",
          type: "huntington",
          src: "https://d10ysazx19ptke.cloudfront.net/nookassets/packages/huntington.webp",
          des: "packages.huntington.des",
          more: "packages.huntington.more",
        },
        {
          title: "packages.japandi.title",
          type: "japandi",
          src: "https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/japandi/19fn.webp",
          des: "packages.japandi.des",
          more: "packages.japandi.more",
        },
        {
          title: "packages.hexagon_flowers.title",
          type: "hexagon_flowers",
          src: "https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/hexagon_flowers/1.webp",
          des: "packages.hexagon_flowers.des",
          more: "packages.hexagon_flowers.more",
        },
      ],
      packagename: '',
      des: '',
      price: '',
      mainpt: '',
      photos: [],
      slideshow: [],
      vanity: [],
      shower: [],
      tile: [],
      avgBuild: 0,
    };
  },
  created() {
    this.checkBathroomParams(this.$route.params.type);
  },
  methods: {
    checkBathroomParams(toParams) {
      switch (toParams) {
        case 'scandinavian': {
          this.packagename = 'packagedetail.scandinavian.name';
          this.des = 'packagedetail.scandinavian.des';
          this.price = 'packagedetail.scandinavian.price';
          this.mainpt = 'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/scandinavian.webp';
          this.photos = [
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/scandinavian/6fn.webp',
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/scandinavian/4fn.webp',
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/scandinavian/5fn.webp',
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/scandinavian/3fn.webp',
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/scandinavian/2fn.webp',
          ];
          this.slideshow = [
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/scandinavian/slideshow/1a.webp',
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/scandinavian/slideshow/1c.webp',
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/scandinavian/slideshow/2a.webp',
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/scandinavian/slideshow/2b.webp',
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/scandinavian/slideshow/3b.webp',
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/scandinavian/slideshow/3c.webp',
          ];
          this.vanity = [
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/vanity1.webp',
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/vanity2.webp',
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/vanity3.webp',
          ];
          this.shower = [
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/shower1.webp',
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/shower2.webp',
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/shower3.webp',
          ];
          this.tile = [
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/tile1.webp',
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/tile2.webp',
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/tile3.webp',
          ];
          this.avgBuild = 0;
          break;
        }
        case 'huntington': {
          this.packagename = 'packagedetail.huntington.name'
          this.des = 'packagedetail.huntington.des'
          this.price = 'packagedetail.huntington.price';
          this.mainpt = 'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/huntington.webp';
          this.photos = [
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/huntington/10fn.webp',
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/huntington/13fn.webp',
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/huntington/15fn.webp',
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/huntington/7fnn.webp',
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/huntington/8fn.webp',
          ];
          this.slideshow = [
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/huntington/slideshow.webp',
          ];
          this.vanity = [
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/huntington/vanity1.webp',
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/huntington/vanity2.webp',
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/huntington/vanity3.webp',
          ];
          this.shower = [
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/huntington/shower-fix1.webp',
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/huntington/shower2.webp',
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/huntington/shower3.webp',
          ];
          this.tile = [];
          this.avgBuild = 0;
          break;
        }
        case 'japandi': {
          this.packagename = 'packagedetail.japandi.name'
          this.des = 'packagedetail.japandi.des'
          this.price = 'packagedetail.japandi.price';
          this.mainpt = 'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/japandi/19fn.webp';
          this.photos = [
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/japandi/17fn.webp',
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/japandi/18fn.webp',
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/japandi/16fn.webp',
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/japandi/20fn.webp',
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/japandi/21fn.webp',
          ];
          this.slideshow = [
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/japandi/slideshow/slideshow1.webp',
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/japandi/slideshow/slideshow2.webp',
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/japandi/slideshow/slideshow3.webp',
          ];
          this.vanity = [
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/japandi/vanity1.webp',
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/japandi/vanity2.webp',
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/japandi/vanity3.webp',
          ];
          this.shower = [
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/japandi/shower1.webp',
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/japandi/shower2.webp',
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/japandi/shower3.webp',
          ];
          this.tile = [
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/japandi/tile1thumbnail.webp',
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/japandi/tile2thumbnail.webp',
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/japandi/tile3thumbnail.webp',
          ];
          this.avgBuild = 0;
          break;
        }
        case 'hexagon_flowers': {
          this.packagename = 'packagedetail.hexagon_flowers.name'
          this.des = 'packagedetail.hexagon_flowers.des'
          this.price = 'packagedetail.hexagon_flowers.price';
          this.mainpt = 'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/hexagon_flowers/1.webp';
          this.photos = [
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/hexagon_flowers/2.webp',
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/hexagon_flowers/3.webp',
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/hexagon_flowers/4.webp',
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/hexagon_flowers/5.webp',
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/hexagon_flowers/6.webp',
          ];
          this.slideshow = [
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/hexagon_flowers/slideshow/11fn.webp',
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/hexagon_flowers/slideshow/2fnnn.webp',
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/hexagon_flowers/slideshow/5fn.webp',
          ];
          this.vanity = [
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/hexagon_flowers/vanity1.webp',
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/hexagon_flowers/vanity2.webp',
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/hexagon_flowers/vanity3.webp',
          ];
          this.shower = [
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/hexagon_flowers/shower1.webp',
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/hexagon_flowers/shower2.webp',
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/hexagon_flowers/shower3.webp',
          ];
          this.tile = [
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/hexagon_flowers/tile1.webp',
            'https://d10ysazx19ptke.cloudfront.net/nookassets/packages/details/hexagon_flowers/tile2.webp',
          ];
          this.avgBuild = 0;
          break;
        }
        // default: {
        //   this.$router.push( this.i18n.locale + '/packages');
        //   break;
        // }
      }
    },
  },
  watch: {
    '$route.params.type': function(newVal) {
      if (newVal) {
        this.checkBathroomParams(newVal);
      }
    }
  }
};
</script>
<style scoped>
.packages-preview {
  max-width: 1200px;
}

.package-preview:not(:last-of-type) {
  @apply mb-8;
}

@media screen and (min-width: 1024px) {
  .package-preview {
    flex: calc(50% - 20px);
    max-width: 520px;

    @apply flex flex-col justify-between;
  }

  .package-preview:nth-of-type(odd) {
    margin-right: 40px;
  }

  .package-preview:last-of-type {
    @apply mb-8;
  }
}
</style>
