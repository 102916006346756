<template>
  <div>
    <Navbar>

    </Navbar>
    <router-view class="content-view" />
    <Footer>

    </Footer>
    <div class="layout-fixed-navbar" id="layout-fixed-navbar">
      <div class="h-16 flex justify-between">
        <div class="flex items-center h-full pl-4 contact-icons lg:pl-12">
          <p class="text-sm hidden lg:inline-block whitespace-nowrap mr-6">
            {{ $t("info.contact") }}
          </p>
          <a class="h-full w-12 flex items-center justify-center" href="tel:0394629013">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-6 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
            </svg>
          </a>
          <a class="h-full w-12 flex items-center justify-center" href="https://zalo.me/84394629013" target="_blank">
            <img class="h-6 w-6 mr-6 cursor-pointer" src="https://d10ysazx19ptke.cloudfront.net/nookassets/zalo.svg"/>
          </a>
          <a class="h-full w-12 flex items-center justify-center" href="https://m.me/nook.renovation" target="_blank">
            <img class="h-6 w-6 mr-6 cursor-pointer" src="https://d10ysazx19ptke.cloudfront.net/nookassets/messenger.svg"/>
          </a>
        </div>
        <div class="w-full md:w-72">
          <a
              href="https://designer.nookrenovation.com/"
              target="_blank"
              class="h-16 bg-primary text-black uppercase flex items-center justify-center text-xs
              hover:bg-custom-hover text-white">
            <span>{{ $t("navbar.pricing") }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Navbar from "@/core/layout/Navbar";
import Footer from "@/pages/Footer";

export default {
  components: {Footer, Navbar},
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      const bottomNav = document.getElementById('layout-fixed-navbar');

      if (window.scrollY > 600) {
        bottomNav.classList.add('show-layout-fixed-navbar');
      } else {
        bottomNav.classList.remove('show-layout-fixed-navbar');
      }
    }
  }
}

</script>

<style scoped>
html {
  scroll-behavior: smooth;
}

.contact-icons {
  width: 160px;
  flex-shrink: 0;
}

.layout-fixed-navbar {
  height: 0;
  opacity: 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  transition: 0.4s;
  background: rgb(225, 228, 238);
  z-index: 100;
}

.layout-fixed-navbar.show-layout-fixed-navbar {
  height: 64px;
  width: 100%;
  opacity: 1;
  position: fixed;
  bottom: 0;
  transition: 0.4s;
}

@media screen and (min-width: 1024px) {
  .contact-icons {
    width: 300px;
  }
}

@media screen and (max-width: 640px) {

}

</style>
