<template>
  <div class="text-left py-16 px-2 md:px-56">
    <p class="font-bold text-4xl">PRIVACY POLICY</p>

    <p class="mt-8">
      Welcome to the website of Nook Pte Ltd (“Nook,” “we,” “us,” or “our”! Nook
      is an all-in-one home platform to remodel and redesign homes in Southeast
      Asia. We offer a hassle-free renovation package to homeowners through
      tech-enabled visualization, curated design templates, and best-in-class
      construction provided by vetted contractors.
    </p>

    <p class="mt-4">
      This Privacy Policy describes what personal information we collect, how we
      use and share that data, and your choices and rights concerning our data
      practices. Access and use of our website is subject to this Privacy
      Policy.
    </p>

    <p class="font-bold mt-4">1. Collection of personal information</p>

    <p class="mt-4">
      We collect personal information that you provide us, from third parties,
      and automatically when you use our website. We collect information
      associated with your device using cookies and similar technologies. We may
      also receive information about your location.
    </p>

    <p class="mt-4">
      Inform you provide to us: We collect information that you provide us,
      including your account and profile information, content you submit or post
      to our Platform, your purchase information, your communications, and
      information you provide to us as part of surveys.
    </p>

    <p class="mt-4">
      Personal data we collect through our Social Media Pages: We have fan pages
      on social media sites like Facebook and Instagram (“Social Media Pages”).
      When you interact with our Social Media Pages, we will collect Personal
      Data that you elect to provide to us, such as your contact details. In
      addition, the companies that host our Social Media Pages may provide us
      with aggregate information and analytics regarding the use of our Social
      Media Pages.
    </p>

    <p class="mt-4">
      Personal data we receive automatically from your use of the service: When
      you visit, use and interact with the Service, information is sent to our
      server by your Internet browser or app and stored in our log files. For
      example, we may monitor the number of people that visit the service, the
      date and time of your visit, which page(s) are visited, the domains our
      visitors come from (e.g., zalo.com, facebook.com, etc.), and which
      browsers people use to access the Service (e.g., Google Chrome, Microsoft
      Internet Explorer, etc.), broad geographical information, and navigation
      pattern. In particular, the following information is created and
      automatically logged in our systems:
    </p>

    <ul class="mt-4 ml-12 list-disc">
      <li>
        Log data: Information that your browser automatically sends whenever you
        visit the website such as your Internet Protocol address, browser type,
        the date and time of your request, and other information to identify you
        and log your use across devices and third-party sites. We may use this
        information to personalize your experience.
      </li>
      <li>
        Cookies: Text files that websites store on a visitor’s device to
        identify visitor’s browser and store information for the purpose of
        helping you navigate between pages efficiently. Please see the “Cookies”
        section below for more details.
      </li>
      <li>
        Device information: Includes name of the device, browser, and operating
        system you are using. Information collected may depend on the type of
        device you use and its settings.
      </li>
      <li>
        Usage Information: We collect information about how you use our Service,
        such as the types of content that you view or engage with, the features
        you use, the actions you take, and the time, frequency and duration of
        your activities.
      </li>
    </ul>

    <p class="mt-4">
      Cookies: We use cookies to operate and administer our site, gather usage
      data on our site and personalize your experience on it. A “cookie” is a
      text file sent to your browser by a website you visit. Cookies can be
      stored on your computer for different periods of time with some expiring
      after a certain amount of time, or upon logging out (session cookies),
      while others survive after your browser is closed until a defined
      expiration date set in the cookie. Useful information about cookies can be
      found by visiting: All About Cookies (http://www.allaboutcookies.org).
    </p>

    <p class="mt-4">
      Do Not Track Signals: We and our third party service providers, including
      Facebook, may use cookies, pixels, or other tracking technologies to
      collect information about your browsing activities. We currently do not
      respond to “Do Not Track” or similar signals. If we do respond to these
      signals in the future, we will update this Privacy Policy to describe how
      we do so. To find out more about “Do Not Track”, please visit
      http://www.allaboutdnt.com/.
    </p>

    <p class="mt-4">Your Choices and Rights.</p>

    <p class="mt-4">
      We respond to all requests we receive from individuals wishing to exercise
      their data protection rights in accordance with applicable data protection
      laws. We may ask you to verify your identity in order to help us respond
      to your request securely and efficiently. Even where not legally required,
      we will use reasonable efforts to complete requests to access or delete
      your personal information. To request permanent deletion of your personal
      information, please contact us as described in the Contact Us section.
      Please note that if you limit the ability of websites to set cookies, you
      may be unable to access certain parts of the website and you may not be
      able to benefit from the full functionality of the website.
    </p>

    <p class="mt-4">
      Advertising networks may collect Personal Data through the use of cookies.
      Most advertising networks offer you a way to opt out of targeted
      advertising. If you would like to find out more information, please visit
      the Network Advertising Initiative’s online resources at
      http://www.networkadvertising.org and follow the opt-out instructions
      there.
    </p>

    <p class="font-bold mt-4">2. How we use your personal information</p>

    <p class="mt-4">
      We may use personal information for the following purposes to:
    </p>

    <ul class="mt-4 ml-12 list-disc">
      <li>provide, operate, and improve the Service;</li>
      <li>communicate and respond to your questions, comments, or feedback;</li>
      <li>establish and maintain your profile on the Service;</li>
      <li>
        provide support and administrative information to you, for example,
        information regarding the Service, and changes to our terms, conditions,
        and policies;
      </li>
      <li>analyze how you interact with our Service;</li>
      <li>
        maintain and improve the content and functionality of the Service;
      </li>
      <li>
        prevent fraud, criminal activity, or misuses of our Service, and to
        ensure the security of our IT systems, architecture and networks; and
      </li>
      <li>
        comply with legal obligations and legal process and to protect our
        rights, privacy, safety or property, and/or that of our affiliates, you
        or other third parties.
      </li>
    </ul>

    <p class="mt-4">
      Aggregated Information. We may aggregate personal information and use the
      aggregated data to analyze the effectiveness of our Service, to improve
      and add features to our Service, and for other similar purposes. In
      addition, from time to time, we may analyze the general behavior and
      characteristics of users of our Services and share aggregated information
      like general user statistics with prospective business partners. We may
      collect aggregated information through the Service, through cookies, and
      through other means described in this Privacy Policy.
    </p>

    <p class="font-bold mt-4">3. How we share your personal information</p>

    <p class="mt-4">
      We may share your personal information with the following parties and as
      otherwise described in this Privacy Policy or at the time of collection.
    </p>

    <ul class="mt-4 ml-12 list-disc">
      <li>
        Vendors, Professionals, Sellers, and Other Service Providers. We may
        share your project information (including the contact information) that
        you provide will be made available to a limited number of professionals
        that we believe may be suitable for your project. Vendors,
        Professionals, Sellers, and Other Service Providers that participate on
        the Nook Platform may view your activities on our Platform and receive
        feedback you provide to us about your engagement with them. We ask
        Vendors, Professionals, Sellers, and Other Service Providers on our Nook
        Platform to limit the use of your information to the reasons for which
        the information was provided.
      </li>
      <li>
        Corporate Affiliates and Business Transfers: If we are involved in a
        merger, acquisition, financing due diligence, reorganization,
        bankruptcy, receivership, sale of all or a portion of our assets, or
        transition of service to another provider, your personal information and
        other information may be shared in the diligence process with
        counterparties and others assisting with the transaction and transferred
        to a successor or affiliate as part of that transaction along with other
        assets.
      </li>
      <li>
        Legal Requirements: We may disclose information from or about you, as
        required or permitted by applicable law, If required to do so or in the
        good faith belief that such action is necessary to (i) comply with a
        legal obligation, including to meet national security or law enforcement
        requirements, (ii) protect and defend our rights or property, (iii)
        prevent fraud, (iv) act in urgent circumstances to protect the personal
        safety of users of the Services, or the public, or (v) protect against
        legal liability.
      </li>
      <li>
        Corporate Affiliates and Business Transfers: We may share Personal Data
        with our affiliates, meaning an entity that controls, is controlled by,
        or is under common control with Company. Our affiliates may use the
        Personal Data we share in a manner consistent with this Privacy Policy.
      </li>
    </ul>

    <p class="font-bold mt-4">4. CHILDREN</p>

    <p class="mt-4">
      Our Service is intended for general audiences and is not directed to
      children under 16. We do not knowingly collect personal information from
      children under 16. In the event that we learn that we have collected
      personal information from a child under age 16, we will take appropriate
      steps to delete that information. If you become aware or believe that a
      child has provided us with personal information, please contact us as
      provided in the Contact Us section.
    </p>

    <p class="font-bold mt-4">5. Other sites and services</p>

    <p class="mt-4">
      The Service may contain links to other websites, mobile applications, and
      other online services not operated or controlled by the Company. In
      addition, our content may be integrated into web pages or other online
      services that are not associated with us. These links and integrations are
      not an endorsement of, or representation that we are affiliated with, any
      third party. We do not control websites, mobile applications or online
      services operated by third parties, and we are not responsible for their
      actions. We encourage you to read the privacy policies of the other
      websites and mobile applications and online services you use and contact
      the Third Party Sites directly for information on their privacy practices
      and policies.
    </p>

    <p class="font-bold mt-4">6. Security</p>

    <p class="mt-4">
      We employ a number of technical, organizational and physical safeguards
      designed to protect the personal information we collect. However, security
      risk is inherent in all internet and information technologies and we
      cannot guarantee the security of your personal information.
    </p>

    <p class="font-bold mt-4">7. Changes to this Privacy Policy</p>

    <p class="mt-4">
      We reserve the right to modify this Privacy Policy at any time. If we make
      material changes to this Privacy Policy, we will notify you by updating
      the date of this Privacy Policy and posting it on the Service. If required
      by law we will also provide notification of changes in another way that we
      believe is reasonably likely to reach you, such as via email or another
      manner through the Service. Any modifications to this Privacy Policy will
      be effective upon our posting the modified version (or as otherwise
      indicated at the time of posting). In all cases, your use of the Service
      ager the effective date of any modified Privacy Policy indicates your
      acceptance of the modified Privacy Policy.
    </p>

    <p class="font-bold mt-4">10. CONTACT US</p>

    <p class="mt-4">
      If you have any questions or concerns about our Privacy Policy, please
      feel free to contact us at team@nookrenovation.com.
    </p>
  </div>
</template>
<script>
export default {
  name: "PrivacyPolicy",
};
</script>
