<template>
  <div>
    <h3 class="text-3xl my-10 lg:text-4xl lg:my-20">
      {{ $t('contractors.contractor_list.title') }}
    </h3>
    <div class="contractor-list-container">
      <div class="contractor-list">
        <div class="flex pt-5 px-5 contractor-item" v-for="i of constractorList" :key="i.id">
          <img class="mr-5 contractor-avatar" :src='i.image' />
          <div class="text-left">
            <h2 class="text-xl font-semibold mb-2">
              {{ i.name }}
            </h2>
            <img class="mb-2 review-star h-4" src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/star.svg" />
            <p>{{ $t(i.description) }}</p>
            <div class="flex mt-2">
              <!-- <router-link class="button-primary border border-solid bg-white px-4 py-2 rounded-md mr-4"
                :to="i.link">
                Details
              </router-link> -->
              <!-- <button class="button-primary border border-solid bg-white px-4 py-2 rounded-md">
                Contact
              </button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ContractorList',
  data() {
    return {
      constractorList: [
        {
          id: 1,
          name: 'Ocean Pac',
          rating: 4,
          description: 'contractors.contractor_list.ocean_pac',
          // image: require('https://d10ysazx19ptke.cloudfront.net/nookassets/contractors/oceanpac.png'),
          image: 'https://d10ysazx19ptke.cloudfront.net/nookassets/contractors/oceanpac.webp',
          link: '/contractors/details/1'
        },
        {
          id: 2,
          name: 'Thinh Phat',
          rating: 4,
          description: 'contractors.contractor_list.thinh_phat',
          // image: require('https://d10ysazx19ptke.cloudfront.net/nookassets/contractors/thinhphat.png'),
          image: 'https://d10ysazx19ptke.cloudfront.net/nookassets/contractors/thinhphat.webp',
          link: '/contractors/details/2'
        }
      ]
    }
  }
}
</script>
<style>
.contractor-item:last-of-type {
  @apply mb-5;
}

.contractor-list .contractor-item img.contractor-avatar {
  border-radius: 50%;
  width: 150px;
  height: 150px;
}

@media screen and (min-width: 1024px) {
  .contractor-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-bottom: 5rem;
    max-width: 1500px;
  }

  .contractor-item:last-of-type {
    @apply mb-0;
  }

  .contractor-list-container {
    @apply flex justify-center;
  }
}
</style>
