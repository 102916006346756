<template>
  <div class="w-full">
    <!-- review title -->
    <div class="py-16 border-b-2">
      <h1 class="text-3xl uppercase">Let's hear what customers talk about us</h1>
    </div>

    <!-- Review customer list -->
    <div class="w-full px-64 my-16">
      <div class="bg-white p-4 border-b-2">
        <div class="flex items-center">
          <div class="w-1/3">
            <img
              src="https://images.unsplash.com/photo-1518806118471-f28b20a1d79d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80"
              alt="customer"
              class="w-16 h-16 rounded-full inline"
            />
          </div>
          <div class="w-2/3">
            <h2 class="text-xl font-bold">Customer Name</h2>
            <p class="text-sm">Customer Address</p>
          </div>
        </div>
        <div class="flex items-center mt-4">
          <div class="w-1/3">
            <h2 class="text-xl font-bold">Rating</h2>
          </div>
          <div class="w-2/3">
            <div class="flex justify-center">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"/></svg>
              </div>
              <div>
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"/></svg>
              </div>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"/></svg>
              </div>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"/></svg>
              </div>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"/></svg>
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center mt-4">
          <div class="w-1/3">
            <h2 class="text-xl font-bold">Review</h2>
          </div>
          <div class="w-2/3">
            <p class="text-sm">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Quisquam, quos.
            </p>
          </div>
        </div>
        <!-- well tags -->
        <div class="flex items-center mt-4">
          <div class="w-1/3">
            <h2 class="text-xl font-bold">Tags</h2>
          </div>
          <div class="w-2/3">
            <div class="flex justify-center">
              <div>
                <span
                  class="bg-gray-200 text-gray-800 px-2 py-1 rounded-full text-sm font-semibold"
                  >Tag 1</span
                >
              </div>
              <div class="mx-4">
                <span
                  class="bg-gray-200 text-gray-800 px-2 py-1 rounded-full text-sm font-semibold"
                  >Tag 2</span
                >
              </div>
              <div>
                <span
                  class="bg-gray-200 text-gray-800 px-2 py-1 rounded-full text-sm font-semibold"
                  >Tag 3</span
                >
              </div>
            </div>
          </div>
        </div>
        <!-- Photos -->
        <div class="flex items-center mt-4">
          <div class="w-1/3">
            <h2 class="text-xl font-bold">Photos</h2>
          </div>
          <div class="w-2/3">
            <div class="flex items-center justify-center">
              <figure class="relative max-w-xs cursor-pointer">
                <img class="rounded-lg shadow-xl hover:shadow-2xl" src="https://images.unsplash.com/photo-1570169797118-99f5f90c4e25?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80">
                <div class="absolute text-lg -mt-16 text-white px-4">
                  <div>
                    <h1>Title</h1>
                  </div>
                  <div>
                    <h1>Subtitle</h1>
                  </div>
                </div>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Review customer list -->
    <div class="w-full px-64 my-16">
      <div class="bg-white p-4 border-b-2">
        <div class="flex items-center">
          <div class="w-1/3">
            <img
              src="https://images.unsplash.com/photo-1518806118471-f28b20a1d79d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80"
              alt="customer"
              class="w-16 h-16 rounded-full inline"
            />
          </div>
          <div class="w-2/3">
            <h2 class="text-xl font-bold">Customer Name</h2>
            <p class="text-sm">Customer Address</p>
          </div>
        </div>
        <div class="flex items-center mt-4">
          <div class="w-1/3">
            <h2 class="text-xl font-bold">Rating</h2>
          </div>
          <div class="w-2/3">
            <div class="flex justify-center">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"/></svg>
              </div>
              <div>
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"/></svg>
              </div>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"/></svg>
              </div>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"/></svg>
              </div>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"/></svg>
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center mt-4">
          <div class="w-1/3">
            <h2 class="text-xl font-bold">Review</h2>
          </div>
          <div class="w-2/3">
            <p class="text-sm">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Quisquam, quos.
            </p>
          </div>
        </div>
        <!-- well tags -->
        <div class="flex items-center mt-4">
          <div class="w-1/3">
            <h2 class="text-xl font-bold">Tags</h2>
          </div>
          <div class="w-2/3">
            <div class="flex justify-center">
              <div>
                <span
                  class="bg-gray-200 text-gray-800 px-2 py-1 rounded-full text-sm font-semibold"
                  >Tag 1</span
                >
              </div>
              <div class="mx-4">
                <span
                  class="bg-gray-200 text-gray-800 px-2 py-1 rounded-full text-sm font-semibold"
                  >Tag 2</span
                >
              </div>
              <div>
                <span
                  class="bg-gray-200 text-gray-800 px-2 py-1 rounded-full text-sm font-semibold"
                  >Tag 3</span
                >
              </div>
            </div>
          </div>
        </div>

        <!-- Photos -->
        <div class="flex items-center mt-4">
          <div class="w-1/3">
            <h2 class="text-xl font-bold">Photos</h2>
          </div>
          <div class="w-2/3">
            <div class="flex items-center justify-center">
              <figure class="relative max-w-xs cursor-pointer">
                <img class="rounded-lg shadow-xl hover:shadow-2xl" src="https://images.unsplash.com/photo-1570169797118-99f5f90c4e25?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80">
                <div class="absolute text-lg -mt-16 text-white px-4">
                  <div>
                    <h1>Title</h1>
                  </div>
                  <div>
                    <h1>Subtitle</h1>
                  </div>
                </div>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>

    
  </div>
</template>
<script>
export default {
  name: "Reviews",
};
</script>
