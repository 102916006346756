import {createRouter, createWebHistory} from "vue-router";
import Home from "@/pages/Home.vue";
import Bathroom from "@/pages/Bathroom.vue";
import Gallery from "@/pages/Gallery";
import Faq from "@/pages/Faq";
import Financing from "@/pages/Financing";
import Blog from "@/pages/Blog";
import Estimate from "@/pages/Estimate";
import Contractors from "@/pages/Contractors";
import HowItWorks from "@/pages/HowItWorks";
import Reviews from "@/pages/Reviews";
import AboutUs from "@/pages/AboutUs";
import Agents from "@/pages/Agents";
import Careers from "@/pages/Careers";
import PrivacyPolicy from "@/pages/PrivacyPolicy";
import TermOfService from "@/pages/TermOfService";
import Packages from "@/pages/Packages";
import ContractorDetails from "@/components/ContractorDetails";
import i18n from "../i18n";

const routes = [
    {
        path: '/:lang(vi|en)?',
        component: {
            template: "<router-view />"
        },
        beforeEnter: (to, from, next) => {
            const locale = to.params.lang

            const supported_locales = ['vi', 'en'];

            if (!supported_locales.includes(locale)) {
                return next('vi')
            }

            if (i18n.global.locale !== locale) {
                i18n.global.locale = locale;
            }

            return next()
        },
        children: [
            {
                path: "",
                name: "home",
                component: Home,
                meta: {
                    title: 'page_title.home',
                }
            },
            {
                path: "bathroom",
                name: "bathroom",
                component: Bathroom,
                meta: {
                    title: 'page_title.bathroom'
                }
            },
            {
                path: "gallery",
                name: "gallery",
                component: Gallery,
                meta: {
                    title: 'page_title.gallery'
                }
            },
            {
                path: "faq",
                name: "faq",
                component: Faq,
                meta: {
                    title: 'page_title.faq'
                }
            },
            {
                path: "financing",
                name: "financing",
                component: Financing,
                meta: {
                    title: 'page_title.financing'
                }
            },
            {
                path: "blog",
                name: "blog",
                component: Blog,
                meta: {
                    title: 'page_title.blog'
                }
            },
            {
                path: "estimate",
                name: "estimate",
                component: Estimate,
                meta: {
                    title: 'page_title.estimate'
                }
            },
            {
                path: "contractors",
                name: "contractors",
                component: Contractors,
                meta: {
                    title: 'page_title.contractors'
                }
            },
            {
                path: "contractors/details/:id",
                name: "contractorsdetails",
                component: ContractorDetails,
                meta: {
                    // title: i18n.global.t('page_title.contractors')
                    title: 'page_title.contractors'
                }
            },
            {
                path: "howitworks",
                name: "howitworks",
                component: HowItWorks,
                meta: {
                    title: 'page_title.how_it_works'
                }
            },
            {
                path: "packages",
                name: "packages",
                component: Packages,
                meta: {
                    title: 'page_title.packages'
                }
            },
            {
                path: "packages/:type",
                name: "packagesdetails",
                component: Packages,
                sensitive: true,
                meta: {
                    title: 'page_title.packages'
                }
            },
            {
                path: "aboutus",
                name: "aboutus",
                component: AboutUs,
                meta: {
                    title: 'page_title.about_us'
                }
            },
            {
                path: "reviews",
                name: "reviews",
                component: Reviews,
                meta: {
                    title: 'page_title.reviews'
                }
            },
            {
                path: "agents",
                name: "agents",
                component: Agents,
                meta: {
                    title: 'page_title.agents'
                }
            },
            {
                path: "careers",
                name: "careers",
                component: Careers,
                meta: {
                    title: 'page_title.careers'
                }
            },
            {
                path: "privacy",
                name: "privacy",
                component: PrivacyPolicy,
                meta: {
                    title: 'page_title.privacy_policy'
                }
            },
            {
                path: "terms",
                name: "terms",
                component: TermOfService,
                meta: {
                    title: 'page_title.term_of_service'
                }
            },
            {
                path: "home",
                redirect: '/'
            },
        ]
    },
    {
        path: "/:catchAll(.*)",
        redirect: '/vi'
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return {top: 0}
    },
});

// router.beforeEach((to, from, next) => {

//     const metaTags = [
//         {
//             name: 'description',
//             content: i18n.global.t('meta.des')
//         },
//         {
//             property: 'og:description',
//             content: i18n.global.t('meta.des')
//         },
//         {
//             property: 'og:image',
//             content: 'https://d10ysazx19ptke.cloudfront.net/nookassets/logo.png'
//         }
//     ];

//     metaTags.map(tagDef => {
//         const tag = document.createElement('meta');

//         Object.keys(tagDef).forEach(key => {
//             tag.setAttribute(key, tagDef[key]);
//         });

//         tag.setAttribute('data-vue-router-controlled', '');

//         return tag;
//     }).forEach(tag => document.head.appendChild(tag));

//     // document.title = i18n.global.t(to.meta.title);
//     next();
// });

router.afterEach((to, from, next) => {
    document.title = i18n.global.t(to.meta.title);
    next();
});

export default router;
