<template>
  <div>
    <div class="header w-full h-full relative">
      <img
        class="w-full h-full lg:hidden"
        src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/home_header.webp"
        alt=""
      />
      <img
        class="w-full h-full hidden lg:block"
        src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/home_header.webp"
        alt=""
      />
      <div class="home-slogan absolute">
        <h1 class="text-3xl text-black lg:text-4xl">
          {{ $t("finance.slogan") }}
        </h1>
        <div class="p-5 flex flex-col items-center hidden lg:flex">
          <span class="text-lg">
            {{ $t("finance.des") }}
          </span>
          <a
            href="https://designer.nookrenovation.com/"
            target="_blank"
            class="w-60 h-12 bg-primary text-black uppercase flex items-center justify-center text-xs hover:bg-custom-hover text-white my-5"
          >
            <span>{{ $t("navbar.pricing") }}</span>
          </a>
        </div>
      </div>
    </div>

    <div class="my-32">
      <!-- <p class="text-xl font-bold mx-8 md:mx-0">
        We’ve partnered with Hearth to provide convenient <br> financing options  with
        fixed interest, low monthly rates, and no annual fees.
      </p> -->

      <div class="flex flex-col w-full md:w-7/12 mt-16 md:mx-auto border bg-custom-light-green">
        <!-- <img class="w-64 mt-2 mx-auto" src="https://d10ysazx19ptke.cloudfront.net/nookassets/finance/mpos.svg" alt=""> -->
        <!-- <p class="text-2xl mb-16">0% interest up to 12 months</p> -->
        <table class="offer mt-6">
          <tbody>
            <tr>
              <td>
                <p>
                  <svg
                    viewBox="0 0 1024 1024"
                    style="
                      display: inline-block;
                      stroke: currentcolor;
                      fill: currentcolor;
                      width: 10px;
                      height: 10px;
                    "
                  >
                    <path
                      d="M1024 267.832l-162.909-158.118-465.455 451.765-232.727-225.882-162.909 158.118 395.636 384z"
                    ></path>
                  </svg>
                  {{ $t("finance.guarantee1") }}
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <svg
                    viewBox="0 0 1024 1024"
                    style="
                      display: inline-block;
                      stroke: currentcolor;
                      fill: currentcolor;
                      width: 10px;
                      height: 10px;
                    "
                  >
                    <path
                      d="M1024 267.832l-162.909-158.118-465.455 451.765-232.727-225.882-162.909 158.118 395.636 384z"
                    ></path>
                  </svg>
                  {{ $t("finance.guarantee2") }}
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <svg
                    viewBox="0 0 1024 1024"
                    style="
                      display: inline-block;
                      stroke: currentcolor;
                      fill: currentcolor;
                      width: 10px;
                      height: 10px;
                    "
                  >
                    <path
                      d="M1024 267.832l-162.909-158.118-465.455 451.765-232.727-225.882-162.909 158.118 395.636 384z"
                    ></path>
                  </svg>
                  {{ $t("finance.guarantee3") }}
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <svg
                    viewBox="0 0 1024 1024"
                    style="
                      display: inline-block;
                      stroke: currentcolor;
                      fill: currentcolor;
                      width: 10px;
                      height: 10px;
                    "
                  >
                    <path
                      d="M1024 267.832l-162.909-158.118-465.455 451.765-232.727-225.882-162.909 158.118 395.636 384z"
                    ></path>
                  </svg>
                  {{ $t("finance.guarantee4") }}
                </p>
              </td>
            </tr>
            <!-- <tr>
              <td>
                <p>
                  <svg
                    viewBox="0 0 1024 1024"
                    style="
                      display: inline-block;
                      stroke: currentcolor;
                      fill: currentcolor;
                      width: 10px;
                      height: 10px;
                    "
                  >
                    <path
                      d="M1024 267.832l-162.909-158.118-465.455 451.765-232.727-225.882-162.909 158.118 395.636 384z"
                    ></path>
                  </svg>
                  {{ $t("finance.guarantee5") }}
                </p>
              </td>
            </tr> -->
          </tbody>
        </table>

        <div class="flex justify-center mb-12">
          <button class="w-40 h-12 bg-primary text-black uppercase flex items-center justify-center text-xs
                        hover:bg-custom-hover text-white mt-7 rounded-full">
            <span>{{ $t('homepage.review.view') }}</span>
          </button>
        </div>
      </div>
    </div>

    <!-- <div class="w-full mb-12">
      <p class="text-3xl mb-16">0% interest up to 12 months</p>

      <div class="grid grid-cols-2 md:grid-cols-8 gap-4 px-16 mx-auto">
        <img class="w-32" src="https://d10ysazx19ptke.cloudfront.net/nookassets/finance/mpos.svg" alt="" v-for="i of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19]" :key="i">
      </div>
    </div> -->

    <div
      class="py-14 px-7 bg-gray home-third-container lg:flex lg:justify-center"
    >
      <div class="one-platform">
        <div class="lg:flex">
          <div class="mr-10">
            <h2 class="text-2xl text-left mb-7 lg:text-4xl">
              {{ $t("homepage.complete_platform.title") }}
            </h2>
          </div>
          <div>
            <h3 class="text-md text-left mb-4">
              {{ $t("homepage.complete_platform.description") }}
            </h3>
            <div class="text-sm text-left underline uppercase mb-12">
              <router-link :to="{name: 'home', params: { lang: this.$i18n.locale }}">
                {{ $t("homepage.complete_platform.learn") }}
              </router-link>
            </div>
          </div>
        </div>
        <div class="lg:flex lg:justify-between">
          <div class="how_it_works mb-12 relative lg:mr-20">
            <img class="mb-5 w-full" src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/how-it-works-1.webp" />
            <h1 class="text-2xl text-black absolute top-3 left-3">01</h1>
            <h4 class="text-sm text-left mb-5 font-semibold">
              {{ $t("homepage.complete_platform.plan.title") }}
            </h4>
            <p class="text-sm text-left">
              {{ $t("homepage.complete_platform.plan.stt1") }}
            </p>
          </div>
          <div class="how_it_works mb-12 relative lg:mr-20">
            <img class="mb-5 w-full" src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/how-it-works-2.webp" />
            <h1 class="text-2xl text-black absolute top-3 left-3">02</h1>
            <h4 class="text-sm text-left mb-5 font-semibold">
              {{ $t("homepage.complete_platform.design.title") }}
            </h4>
            <p class="text-sm text-left">
              {{ $t("homepage.complete_platform.design.stt1") }}
            </p>
          </div>
          <div class="how_it_works relative">
            <img class="mb-5 w-full" src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/how-it-works-3.webp" />
            <h1 class="text-2xl text-black absolute top-3 left-3">03</h1>
            <h4 class="text-sm text-left mb-5 font-semibold">
              {{ $t("homepage.complete_platform.build.title") }}
            </h4>
            <p class="text-sm text-left">
              {{ $t("homepage.complete_platform.build.stt1") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Financing",
};
</script>

<style scoped>
.offer svg {
  margin-right: 4px;
}

.offer td {
  height: 66px;
  @apply align-middle;
}

.offer td p {
  @apply pb-4 mx-3;
}

.offer th {
  @apply px-3 text-2xl pb-7;
}

.offer td:nth-of-type(3n+1) {
  @apply font-semibold;
}

</style>
