<template>
  <div class="py-20 px-5 lg:px-20 lg:flex lg:flex-col lg:items-center">
    <div class="career-first-container max-width-1200">
      <div class="">
        <h1 class="text-2xl mb-8 lg:text-4xl">
          {{ $t('careers.slogan') }}
        </h1>
      </div>
      <div class="">
        <p class="text-sm mb-10 lg:text-md">
          {{ $t('careers.des') }}
        </p>
        <div class="flex justify-center">
          <a href="#">
            <a href="https://www.workatastartup.com/companies/nook" class="button-primary w-64 h-12" target="_blank">
              {{ $t('careers.roles') }}
            </a>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="lg:flex lg:justify-center">
    <div class="flex justify-between px-10 pb-20 max-width-1200">
      <div class="mr-10 flex items-end">
        <img class="w-80 h-96 object-cover" src="https://d10ysazx19ptke.cloudfront.net/nookassets/careers/gen1.webp"/>
      </div>
      <div class="hidden lg:block mr-10">
        <img class="w-80 h-96 object-cover" src="https://d10ysazx19ptke.cloudfront.net/nookassets/careers/gen2.webp"/>
      </div>
      <div class="flex items-start">
        <img class="w-80 h-96 object-cover" src="https://d10ysazx19ptke.cloudfront.net/nookassets/careers/gen3.webp"/>
      </div>
    </div>
  </div>
  <div class="lg:flex lg:justify-center bg-gray">
    <div class="pb-5 text-left max-width-1200">
      <div class="mb-10 p-5 lg:mt-5">
        <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/careers/team.webp">
      </div>
      <div class="lg:flex lg:justify-center">
        <div class="career-about-us">
          <div class="p-5">
            <h2 class="text-2xl mb-4">{{ $t('careers.aboutus.title') }}</h2>
            <p class="mb-4">
              {{ $t('careers.aboutus.des') }}
            </p>
            <router-link :to="{name: 'aboutus', params: { lang: this.$i18n.locale }}">
              {{ $t('careers.more') }}
            </router-link>
          </div>
        </div>
        <div>
          <div class="bg-white m-5">
            <div class="card-text-inner px-8 pb-5">
              <h2 class="py-10 text-2xl">{{ $t('careers.our_value.title') }}</h2>
              <ul class="our-values-list">
                <li>
                  <svg class="icon" viewBox="0 0 1024 1024"
                       style="display: inline-block; stroke: currentcolor; fill: currentcolor; width: 16px; height: 16px;">
                    <path
                        d="M1024 230.4l-51.2-102.4c-441.6 128-665.6 409.6-665.6 409.6l-204.8-153.6-102.4 102.4 307.2 409.6c236.8-441.6 716.8-665.6 716.8-665.6z"></path>
                  </svg>
                  {{ $t('careers.our_value.stt1') }}
                </li>
                <li>
                  <svg class="icon" viewBox="0 0 1024 1024"
                       style="display: inline-block; stroke: currentcolor; fill: currentcolor; width: 16px; height: 16px;">
                    <path
                        d="M1024 230.4l-51.2-102.4c-441.6 128-665.6 409.6-665.6 409.6l-204.8-153.6-102.4 102.4 307.2 409.6c236.8-441.6 716.8-665.6 716.8-665.6z"></path>
                  </svg>
                  {{ $t('careers.our_value.stt2') }}
                </li>
                <li>
                  <svg class="icon" viewBox="0 0 1024 1024"
                       style="display: inline-block; stroke: currentcolor; fill: currentcolor; width: 16px; height: 16px;">
                    <path
                        d="M1024 230.4l-51.2-102.4c-441.6 128-665.6 409.6-665.6 409.6l-204.8-153.6-102.4 102.4 307.2 409.6c236.8-441.6 716.8-665.6 716.8-665.6z"></path>
                  </svg>
                  {{ $t('careers.our_value.stt3') }}
                </li>
                <li>
                  <svg class="icon" viewBox="0 0 1024 1024"
                       style="display: inline-block; stroke: currentcolor; fill: currentcolor; width: 16px; height: 16px;">
                    <path
                        d="M1024 230.4l-51.2-102.4c-441.6 128-665.6 409.6-665.6 409.6l-204.8-153.6-102.4 102.4 307.2 409.6c236.8-441.6 716.8-665.6 716.8-665.6z"></path>
                  </svg>
                  {{ $t('careers.our_value.stt4') }}
                </li>
                <li>
                  <svg class="icon" viewBox="0 0 1024 1024"
                       style="display: inline-block; stroke: currentcolor; fill: currentcolor; width: 16px; height: 16px;">
                    <path
                        d="M1024 230.4l-51.2-102.4c-441.6 128-665.6 409.6-665.6 409.6l-204.8-153.6-102.4 102.4 307.2 409.6c236.8-441.6 716.8-665.6 716.8-665.6z"></path>
                  </svg>
                  {{ $t('careers.our_value.stt5') }}
                </li>
                <li>
                  <svg class="icon" viewBox="0 0 1024 1024"
                       style="display: inline-block; stroke: currentcolor; fill: currentcolor; width: 16px; height: 16px;">
                    <path
                        d="M1024 230.4l-51.2-102.4c-441.6 128-665.6 409.6-665.6 409.6l-204.8-153.6-102.4 102.4 307.2 409.6c236.8-441.6 716.8-665.6 716.8-665.6z"></path>
                  </svg>
                  {{ $t('careers.our_value.stt6') }}
                </li>
                <li>
                  <svg class="icon" viewBox="0 0 1024 1024"
                       style="display: inline-block; stroke: currentcolor; fill: currentcolor; width: 16px; height: 16px;">
                    <path
                        d="M1024 230.4l-51.2-102.4c-441.6 128-665.6 409.6-665.6 409.6l-204.8-153.6-102.4 102.4 307.2 409.6c236.8-441.6 716.8-665.6 716.8-665.6z"></path>
                  </svg>
                  {{ $t('careers.our_value.stt7') }}
                </li>
              </ul>
            </div>
          </div>
          <!-- <div class="bg-white mt-10 mb-5 mx-5">
            <div class="card-text-inner px-8 pb-5">
              <h2 class="py-10 text-2xl">Our Vision</h2>
              <p>
                To create aspirational yet affordable spaces that bring homeowners bliss, surrounding them with form,
                function, and beauty.
              </p>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="lg:flex lg:justify-center bg-white">
    <div class="px-5 py-10 text-left max-width-1200
                lg:flex lg:flex-row-reverse lg:items-center what-we-do">
      <div class="">
        <div>
          <h2 class="text-2xl mb-5">What We Do</h2>
        </div>
        <p class="mb-5">
          Above all else, we believe in helping our clients elevate
          their homes. To us, that means not only making your home more beautiful, more functional, and more valuable,
          but also elevating your experience of being home. With half your life spent at home, we believe your personal
          space is an investment well-made.
        </p>
      </div>
      <div class="lg:mr-20">
        <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/careers/careers-5.svg"/>
      </div>
    </div>
  </div> -->

  <div class="lg:flex lg:flex-col lg:items-center py-16 px-5 lg:py-24 lg:px-10 bg-milk">
    <div class="max-width-1200 border-b border-black border-dashed pb-16 text-left">
      <h1 class="text-4xl lg:text-5xl">
        {{ $t('careers.join.title') }}
      </h1>
      <div class="lg:flex lg:gap-x-10 justify-center">
        <div class="flex flex-col mt-16 w-80" v-for="item in employees" :key="item">
          <img class="mb-10 w-80 h-96 object-cover" :src="item.image"/>
          <h3 class="text-md mb-2 w-full">
            {{ $t(item.name) }}
          </h3>
          <h4 class="text-sm mb-10">
            {{ $t(item.job) }}
          </h4>
          <p class="text-sm">
            {{ $t(item.bio) }}
          </p>
        </div>
      </div>
    </div>
    <!-- <div class="mt-16">
      <h1 class="text-4xl lg:text-5xl mb-10">
        Our Benefits
      </h1>
      <div class="grid grid-cols-2 gap-10 lg:grid-cols-3 our-benefits">
        <div class="flex flex-col items-center">
          <img class="mb-4" src="https://d10ysazx19ptke.cloudfront.net/nookassets/careers/benefit-1.svg"/>
          <div class="text-md">
            Medical, Dental & Vision
          </div>
        </div>
        <div class="flex flex-col items-center">
          <img class="mb-4" src="https://d10ysazx19ptke.cloudfront.net/nookassets/careers/benefit-2.svg"/>
          <div class="text-md">
            Unlimited PTO & Sick Time
          </div>
        </div>
        <div class="flex flex-col items-center">
          <img class="mb-4" src="https://d10ysazx19ptke.cloudfront.net/nookassets/careers/benefit-2.svg"/>
          <div class="text-md">
            Daily Catered Team Lunches
          </div>
        </div>
        <div class="flex flex-col items-center">
          <img class="mb-4" src="https://d10ysazx19ptke.cloudfront.net/nookassets/careers/benefit-4.svg"/>
          <div class="text-md">
            Team Activities & Happy Hours
          </div>
        </div>
        <div class="flex flex-col items-center">
          <img class="mb-4" src="https://d10ysazx19ptke.cloudfront.net/nookassets/careers/benefit-5.svg"/>
          <div class="text-md">
            401K & Commuter benefits
          </div>
        </div>
        <div class="flex flex-col items-center">
          <img class="mb-4" src="https://d10ysazx19ptke.cloudfront.net/nookassets/careers/benefit-6.svg"/>
          <div class="text-md">
            Monthly Cell Phone Stipend
          </div>
        </div>
      </div>
    </div> -->
  </div>
  <!-- <div class="lg:flex lg:flex-col lg:items-center py-16 px-5 lg:py-24 lg:px-10">
    <div
        class="max-width-1200 pb-16 text-left lg:flex lg:justify-between lg:w-full">
      <h1 class="text-2xl lg:text-3xl">
        Our Investors
      </h1>
      <div class="mt-16 lg:mt-0">
        <div class="grid grid-cols-2 gap-10 our-investors lg:gap-20">
          <div class="flex flex-col items-center">
            <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/careers/investor-1.svg"/>
          </div>
          <div class="flex flex-col items-center">
            <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/careers/investor-2.svg"/>
          </div>
          <div class="flex flex-col items-center">
            <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/careers/investor-3.svg"/>
          </div>
          <div class="flex flex-col items-center">
            <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/careers/investor-4.svg"/>
          </div>
          <div class="flex flex-col items-center">
            <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/careers/investor-5.svg"/>
          </div>
          <div class="flex flex-col items-center">
            <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/careers/investor-6.svg"/>
          </div>
        </div>
      </div>
    </div>
  </div> -->

</template>
<script>
export default {
  name: 'Careers',
  data() {
    return {
      employees: [
        {
          name: 'careers.join.van.name',
          job: 'careers.join.van.job',
          image: 'https://d10ysazx19ptke.cloudfront.net/nookassets/careers/van.webp',
          bio: 'careers.join.van.statements',
        },
        {
          name: 'careers.join.myky.name',
          job: 'careers.join.myky.job',
          image: 'https://d10ysazx19ptke.cloudfront.net/nookassets/careers/myky.webp',
          bio: 'careers.join.myky.statements',
        },
      ],
    }
  }
}
</script>
<style>

.our-values-list li {
  padding-bottom: 20px;
}

.our-values-list svg {
  margin-right: 16px;
  @apply text-primary;
}

.our-benefits img {
  width: 80px;
  height: 80px;
}

.career-select {
  @apply block appearance-none w-full
  border py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white;
  max-width: 360px;
}

.career-select option {
  width: 100%;
}

@media screen and (min-width: 1024px) {
  .career-first-container {
    max-width: 800px;
  }

  .our-values-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .career-about-us {
    width: 500px;
    margin-right: 80px;
  }

  .what-we-do > div {
    flex: 50%;
  }

  .current-job-openings {
    max-width: 800px;
  }
}
</style>
