<template>
  <div>
    <!--  first container-->
    <div class="header w-full h-full relative">
      <!-- <video class="w-full h-full lg:hidden" loop autoplay muted>
        <source src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/home_header_video_sm.mp4" type="video/mp4">
        Your browser does not support video.
      </video>
      <video class="w-full h-full hidden lg:block" loop autoplay muted>
        <source src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/home_header_video_lg.mp4" type="video/mp4">
        Your browser does not support video.
      </video> -->
      <img class="w-full h-full lg:hidden" src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/home_header.webp"
           alt="">
      <img class="w-full h-full hidden lg:block"
           src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/home_header.webp" alt="">
      <div class="home-slogan absolute">
        <h1 class="text-3xl text-black lg:text-4xl">
          {{ $t('homepage.slogan') }}
        </h1>
        <div class="p-5 flex flex-col items-center hidden lg:flex">
          <span class="text-lg">
            {{ $t('homepage.subslogan') }}
          </span>
          <a
              href="https://designer.nookrenovation.com/"
              target="_blank"
              class="w-60 h-12 bg-primary text-black uppercase flex items-center justify-center text-xs
              hover:bg-custom-hover text-white my-5">
            <span>{{ $t("navbar.pricing") }}</span>
          </a>
        </div>
      </div>
    </div>
    <div class="p-10 bg-gray flex flex-col items-center lg:hidden">
      <span class="text-lg">{{ $t('homepage.subslogan') }}</span>
      <a
          href="https://designer.nookrenovation.com/"
          target="_blank"
          class="w-60 h-12 bg-primary text-black uppercase flex items-center justify-center text-xs
          hover:bg-custom-hover text-white my-10">
        <span>{{ $t("navbar.pricing") }}</span>
      </a>
    </div>
    <!--  end of first container-->

    <div class="get-estimate px-6 pb-6 pt-20">
      <div class="lg:flex">
        <div class="lg:text-left renovation-estimate-text">
          <h2 class="text-3xl mb-6 font-semibold lg:text-4xl">
            {{ $t('homepage.allinclusive') }}
          </h2>
          <div class="flex flex-col items-center lg:items-start">
            <div class="flex flex-row text-left mb-2">
              <div class="renovation-estimate-stage">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                </svg>
                <span class="text-xl">
                  {{ $t('homepage.allinclusivetext.design') }}
                </span>
              </div>
              <div class="renovation-estimate-stage">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                </svg>
                <span class="text-xl">
                  {{ $t('homepage.allinclusivetext.construction') }}
                </span>
              </div>
            </div>
            <div class="flex flex-row text-left">
              <div class="renovation-estimate-stage">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                </svg>
                <span class="text-xl">
                  {{ $t('homepage.allinclusivetext.materials') }}
                </span>
              </div>
              <div class="renovation-estimate-stage">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                </svg>
                <span class="text-xl">
                  {{ $t('homepage.allinclusivetext.support') }}
                </span>
              </div>
            </div>
          </div>
          <div class="my-6">
            <p class="text-md">
              {{ $t('homepage.allinclusivetext.pickingbudget') }}
            </p>
          </div>
        </div>
        <div class="lg:flex estimate-style">
          <a class="w-full mb-8 cursor-pointer lg:mr-6 lg:mb-0"
             href="https://designer.nookrenovation.com/"
             target="_blank">
            <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/affordable_v2.webp"/>
            <div class="text-2xl my-4">{{ $t('homepage.allinclusivetext.affordable') }}</div>
          </a>
          <a class="w-full mb-8 cursor-pointer lg:mr-6 lg:mb-0"
             href="https://designer.nookrenovation.com/"
             target="_blank">
            <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/standard_v2.webp"/>
            <div class="text-2xl my-4">{{ $t('homepage.allinclusivetext.standard') }}</div>
          </a>
          <a class="w-full cursor-pointer lg:mb-0"
             href="https://designer.nookrenovation.com/"
             target="_blank">
            <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/luxury_v2.webp"/>
            <div class="text-2xl my-4">{{ $t('homepage.allinclusivetext.luxury') }}</div>
          </a>
        </div>
      </div>
    </div>

    <!--  second container-->
    <div class="reviews-from-companies">
      <div class="py-14 px-7 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-y-10">
        <div class="company-review-container h-full mx-3 flex flex-col items-center justify-start my-auto">
          <img class="w-24" src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/logo-cafe-biz.webp"/>
          <div class="company-review mt-3">
            <span class="text-sm">
              {{ $t('homepage.sponsors.cafebiz') }}
            </span>
          </div>
        </div>
        <div class="company-review-container h-full mx-3 flex flex-col items-center my-auto">
          <img class="w-24" src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/logo-cafe-f.webp"/>
          <div class="company-review mt-3">
            <span class="text-sm">
              {{ $t('homepage.sponsors.cafef') }}
            </span>
          </div>
        </div>
        <div class="company-review-container h-full mx-3 flex flex-col items-center my-auto">
          <img class="w-24" src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/logo-afamily.webp"/>
          <div class="company-review mt-3">
            <span class="text-sm">
              {{ $t('homepage.sponsors.afamily') }}
            </span>
          </div>
        </div>
        <div class="company-review-container h-full mx-3 flex flex-col items-center my-auto">
          <img class="w-24" src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/logo-vdas.webp"/>
          <div class="company-review mt-3">
            <span class="text-sm">
              {{ $t('homepage.sponsors.vdas') }}
            </span>
          </div>
        </div>
        <div class="company-review-container mx-3 flex flex-col items-center my-auto">
          <img class="w-24" src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/logo-techinasia.webp"/>
          <div class="company-review mt-3">
            <span class="text-sm">
              {{ $t('homepage.sponsors.beststartup') }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <!--  end of second container-->

    <div class="flex flex-col items-center">
      <h2 class="text-3xl py-6">
        {{ $t('homepage.statements.title') }}
      </h2>
      <div class="p-6 quality-accordions-container w-full">
        <Accordion class="quality-accordion" :title="$t('homepage.statements.full_service.title')">
          <p class="text-left text-sm">
                <span class="mb-3 block">
                  {{ $t('homepage.statements.full_service.stt1') }}
                </span>
            <span class="mb-3 block text-sm">
            {{ $t('homepage.statements.full_service.stt2') }}
          </span>
            <span class="mb-3 block text-sm">
            {{ $t('homepage.statements.full_service.stt3') }}
          </span>
          </p>
        </Accordion>
        <Accordion class="quality-accordion" :title="$t('homepage.statements.beautiful_design.title')">
          <p class="pb-5 text-left">
          <span class="mb-3 block text-sm">
            {{ $t('homepage.statements.beautiful_design.stt1') }}
          </span>
            <span class="mb-3 block text-sm">
          {{ $t('homepage.statements.beautiful_design.stt2') }}
          </span>
          </p>
        </Accordion>
        <Accordion class="quality-accordion" :title="$t('homepage.statements.quality_materials.title')">
          <p class="pb-5 text-left">
          <span class="mb-3 block text-sm">
            {{ $t('homepage.statements.quality_materials.stt1') }}
          </span>
            <span class="mb-3 block text-sm">
            {{ $t('homepage.statements.quality_materials.stt2') }}
          </span>
          </p>
        </Accordion>
        <Accordion class="quality-accordion" :title="$t('homepage.statements.professional_team.title')">
          <p class="pb-5 text-left">
          <span class="mb-3 block text-sm">
            {{ $t('homepage.statements.professional_team.stt1') }}
          </span>
            <span class="mb-3 block text-sm">
            {{ $t('homepage.statements.professional_team.stt2') }}
          </span>
          </p>
        </Accordion>
        <Accordion class="quality-accordion" :title="$t('homepage.statements.price_transparency.title')">
          <p class="pb-5 text-left">
          <span class="mb-3 block text-sm">
            {{ $t('homepage.statements.price_transparency.stt1') }}
          </span>
            <span class="mb-3 block text-sm">
            {{ $t('homepage.statements.price_transparency.stt2') }}
          </span>
          </p>
        </Accordion>
      </div>
    </div>

    <!--  third container-->

    <div class="py-14 px-7 bg-gray home-third-container lg:flex lg:justify-center">
      <div class="one-platform">
        <div class="lg:flex">
          <div class="mr-10">
            <h2 class="text-2xl text-left mb-7 lg:text-4xl">{{ $t('homepage.complete_platform.title') }}</h2>
          </div>
          <div>
            <h3 class="text-md text-left mb-4">{{ $t('homepage.complete_platform.description') }}</h3>
            <div class="text-sm text-left underline uppercase mb-12">
              <router-link :to="{name: 'howitworks', params: { lang: this.$i18n.locale }}">
                {{ $t('homepage.complete_platform.learn') }}
              </router-link>
            </div>
          </div>
        </div>
        <div class="lg:flex lg:justify-between">
          <div class="how_it_works mb-12 relative lg:mr-20">
            <img class="mb-5 w-full" src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/how-it-works-1.webp">
            <h1 class="text-2xl text-black absolute top-3 left-3">01</h1>
            <h4 class="text-sm text-left mb-5 font-semibold">{{ $t('homepage.complete_platform.plan.title') }}</h4>
            <p class="text-sm text-left">
              {{ $t('homepage.complete_platform.plan.stt1') }}
            </p>
          </div>
          <div class="how_it_works mb-12 relative lg:mr-20">
            <img class="mb-5 w-full" src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/how-it-works-2.webp">
            <h1 class="text-2xl text-black absolute top-3 left-3">02</h1>
            <h4 class="text-sm text-left mb-5 font-semibold">{{ $t('homepage.complete_platform.design.title') }}</h4>
            <p class="text-sm text-left">
              {{ $t('homepage.complete_platform.design.stt1') }}
            </p>
          </div>
          <div class="how_it_works relative">
            <img class="mb-5 w-full" src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/how-it-works-3.webp">
            <h1 class="text-2xl text-black absolute top-3 left-3">03</h1>
            <h4 class="text-sm text-left mb-5 font-semibold">{{ $t('homepage.complete_platform.build.title') }}</h4>
            <p class="text-sm text-left">
              {{ $t('homepage.complete_platform.build.stt1') }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!--  end of third container-->

    <!--  fourth container-->
    <div class="financing relative h-72">
      <img class="h-72 w-full object-cover" src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/financing_ver1.webp"/>
      <div class="financing-text absolute flex flex-col items-center">
        <h1 class="text-3xl text-black lg:text-4xl">
          {{ $t('homepage.renovate.title') }}
        </h1>
        <router-link :to="{name: 'financing', params: { lang: this.$i18n.locale }}" class="w-60 h-12 bg-primary text-black uppercase flex items-center justify-center text-xs
                      hover:bg-custom-hover text-white mt-5">
          <span>{{ $t('homepage.renovate.explore') }}</span>
        </router-link>
      </div>
    </div>
    <!--  end of fourth container-->


    <!--  fifth container-->
    <!-- <div class="bg-gray py-7 px-5 lg:p-20">
      <h1 class="text-3xl text-black text-left mb-10 lg:mb-20 lg:text-center">
        {{ $t('homepage.ours.title') }}
      </h1>

      <div class="lg:flex lg:items-center">
        <div class="renovation mb-7 w-full mr-32">
          <h3 class="text-xl text-black py-6">
            {{ $t('homepage.ours.bathroom.title') }}
          </h3>
          <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/renovation_bathroom.svg" class="w-full mb-6">
          <div class="p-7">
            <h4 class="text-sm text-left mb-3 font-semibold">{{ $t('homepage.ours.bathroom.week') }}</h4>
            <p class="text-left text-2xl pb-7">
              {{ $t('homepage.ours.bathroom.des') }}
            </p>
            <div class="flex justify-center">
              <router-link to="/bathroom">
                <button class="w-40 h-12 bg-primary text-black uppercase flex items-center justify-center text-xs
                      hover:bg-black hover:text-white mt-5">
                  <span>{{ $t('homepage.ours.bathroom.learn') }}</span>
                </button>
              </router-link>
            </div>
          </div>
        </div>

        <div class="renovation mb-7 w-full">
          <h3 class="text-xl text-black py-6">
            {{ $t('homepage.ours.contractors.title') }}
          </h3>
          <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/renovation_kitchen.svg" class="w-full mb-6">
          <div class="p-7">
            <h4 class="text-sm text-left mb-3 font-semibold">{{ $t('homepage.ours.contractors.week') }}</h4>
            <p class="text-left text-2xl pb-7">
              {{ $t('homepage.ours.contractors.des') }}
            </p>
            <div class="flex justify-center">
              <router-link to="/contractors">
                <button class="w-40 h-12 bg-primary text-black uppercase flex items-center justify-center text-xs
                      hover:bg-black hover:text-white mt-5">
                  <span>{{ $t('homepage.ours.contractors.learn') }}</span>
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!--  end of fifth container-->

    <div class="why-nook bg-custom-light-gray py-20 px-5 lg:px-20 text-left lg:flex lg:justify-center">
      <div class="relative">
        <h1 class="text-4xl font-bold mb-20">
          {{ $t('homepage.why.title') }}
        </h1>
        <table class="experience-table-mobile" v-if="screenSize <= 640">
          <thead>
          <tr>
            <th>{{ $t('homepage.why.typical_ex.stt1') }}<br> {{ $t('homepage.why.typical_ex.stt2') }}</th>
            <th>{{ $t('homepage.why.with_nook.stt1') }}<br> {{ $t('homepage.why.with_nook.stt2') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td><strong>{{ $t('homepage.why.reno_ex.title') }}</strong>
              <p>{{ $t('homepage.why.reno_ex.stt1') }}</p></td>
            <td><strong>{{ $t('homepage.why.reno_ex.title') }}</strong>
              <p :class="{'content-two-row': screenSize <= 640}">
                {{ $t('homepage.why.reno_ex.stt2') }}
              </p>
            </td>
          </tr>
          <tr>
            <td><strong>{{ $t('homepage.why.design.title') }}</strong>
              <p :class="{'content-two-row': screenSize <= 640}">
                {{ $t('homepage.why.design.stt1') }}
              </p>
            </td>
            <td><strong>{{ $t('homepage.why.design.title') }}</strong>
              <p>
                {{ $t('homepage.why.design.stt2') }}
              </p></td>
          </tr>
          <tr>
            <td><strong>{{ $t('homepage.why.materials.title') }}</strong>
              <p :class="{'content-two-row': screenSize <= 640}">
                {{ $t('homepage.why.materials.stt1') }}</p></td>
            <td><strong>{{ $t('homepage.why.materials.title') }}</strong>
              <p>{{ $t('homepage.why.materials.stt2') }}</p></td>
          </tr>
          <tr>
            <td><strong>{{ $t('homepage.why.support.title') }}</strong>
              <p>{{ $t('homepage.why.support.stt1') }}</p></td>
            <td><strong>{{ $t('homepage.why.support.title') }}</strong>
              <p>{{ $t('homepage.why.support.stt2') }}</p></td>
          </tr>
          <tr>
            <td><strong>{{ $t('homepage.why.construction.title') }}</strong>
              <p>{{ $t('homepage.why.construction.stt1') }}</p></td>
            <td><strong>{{ $t('homepage.why.construction.title') }}</strong>
              <p>{{ $t('homepage.why.construction.stt2') }}</p></td>
          </tr>
          </tbody>
        </table>
        <div class="w-full flex justify-center" v-else>
          <table class="experience-table px-5 w-full">
            <thead>
            <tr>
              <th></th>
              <th>{{ $t('homepage.why.typical_ex.stt1') }}<br> {{ $t('homepage.why.typical_ex.stt2') }}</th>
              <th>{{ $t('homepage.why.with_nook.stt1') }}<br> {{ $t('homepage.why.with_nook.stt2') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td><p>{{ $t('homepage.why.reno_ex.title') }}</p></td>
              <td><p>{{ $t('homepage.why.reno_ex.stt1') }}</p></td>
              <td>
                <p>
                  <svg viewBox="0 0 1024 1024"
                       style="display: inline-block; stroke: currentcolor; fill: currentcolor; width: 10px; height: 10px;">
                    <path
                        d="M1024 267.832l-162.909-158.118-465.455 451.765-232.727-225.882-162.909 158.118 395.636 384z"></path>
                  </svg>
                  {{ $t('homepage.why.reno_ex.stt2') }}
                </p>
              </td>
            </tr>
            <tr>
              <td><p>{{ $t('homepage.why.design.title') }}</p></td>
              <td><p>{{ $t('homepage.why.design.stt1') }}</p></td>
              <td>
                <p>
                  <svg viewBox="0 0 1024 1024"
                       style="display: inline-block; stroke: currentcolor; fill: currentcolor; width: 10px; height: 10px;">
                    <path
                        d="M1024 267.832l-162.909-158.118-465.455 451.765-232.727-225.882-162.909 158.118 395.636 384z"></path>
                  </svg>
                  {{ $t('homepage.why.design.stt2') }}
                </p>
              </td>
            </tr>
            <tr>
              <td><p>{{ $t('homepage.why.materials.title') }}</p></td>
              <td><p>{{ $t('homepage.why.materials.stt1') }}</p></td>
              <td>
                <p>
                  <svg viewBox="0 0 1024 1024"
                       style="display: inline-block; stroke: currentcolor; fill: currentcolor; width: 10px; height: 10px;">
                    <path
                        d="M1024 267.832l-162.909-158.118-465.455 451.765-232.727-225.882-162.909 158.118 395.636 384z"></path>
                  </svg>
                  {{ $t('homepage.why.materials.stt2') }}
                </p>
              </td>
            </tr>
            <tr>
              <td><p>{{ $t('homepage.why.support.title') }}</p></td>
              <td><p>{{ $t('homepage.why.support.stt1') }}</p></td>
              <td>
                <p>
                  <svg viewBox="0 0 1024 1024"
                       style="display: inline-block; stroke: currentcolor; fill: currentcolor; width: 10px; height: 10px;">
                    <path
                        d="M1024 267.832l-162.909-158.118-465.455 451.765-232.727-225.882-162.909 158.118 395.636 384z"></path>
                  </svg>
                  {{ $t('homepage.why.support.stt2') }}
                </p>
              </td>
            </tr>
            <tr>
              <td><p>{{ $t('homepage.why.construction.title') }}</p></td>
              <td><p>{{ $t('homepage.why.construction.stt1') }}</p></td>
              <td>
                <p>
                  <svg viewBox="0 0 1024 1024"
                       style="display: inline-block; stroke: currentcolor; fill: currentcolor; width: 10px; height: 10px;">
                    <path
                        d="M1024 267.832l-162.909-158.118-465.455 451.765-232.727-225.882-162.909 158.118 395.636 384z"></path>
                  </svg>
                  {{ $t('homepage.why.construction.stt2') }}
                </p>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!--  sixth container-->
    <!-- <div class="pt-16 pb-8 px-8">
      <div class="mb-4">
        <h1 class="text-3xl text-black lg:text-4xl mb-4">
          {{ $t('homepage.project_gallery.title') }}
        </h1>
        <p class="text-md">
          {{ $t('homepage.project_gallery.des') }}
        </p>
      </div>
      <div class="p-6">
        <div class="flex flex-col items-center">
          <div class="images-gallery mb-6">
            <img class="scroller" src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/gallery_bathroom_1.svg">
            <img class="scroller" src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/gallery_bathroom_2.svg">
            <img class="scroller" src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/gallery_bathroom_3.svg">
            <img class="scroller" src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/gallery_bathroom_4.svg">
            <img class="scroller" src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/gallery_bathroom_5.svg">
            <img class="scroller" src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/gallery_bathroom_6.svg">
            <img class="scroller" src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/gallery_bathroom_7.svg">
            <img class="scroller" src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/gallery_bathroom_8.svg">
            <img class="scroller" src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/gallery_bathroom_9.svg">
            <img class="scroller" src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/gallery_bathroom_10.svg">
          </div>
          <div class="images-gallery">
            <img class="scroller" src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/gallery_bathroom_1.svg">
            <img class="scroller" src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/gallery_bathroom_2.svg">
            <img class="scroller" src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/gallery_bathroom_3.svg">
            <img class="scroller" src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/gallery_bathroom_4.svg">
            <img class="scroller" src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/gallery_bathroom_5.svg">
            <img class="scroller" src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/gallery_bathroom_6.svg">
            <img class="scroller" src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/gallery_bathroom_7.svg">
            <img class="scroller" src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/gallery_bathroom_8.svg">
            <img class="scroller" src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/gallery_bathroom_9.svg">
            <img class="scroller" src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/gallery_bathroom_10.svg">
          </div>
        </div>
      </div>
      <div class="py-8 flex justify-center">
        <button class="w-60 h-12 bg-primary text-black uppercase flex items-center justify-center text-xs
                      hover:bg-black hover:text-white mt-5">
          <span>{{ $t('homepage.project_gallery.view') }}</span>
        </button>
      </div>
    </div> -->
    <!--  end of sixth container-->

    <!--  seventh container-->
    <div class="py-14 view-360">
      <h2 class="text-2xl mb-7 px-8">
        {{ $t('homepage.paranoma.title') }}
      </h2>
      <h3 class="text-md px-8 mb-8">

      </h3>
      <div class="flex justify-center relative">
        <div class="w-full carousel panorama-carousel">
          <div :id="'slide' + index" class="relative w-full carousel-item" v-for="(item, index) in paranomaImg"
               :key="index">
            <div class="w-full">
              <div class="h-60 relative w-full lg:h-auto">
                <a :href="item.ref" target="_blank">
                  <img class="h-60 preview-360 w-full object-cover panorama-image" :src="item.holder">
                  <img class="h-15 w-15 image-360 absolute"
                       src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/icon_360.svg">
                </a>
              </div>
              <div class="mt-4 text-center">
                {{ $t(item.title) }}
              </div>
            </div>
            <div
                class="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2"
            >
              <a :href="index - 1 >= 0 ? '#slide' + (index - 1) : '#slide' + (paranomaImg.length - 1)"
                 class="btn btn-circle bg-white hover:bg-white">❮</a>
              <a :href="index + 1 < paranomaImg.length ?  '#slide' + (index + 1) : '#slide0'"
                 class="btn btn-circle bg-white hover:bg-white">❯</a>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-center">
        <router-link :to="{name: 'packages', params: { lang: this.$i18n.locale }}" class="w-40 h-12 bg-primary text-black uppercase flex items-center justify-center text-xs
                      hover:bg-custom-hover text-white mt-7">
          <span>{{ $t('homepage.paranoma.more') }}</span>
        </router-link>
      </div>
    </div>
    <!--  end of seventh container-->

    <!--  eighth container-->
    <div class="p-8 bg-gray">
      <div class="p-8">
        <h2 class="text-3xl uppercase text-black lg:text-4xl">
          {{ $t('homepage.review.title') }}
        </h2>
        <!-- <div class="mt-4 lg:mt-6">
          <p class="text-md text-black mb-8 lg:text-xl lg:mb-10">
            {{ $t('homepage.review.des') }}
          </p>
        </div> -->
        <!-- <div class="w-full flex justify-center">
          <div class="flex justify-between items-end review-platforms">
            <div class="w-1/3 mr-6 flex flex-col justify-center review-platform">
              <img class="mb-4" src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/houzz.svg"/>
              <span class="uppercase mb-4">4.8 Stars</span>
              <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/star.svg"/>
            </div>

            <div class="w-1/3 mr-6 flex flex-col justify-center review-platform">
              <img class="mb-4" src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/yelp.svg"/>
              <cite class="uppercase mb-4">4.8 Stars</cite>
              <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/star.svg"/>
            </div>

            <div class="w-1/3 flex flex-col justify-center review-platform">
              <img class="mb-4" src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/google.svg"/>
              <cite class="uppercase mb-4">4.8 Stars</cite>
              <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/star.svg"/>
            </div>
          </div>
        </div> -->
      </div>
      <div class="relative flex justify-center">
        <div class="flex reviews-from-platform">
          <div class="w-full carousel platform-review-carousel">
            <div :id="'review' + index" class="relative w-full carousel-item" v-for="(item, index) in reviews"
                 :key="index">
              <div class="bg-white w-full pb-5 platform-review">
                <div class="px-6">
                  <div class="mt-12 mb-8 px-12">
                    <h3 class="text-xl mb-1">
                      {{ $t(item.name) }}
                    </h3>
                    <!-- <p class="text-sm mb-6">
                      {{ $t('homepage.review.user1.date') }}
                    </p> -->
                    <div class="flex justify-center mt-6 mb-4">
                      <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/home/star.svg"/>
                    </div>
                    <div class="text-2xl">
                      {{ $t(item.stt) }}
                    </div>
                    <!-- <div class="flex justify-center align-center mt-5">
                      <div class="py-1 px-2 bg-gray rounded-md mr-4">
                        <span class="text-sm font-semibold">
                          {{ $t('homepage.review.user1.tag.tag1') }}
                        </span>
                      </div>
                      <div class="py-1 px-3 bg-gray rounded-md">
                        <span class="text-sm font-semibold">
                          {{ $t('homepage.review.user1.tag.tag2') }}
                        </span>
                      </div>
                    </div> -->
                  </div>

                </div>
              </div>
              <div
                  class="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2"
              >
                <a :href="index - 1 >= 0 ? '#review' + (index - 1) : '#review' + (reviews.length - 1)"
                   class="btn btn-circle">❮</a>
                <a :href="index + 1 < reviews.length ?  '#review' + (index + 1) : '#review0'" class="btn btn-circle">❯</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="flex justify-center">
        <a href="https://designer.nookrenovation.com/" class="w-40 h-12 bg-primary text-black uppercase flex items-center justify-center text-xs
                      hover:bg-black hover:text-white mt-7">
          <span>{{ $t('homepage.review.view') }}</span>
        </a>
      </div> -->
    </div>
    <!--  end of eighth container-->
  </div>
</template>
<script>
import Accordion from "@/components/Accordion";

export default {
  name: 'Home',
  components: {Accordion},
  mounted() {
    this.checkScreenSize();
  },
  methods: {
    checkScreenSize() {
      this.screenSize = window.innerWidth;

      window.addEventListener('resize', () => {
        this.screenSize = window.innerWidth;
      });
    },
  },
  data() {
    return {
      screenSize: 0,
      paranomaImg: [
        {
          ref: 'https://kuula.co/post/NJxLL',
          holder: 'https://d10ysazx19ptke.cloudfront.net/nookassets/home/penthouse.webp',
          title: 'homepage.paranoma.photo1.title'
        },
        {
          ref: 'https://kuula.co/post/NJxQY',
          holder: 'https://d10ysazx19ptke.cloudfront.net/nookassets/home/huntington.webp',
          title: 'homepage.paranoma.photo2.title'
        },
        {
          ref: 'https://kuula.co/post/NJxL0',
          holder: 'https://d10ysazx19ptke.cloudfront.net/nookassets/home/encaustic.webp',
          title: 'homepage.paranoma.photo3.title'
        },
        {
          ref: 'https://kuula.co/post/NJVNm',
          holder: 'https://d10ysazx19ptke.cloudfront.net/nookassets/home/scandinavian.webp',
          title: 'homepage.paranoma.photo4.title'
        },
      ],
      reviews: [
        {
          name: 'homepage.review.user1.name',
          stt: 'homepage.review.user1.stt',
        },
        {
          name: 'homepage.review.user2.name',
          stt: 'homepage.review.user2.stt',
        },
        {
          name: 'homepage.review.user3.name',
          stt: 'homepage.review.user3.stt',
        },
        {
          name: 'homepage.review.user4.name',
          stt: 'homepage.review.user4.stt',
        },
      ]
    }
  }
}
</script>
<style>
.img {
  max-width: 100%;
}

.home-slogan {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.reviews-from-companies, .view-360 {
  background: #DFEDE8;
}

.reviews-from-companies, .images-gallery {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.reviews-from-companies::-webkit-scrollbar,
.images-gallery::-webkit-scrollbar {
  display: none;
}

.home-third-container .one-platform {
  max-width: 1200px;
}

.financing-text {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.renovation {
  background: #d6d6d6;
}

.images-gallery {
  height: 160px;
  overflow-y: hidden;
  overflow-x: scroll;
  white-space: nowrap;
  display: flex;

  /*animation-name: scroll;*/
  /*animation-duration: 10s;*/
  /*animation-iteration-count: infinite;*/
  /*animation-timing-function: linear;*/
}

.images-gallery img {
  height: 100%;
}

.images-gallery .scroller {
  margin-right: 24px;
}

.image-360 {
  top: calc(50% + 20px);
  left: 50%;
  transform: translate(-50%, -50%);
}

.review-platforms {
  max-width: 800px;
}

.review-platform {
  max-width: 120px;
}

.experience-table-mobile {
  border-collapse: collapse;
}

.experience-table-mobile th {
  @apply pl-3;
}

.experience-table-mobile tr td {
  @apply align-top px-3 pt-3;
}

.experience-table-mobile tbody tr:not(:last-child) td p,
.experience-table tbody tr:not(:last-child) td p {
  border-bottom: 1px solid rgba(28, 33, 31, 0.1);
}

.content-two-row {
  padding-bottom: 2rem !important;
}

.experience-table-mobile tr td:nth-child(odd), .experience-table-mobile th:nth-child(odd) {
  background: rgba(255, 255, 255, 0.5);
}

.experience-table-mobile tr td:nth-child(even), .experience-table-mobile th:nth-child(even) {
  background: rgb(255, 255, 255);
}

.experience-table-mobile th {
  @apply pt-3;
}

.experience-table-mobile tbody tr td strong {
  @apply text-xs;
}

.experience-table-mobile tbody tr td p {
  @apply text-sm pb-3;
}

.experience-table {
  min-width: 640px;
  max-width: 1160px;
  z-index: 2;
}

.experience-table svg {
  margin-right: 4px;
}

.experience-table td {
  height: 66px;
  padding-left: 30px;

  @apply align-middle;
}

.experience-table td p {
  @apply pb-4 mx-3;
}

.experience-table th {
  @apply px-3 text-2xl pb-7;
}

.experience-table td:nth-of-type(3n+1) {
  @apply font-semibold;
}

.experience-table td:nth-of-type(3n+2), .experience-table th:nth-of-type(2) {
  background: rgba(255, 255, 255, 0.5);
}

.experience-table td:nth-of-type(3n+3), .experience-table th:nth-of-type(3) {
  background: rgba(255, 255, 255);
}

.experience-table th:nth-of-type(2) {
  padding-top: 80px;
  padding-bottom: 40px;
  padding-left: 40px;
  position: relative;
}

.experience-table th:nth-of-type(3) {
  padding-top: 68px;
  padding-bottom: 40px;
  padding-left: 40px;
}

.experience-table thead tr th:nth-child(2):before {
  content: "";
  display: block;
  height: 30px;
  background: #d6d9e0;
  position: absolute;
  margin: auto;
  top: 0px;
  left: 0px;
  right: 0px;
}

.experience-table tbody tr:last-of-type td {
  padding-bottom: 40px;
  height: 106px;
}

.experience-table tbody tr:last-child td:nth-child(2) {
  position: relative;
}

.experience-table tbody tr:last-child td:nth-child(2)::before {
  content: "";
  display: block;
  height: 30px;
  background: #d6d9e0;
  position: absolute;
  margin: auto;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.left-arrow-navigation {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 8px;
  z-index: 10;
}

.right-arrow-navigation {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
  z-index: 10;
}

.bathroom-style-navigation {
  top: calc(50% - 24px) !important;
}

.renovation-estimate-stage {
  min-width: 150px;

  @apply flex items-center;
}

.renovation-estimate-stage svg {
  stroke: white;
  fill: #33d65f;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@media screen and (max-width: 1023px) {
  .reviews-from-companies {
    overflow-x: scroll;
  }

  .bathroom-carousel-container img.preview-360 {
    min-width: 100vw;
  }

  .reviews-from-platform {
    width: 100%;
  }

  .estimate-style img {
    width: 100%;
  }

}

@media screen and (min-width: 1024px) {
  .how_it_works {
    width: 200px;
  }

  .how_it_works img {
    height: 240px;
  }

  .review-platforms {
    width: 800px;
  }

  .why-nook > div {
    max-width: 1140px;
    width: 100%;
  }

  .why-nook > div:before {
    display: block;
    visibility: visible;
    position: absolute;
    top: 27px;
    right: -80px;
    content: "";
    width: 380px;
    height: 380px;
    background: url('https://d10ysazx19ptke.cloudfront.net/nookassets/home/home-pattern.webp') center center / 380px no-repeat;
    z-index: 1;
  }

  .renovation-estimate-text {
    width: 480px;
    flex-shrink: 0;
    margin-right: 40px;
  }

  .quality-accordions-container {
    max-width: 1200px;
  }

  .quality-accordion:last-of-type a {
    @apply border-b;
  }

  .quality-accordion a {
    @apply border-solid;
  }

  .panorama-carousel {
    width: 600px;
  }

  .panorama-image {
    height: 450px !important;
  }

  .platform-review {
    width: 800px;
  }

  .reviews-from-platform {
    width: 800px;
  }
}
</style>
