import {generateId} from "@/utilities/generateId";

export const GalleryStudiesTestData = [
    {
        id: generateId(),
        project: 'Project #12345',
        title: 'Whimsical Tiles in Classic Bath',
        room: 'BATHROOM',
        images: [
            'gallery1.webp',
            'gallery2.webp',
            'gallery3.webp',
            'gallery4.webp',
        ]
    },
    {
        id: generateId(),
        project: 'Project #12345',
        title: 'Whimsical Tiles in Classic Bath',
        room: 'BATHROOM',
        images: [
            'gallery1.webp',
            'gallery2.webp',
            'gallery3.webp',
            'gallery4.webp',
        ]
    },
    {
        id: generateId(),
        project: 'Project #12345',
        title: 'Whimsical Tiles in Classic Bath',
        room: 'BATHROOM',
        images: [
            'gallery1.webp',
            'gallery2.webp',
            'gallery3.webp',
            'gallery4.webp',
        ]
    },
    {
        id: generateId(),
        project: 'Project #12345',
        title: 'Whimsical Tiles in Classic Bath',
        room: 'BATHROOM',
        images: [
            'gallery1.webp',
            'gallery2.webp',
            'gallery3.webp',
            'gallery4.webp',
        ]
    },
    {
        id: generateId(),
        project: 'Project #12345',
        title: 'Whimsical Tiles in Classic Bath',
        room: 'BATHROOM',
        images: [
            'gallery1.webp',
            'gallery2.webp',
            'gallery3.webp',
            'gallery4.webp',
        ]
    },
    {
        id: generateId(),
        project: 'Project #12345',
        title: 'Whimsical Tiles in Classic Bath',
        room: 'BATHROOM',
        images: [
            'gallery1.webp',
            'gallery2.webp',
            'gallery3.webp',
            'gallery4.webp',
        ]
    },
    {
        id: generateId(),
        project: 'Project #12345',
        title: 'Whimsical Tiles in Classic Bath',
        room: 'BATHROOM',
        images: [
            'gallery1.webp',
            'gallery2.webp',
            'gallery3.webp',
            'gallery4.webp',
        ]
    },
    {
        id: generateId(),
        project: 'Project #12345',
        title: 'Whimsical Tiles in Classic Bath',
        room: 'BATHROOM',
        images: [
            'gallery1.webp',
            'gallery2.webp',
            'gallery3.webp',
            'gallery4.webp',
        ]
    },
    {
        id: generateId(),
        project: 'Project #12345',
        title: 'Whimsical Tiles in Classic Bath',
        room: 'BATHROOM',
        images: [
            'gallery1.webp',
            'gallery2.webp',
            'gallery3.webp',
            'gallery4.webp',
        ]
    },
    {
        id: generateId(),
        project: 'Project #12345',
        title: 'Whimsical Tiles in Classic Bath',
        room: 'BATHROOM',
        images: [
            'gallery1.webp',
            'gallery2.webp',
            'gallery3.webp',
            'gallery4.webp',
        ]
    },
];
