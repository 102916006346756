<template>
  <Layout></Layout>
</template>

<script>
import Layout from "@/core/layout/Layout";
export default {
  name: 'App',
  components: {Layout},
}
</script>

<style>
/* font-family: Avenir, Helvetica, Arial, sans-serif; */
#app {

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
