<template>
  <BathroomDetails></BathroomDetails>
</template>

<script>

import BathroomDetails from "@/components/BathroomDetails";

export default {
  name: 'Bathroom',
  components: {BathroomDetails}
}
</script>

<style>

</style>
