<template>
  <div>
    <div class="relative">
      <!-- <video class="w-full h-full contractor-video lg:hidden" loop autoplay muted>
        <source src="https://d10ysazx19ptke.cloudfront.net/nookassets/contractors/banner_video.mp4" type="video/mp4">
        Your browser does not support video.
      </video> -->
      <!-- <video class="w-full h-full contractor-video hidden lg:block" loop autoplay muted>
        <source src="https://d10ysazx19ptke.cloudfront.net/nookassets/contractors/banner_video_lg.mp4" type="video/mp4">
        Your browser does not support video.
      </video> -->
      <img
        class="w-full lg:hidden contractor-banner-image-mobile"
        src="https://d10ysazx19ptke.cloudfront.net/nookassets/contractors/banner.webp"
        alt=""
      />
      <img
        class="w-full h-full hidden lg:block"
        src="https://d10ysazx19ptke.cloudfront.net/nookassets/contractors/banner.webp"
        alt=""
      />
      <!-- <div class="contractor-banner-description
                  flex flex-col text-black absolute bottom-24 lg:bottom-16 left-8 text-left lg:left-16">
        <h2 class="text-3xl mb-4 lg:text-4xl">
          {{ $t('contractors.title') }}
        </h2>
        <p class="text-md mb-4 lg:text-xl">
          {{ $t('contractors.des') }}
        </p>
        <a class="button-primary w-64 h-12">
          {{ $t('contractors.application') }}
        </a>
      </div> -->

      <div class="home-slogan absolute">
        <h1 class="text-3xl text-black lg:text-4xl">
          {{ $t("contractors.title") }}
        </h1>
        <div class="p-5 flex flex-col items-center lg:flex">
          <span class="text-lg">
            {{ $t("contractors.des") }}
          </span>
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSfXYbFVyYJd_fy8nOs9ClWNSqfpY35dylq7TFWCUaqUFa1ePA/viewform"
            target="_blank"
            class="w-60 h-12 bg-primary text-black uppercase flex items-center justify-center text-xs hover:bg-custom-hover text-white my-5"
          >
            <span>{{ $t("contractors.application") }}</span>
          </a>
        </div>
      </div>
    </div>
    <!-- <div class="collaboration lg:flex lg:justify-center bg-milk">
      <div class="py-10 px-6 text-left lg:pt-16">
        <h2 class="text-3xl mb-10 lg:text-4xl lg:text-center">
          {{ $t('contractors.meet.title') }}
        </h2>
        <div class="lg:flex contractor-collaboration">
          <div class="mb-10 lg:mr-10">
            <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/contractors/contractor1.webp"/>
            <h3 class="text-sm uppercase mb-2 font-semibold">
              {{ $t('contractors.meet.profile.title') }}
            </h3>
            <p class="text-sm">
              {{ $t('contractors.meet.profile.des') }}
            </p>
          </div>
          <div class="mb-10 lg:mr-10">
            <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/contractors/contractor2.webp"/>
            <h3 class="text-sm uppercase mb-2 font-semibold">
              {{ $t('contractors.meet.design.title') }}
            </h3>
            <p class="text-sm">
              {{ $t('contractors.meet.design.des') }}
            </p>
          </div>
          <div class="mb-10 lg:mr-10">
            <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/contractors/contractor3.webp"/>
            <h3 class="text-sm uppercase mb-2 font-semibold">
              {{ $t('contractors.meet.management.title') }}
            </h3>
            <p class="text-sm">
              {{ $t('contractors.meet.management.des') }}
            </p>
          </div>
          <div class="mb-10">
            <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/contractors/contractor4.webp"/>
            <h3 class="text-sm uppercase mb-2 font-semibold">
              {{ $t('contractors.meet.guarantee.title') }}
            </h3>
            <p class="text-sm">
              {{ $t('contractors.meet.guarantee.des') }}
            </p>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div class="our-network bg-gray lg:flex lg:flex-row-reverse lg:items-end">
      <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/contractors/contractor5.webp"/>
      <div class="p-10 text-left lg:p-16">
        <div class="max-w-2xl">
          <h4 class="text-sm mb-4 uppercase lg:text-md lg:mb-10">
            {{ $t('contractors.network.title') }}
          </h4>
          <h3 class="text-3xl mb-8 mb-10">
            {{ $t('contractors.network.slogan') }}
          </h3>
          <p class="text-xl lg:text-2xl">
            {{ $t('contractors.network.des') }}
          </p>
        </div>
      </div>
    </div> -->

    <div class="nook-certified bg-milk">
      <div class="nook-certified-container">
        <h2 class="text-2xl p-10 pt-20 uppercase lg:text-3xl">
          {{ $t("contractors.certified1.title") }}
        </h2>
        <div class="px-4 pt-10 lg:flex lg:flex-row-reverse lg:justify-center">
          <div class="lg:mb-20 contractor-certified-image">
            <img
              src="https://d10ysazx19ptke.cloudfront.net/nookassets/contractors/cert1.webp"
            />
          </div>
          <div
            class="py-10 lg:pt-0 lg:w-1/2 contractor-certified-accordions lg:mr-10"
          >
            <PlusAccordion
              :title="$t('contractors.certified1.faq1.title')"
              :is-active="true"
            >
              <div class="text-left">
                <span class="text-md lg:text-sm">
                  {{ $t("contractors.certified1.faq1.des") }}
                </span>
              </div>
            </PlusAccordion>
            <PlusAccordion :title="$t('contractors.certified1.faq2.title')">
              <div class="text-left">
                <span class="text-md lg:text-sm">
                  {{ $t("contractors.certified1.faq2.des") }}
                </span>
              </div>
            </PlusAccordion>
            <PlusAccordion :title="$t('contractors.certified1.faq3.title')">
              <div class="text-left">
                <span class="text-md lg:text-sm">
                  {{ $t("contractors.certified1.faq3.des") }}
                </span>
              </div>
            </PlusAccordion>
            <!-- <PlusAccordion
              :title="$t('contractors.certified1.faq4.title')"
              :has-border-bottom="true"
            >
              <div class="text-left">
                <span class="text-md lg:text-sm">
                  {{ $t("contractors.certified1.faq4.des") }}
                </span>
              </div>
            </PlusAccordion> -->
          </div>
        </div>
      </div>
    </div>

    <div class="nook-certified bg-milk">
      <div class="nook-certified-container">
        <h2 class="text-2xl p-10 pt-20 uppercase lg:text-3xl">
          {{ $t("contractors.certified2.title") }}
        </h2>
        <div class="px-4 pt-10 lg:flex lg:flex-row-reverse lg:justify-center">
          <div
            class="py-10 lg:pt-0 lg:w-1/2 contractor-certified-accordions lg:ml-10"
          >
            <PlusAccordion
              :title="$t('contractors.certified2.faq1.title')"
              :is-active="true"
            >
              <div class="text-left">
                <span class="text-md lg:text-sm">
                  {{ $t("contractors.certified2.faq1.des") }}
                </span>
              </div>
            </PlusAccordion>
            <PlusAccordion :title="$t('contractors.certified2.faq2.title')">
              <div class="text-left">
                <span class="text-md lg:text-sm">
                  {{ $t("contractors.certified2.faq2.des") }}
                </span>
              </div>
            </PlusAccordion>
            <PlusAccordion :title="$t('contractors.certified2.faq3.title')">
              <div class="text-left">
                <span class="text-md lg:text-sm">
                  {{ $t("contractors.certified2.faq3.des") }}
                </span>
              </div>
            </PlusAccordion>
            <!-- <PlusAccordion
              :title="$t('contractors.certified2.faq4.title')"
              :has-border-bottom="true"
            >
              <div class="text-left">
                <span class="text-md lg:text-sm">
                  {{ $t("contractors.certified2.faq4.des") }}
                </span>
              </div>
            </PlusAccordion> -->
          </div>
          <div class="lg:mb-20 contractor-certified-image">
            <img
              src="https://d10ysazx19ptke.cloudfront.net/nookassets/contractors/cert2.webp"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="relative apply-contractor">
      <img class="w-full lg:hidden" src="https://d10ysazx19ptke.cloudfront.net/nookassets/contractors/contractor7.webp">
      <img class="w-full hidden lg:block" src="https://d10ysazx19ptke.cloudfront.net/nookassets/contractors/contractor8.webp">
      <div class="absolute absolute-middle flex flex-col items-center">
        <h2 class="text-2xl mb-5 whitespace-nowrap text-black lg:text-4xl">
          {{ $t("contractors.apply.title") }}
        </h2>
        <button class="button-primary h-12 w-64">
          {{ $t("contractors.apply.action") }}
        </button>
      </div>
    </div> -->

    <!-- <div class="working-together lg:flex lg:flex-row lg:justify-center mb-10">
      <div class="p-4 text-left lg:p-8">
        <h4 class="text-sm mt-4 mb-8 uppercase lg:text-md lg:mb-10 font-semibold lg:hidden">
          {{ $t("contractors.working.title") }}
        </h4>
        <div class="lg:flex lg:flex-row-reverse working-together-review lg:p-10">
          <div>
            <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/contractors/contractor9.webp"/>
          </div>
          <div class="lg:flex lg:flex-col lg:justify-center lg:h-full lg:mr-10">
            <h4 class="text-sm mt-4 mb-8 uppercase lg:text-md lg:mb-10 font-semibold hidden lg:block">
              {{ $t("contractors.working.title") }}
            </h4>
            <div>
              <p class="text-md lg:text-3xl mt-10 mb-2 lg:mb-4">
                {{ $t("contractors.working.des") }}
              </p>
              <span class="text-sm review-from-owner lg:text-md">
                {{ $t("contractors.working.author") }}
              </span>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div class="our-technology bg-gray lg:flex lg:items-end">
      <div>
        <img src="https://d10ysazx19ptke.cloudfront.net/nookassets/contractors/contractor10.webp"/>
      </div>
      <div class="p-10 text-left lg:p-16 lg:pr-24">
        <div class="lg:max-w-2xl">
          <h4 class="text-sm mb-4 uppercase lg:text-md lg:mb-10">
            {{ $t("contractors.technology.title") }}
          </h4>
          <h3 class="text-3xl mb-8 mb-10">
            {{ $t("contractors.technology.subtitle") }}
          </h3>
          <p class="text-xl lg:text-2xl">
            {{ $t("contractors.technology.des") }}
          </p>
        </div>
      </div>
    </div> -->

    <!-- <div class="relative contractor-renovate">
      <img class="w-full lg:hidden" src="https://d10ysazx19ptke.cloudfront.net/nookassets/contractors/contractor11.webp">
      <img class="w-full hidden lg:block contractor-renovate-lg-image" src="https://d10ysazx19ptke.cloudfront.net/nookassets/contractors/contractor12.webp">
      <div class="absolute absolute-middle flex flex-col items-center">
        <h2 class="text-2xl mb-5 whitespace-nowrap text-black lg:text-4xl">
          {{ $t("contractors.ready.title") }}
        </h2>
        <button class="button-primary h-12 w-64">
          {{ $t("contractors.ready.des") }}
        </button>
      </div>
    </div> -->
    <ContractorList> </ContractorList>
  </div>
</template>
<script>
import PlusAccordion from "@/components/PlusAccordion";
import ContractorList from "@/components/ContractorList";

export default {
  name: "Contractors",
  components: { ContractorList, PlusAccordion },
};
</script>
<style>
.contractor-collaboration img {
  height: 220px;
}

.collaboration > div {
  max-width: 1200px;
}

.our-network img,
.our-technology img {
  height: 360px;
  width: 100%;
}

.apply-contractor,
.apply-contractor img {
  height: 600px;
}

.our-technology > div {
  flex: 50%;
}

.contractor-certified-accordions a.tab__link {
  @apply border-solid;
}

.contractor-renovate-lg-image {
  height: 600px;
}

.review-from-owner:before {
  content: "— ";
}

.working-together-review > div {
  flex: 50%;
}

.contractor-banner-image-mobile {
  height: 100vh;
}

@media screen and (min-width: 1024px) {
  .contractor-banner-description {
    top: 50%;
    transform: translateY(-25%);
  }

  .our-network img {
    height: 700px;
    max-width: 1200px;
  }

  .contractor-certified-image,
  .contractor-certified-accordions {
    max-width: 580px;
    flex: 50%;
  }

  .our-technology img {
    min-height: 600px;
  }

  .working-together-review {
    max-width: 1200px;
  }

  .working-together-review img {
    min-height: 500px;
  }
}
</style>
