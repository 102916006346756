<template>
  <div class="">
    <div class="tab__header">
      <a href="#"
         class="tab__link py-4 block no-underline flex justify-between
                text-black border-t border-black border-dashed"
         @click.prevent="active = !active">
        <span class="text-md text-left">{{title}}</span>
        <span class="down-arrow" v-show="!active">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd" />
          </svg>
        </span>
        <span class="up-arrow" v-show="active">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path
                fill-rule="evenodd"
                d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                clip-rule="evenodd" />
          </svg>
        </span>
      </a>
    </div>
    <div class="tab__content p-2 text-left" v-show="active"><slot /></div>
  </div>
</template>
<script>
export default {
  name: 'Accordion',
  props: {
    title: String,
  },
  data() {
    return {
      active: false,
    }
  },
}
</script>
